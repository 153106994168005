import {
    DataSource,
    FilterObject,
    InputViewBox,
    MapStyleType,
    SavedArea,
    SubscriptionResponse,
    useSwitchComputeOffMutation,
} from "@biggeo/bg-server-lib/datascape-ai";
import { Fade } from "@biggeo/bg-ui";
import {
    Box,
    SelectableTreeMenuItem,
    Severity,
    Stack,
} from "@biggeo/bg-ui/lab";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { GeoJSONSource } from "mapbox-gl";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SetURLSearchParams } from "react-router-dom";

import { snapToView } from "../../../utils/utils";
import { FunctionContainer } from "../../FunctionContainer";
import type {
    InputPolygonWithId,
    PureDataStringHookReturnType,
} from "../../hooks/pure-data-string-hook";
import { mapDataActions } from "../../redux/model";
import {
    FunctionType,
    LastFunctionType,
    getMapFeatures,
    pickGeospatialSelection,
} from "../../utils/utils";
import { InitializeMapProps, useInitializeMap } from "../hooks/hooks";
import { SavedPolygonType } from "../hooks/saved-polygon-hooks";

import * as A from "fp-ts/Array";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import { isAppRunningOnSF } from "../../../common/redux/hooks";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { DEFAULT_LOGIC_OPERATOR } from "../../filter-criteria/utils/utils";
import { DataPointTooltip } from "../../map-tooltip/components/DataPointTooltip";
import { removeCustomLayers } from "../../utils/style-utils";
import { MapContextFilter, useMap } from "../context";
import { SetDatasetContextType } from "../context/context-utils";
import Accreditation from "../views/Accreditation";
import MapControls from "../views/MapControls";
import MapInfoElements from "../views/MapInfoElements";
import MapLoadingView from "../views/MapLoadingView";
import { MapPromptPopup } from "../views/MapPromptPopup";
import ResetView from "../views/ResetView";
import SaveView from "../views/SaveView";

export interface MapboxMapContainerProps
    extends Pick<
            PureDataStringHookReturnType,
            | "deleteShape"
            | "handleSavedPolygons"
            | "handlePolygonsOnZoom"
            | "setSavedPolygons"
        >,
        Pick<InitializeMapProps, "handleSelectedShapes">,
        Omit<SetDatasetContextType, "overrideDatasets"> {
    readonly channelId: string;
    readonly functionType: FunctionType;
    readonly multiFilters: FilterObject[];
    readonly viewport: InputViewBox;
    readonly handleViewportChange: ({
        viewport,
    }: {
        viewport: InputViewBox;
    }) => void;
    readonly recentResponse: SubscriptionResponse | undefined;
    readonly savedPolygons: SavedPolygonType;
    readonly setFunctionType: (f: FunctionType) => void;
    readonly polygons?: InputPolygonWithId[];
    readonly handleMultiPolygons: ({
        polygon,
    }: {
        readonly polygon: InputPolygonWithId | InputPolygonWithId[];
    }) => void;
    readonly clearShapes?: () => void;
    readonly setReFetchSavedAreas?: (value: boolean) => void;
    readonly openSaveViewPopper?: boolean;
    readonly setOpenSaveViewPopper?: (value: boolean) => void;
    readonly setSelectedAreaId?: (id?: number) => void;
    readonly resetAreasFilters: () => void;
    readonly handleSideMenu?: (v: boolean) => void;
    readonly responses: Partial<Record<string, SubscriptionResponse | null>>;
    readonly handleSelectedSavedPolygons: (i: {
        savedAreas: SavedArea[];
        selectableItems: SelectableTreeMenuItem[];
    }) => void;
    readonly selectableItems: SelectableTreeMenuItem[];
    readonly selectedShapes: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >;
    readonly savedViewId?: number;
    readonly setSearchParams: SetURLSearchParams;
    readonly mapTemplateId: number;
    readonly isLoading?: boolean;
    readonly isFilterCriteriaOpen: boolean;
    readonly filters: MapContextFilter[];
    readonly mapStyle: MapStyleType;
    readonly clearFilters: () => void;
}

export const MapboxMapContainer = ({
    channelId,
    functionType,
    multiFilters,
    viewport,
    handleViewportChange,
    recentResponse,
    savedPolygons,
    setFunctionType,
    clearShapes,
    setReFetchSavedAreas,
    openSaveViewPopper,
    setOpenSaveViewPopper,
    setSelectedAreaId,
    polygons,
    handleMultiPolygons,
    resetAreasFilters,
    responses,
    deleteShape,
    handleSavedPolygons,
    handlePolygonsOnZoom,
    handleSelectedSavedPolygons,
    selectableItems,
    handleSelectedShapes,
    selectedShapes,
    setSavedPolygons,
    savedViewId,
    setSearchParams,
    mapTemplateId,
    isFilterCriteriaOpen,
    filters,
    mapStyle,
    updateDataset,
    clearFilters,
}: MapboxMapContainerProps) => {
    const { map, draw, isLoaded, datasets, isDataLoading } = useMap();
    const isRunningOnSF = isAppRunningOnSF();
    const dispatch = useDispatch();

    const bounds = map.current?.getBounds();

    const [lastFunctionType, setLastFunctionType] = useState<
        LastFunctionType | undefined
    >(undefined);

    const {
        changeProjection,
        projection,
        zoomIn,
        zoomOut,
        selectMode,
        isSelectMode,
        isDrawMode,
    } = useInitializeMap({
        functionType,
        multiFilters,
        recentResponse,
        viewport,
        handleViewportChange,
        polygons: polygons || [],
        savedPolygons,
        deleteShape,
        handleMultiPolygons,
        handleSavedPolygons,
        handlePolygonsOnZoom: (p) => handlePolygonsOnZoom?.(p),
        setFunctionType,
        handleSelectedSavedPolygons,
        selectableItems,
        handleSelectedShapes,
        isFilterCriteriaOpen,
        setSavedPolygons,
    });

    const { executeMutation: switchComputeOff } = useSwitchComputeOffMutation();

    const switchOffDataset = (id: string) => {
        const updateDataSource = (data: Partial<DataSource>) => {
            dispatch(
                toasterActions.openToast({
                    open: true,
                    title: "Load collection failed. Please turn it back on from the data tab.",
                    autoHideDuration: 8000,
                    severity: Severity.error,
                })
            );

            updateDataset({
                dataSourceId: id,
                dataset: {
                    dataSource: data,
                    isSelected: false,
                    isVisible: false,
                },
            });
        };

        const dataset = datasets.find((d) => d.dataSource.id === id);

        if (dataset) {
            switchComputeOff({
                variables: {
                    input: {
                        id,
                        collectionName: dataset.dataSource.collectionName,
                    },
                },
                onCompleted: (d) => {
                    updateDataSource(d.switchComputeOff);
                },
                onError: () => {
                    updateDataSource({
                        isLoading: false,
                        compute: false,
                    });
                },
            });
        }
    };

    const isDrawFunctionType =
        functionType === FunctionType.polygon ||
        functionType === FunctionType.radius ||
        functionType === FunctionType.square;

    const onClose = () => {
        draw.current?.changeMode("simple_select");

        if (isDrawFunctionType) {
            setLastFunctionType(functionType);
        }

        setFunctionType(FunctionType.viewport);
    };

    const shapes = getMapFeatures(draw, isLoaded);
    const hasDrawnShapes = !isEmpty(shapes);
    const hasSelectedAreas = !isEmpty(savedPolygons);
    const hasDataSets = !isEmpty(
        pipe(
            datasets,
            A.filter((d) => isEqual(d.isSelected, true))
        )
    );
    const hasPolygons = !isEmpty(polygons);
    const hasFilters = !isEmpty(filters);

    const canResetView =
        hasDrawnShapes ||
        hasSelectedAreas ||
        hasDataSets ||
        hasPolygons ||
        hasFilters;

    const resetView = () => {
        if (draw.current && hasDrawnShapes) {
            draw.current.deleteAll();
            draw.current?.changeMode("simple_select");
            if (map.current) {
                removeCustomLayers(map.current);
            }
        }

        if (hasSelectedAreas) {
            resetAreasFilters();
        }

        if (hasDataSets) {
            pipe(
                datasets,
                A.filter((d) => isEqual(d.isSelected, true)),
                A.map((d) => {
                    updateDataset({
                        dataSourceId: d.dataSource.id,
                        dataset: {
                            isVisible: false,
                            isSelected: false,
                            isTableViewed: false,
                            isLegendOpen: false,
                            filters: {
                                logicOperator: DEFAULT_LOGIC_OPERATOR,
                                filters: [],
                            },
                            selectedRows: [],
                            pinnedRows: [],
                        },
                    });
                })
            );
        }

        if (hasFilters) {
            clearFilters();
        }

        const source = map.current?.getSource(
            "saved-polygons"
        ) as GeoJSONSource;

        if (source) {
            source.setData({
                type: "FeatureCollection",
                features: [],
            });
        }

        setSelectedAreaId?.(undefined);
        clearShapes?.();
        setFunctionType(FunctionType.viewport);
        setLastFunctionType(undefined);
        selectMode(false);
        setSearchParams({});
        dispatch(mapDataActions.updateMapData({}));
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // When they click on custom boundaries from map side menu
        if (functionType === FunctionType.savedPolygon) {
            draw.current?.changeMode("simple_select");
            selectMode(false);
        }
    }, [functionType]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: no additional dependencies
    useEffect(() => {
        if (
            recentResponse &&
            !isNil(recentResponse.collectionExist) &&
            !recentResponse.collectionExist
        ) {
            switchOffDataset(recentResponse.databaseId);
            updateDataset({
                dataSourceId: recentResponse.databaseId,
                dataset: {
                    isSelected: false,
                    isVisible: false,
                },
            });
        }
    }, [recentResponse]);

    return (
        <Fade in={isLoaded} timeout={1500}>
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                }}
            >
                <Box
                    id="map"
                    sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        lineHeight: 0,
                        borderRadius: (theme) => theme.radius.default,
                        overflow: "hidden",
                    }}
                />
                <MapPromptPopup />
                <MapInfoElements
                    responses={responses}
                    map={map}
                    datasets={datasets}
                    isLoaded={isLoaded}
                />
                <FunctionContainer
                    onClose={onClose}
                    functionType={functionType}
                    setFunctionType={setFunctionType}
                    draw={draw}
                    resetView={resetView}
                    selectMode={selectMode}
                    isSelectMode={isSelectMode}
                    selectedShapes={selectedShapes}
                    snapToView={() =>
                        snapToView({
                            polygons: polygons || [],
                            map,
                        })
                    }
                    setReFetchSavedAreas={setReFetchSavedAreas}
                    isDrawMode={isDrawMode}
                    lastFunctionType={lastFunctionType}
                    setLastFunctionType={setLastFunctionType}
                    hasDrawnShapes={hasDrawnShapes}
                    mapTemplateId={mapTemplateId}
                    deleteShape={deleteShape}
                />
                <MapControls
                    changeProjection={changeProjection}
                    projection={projection}
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    geospatialSelection={pickGeospatialSelection(functionType, {
                        viewport,
                        multipolygon: polygons?.map((c) => ({
                            inners: c.inners,
                            outer: c.outer,
                        })),
                    })}
                    channelId={channelId}
                />
                {openSaveViewPopper && (
                    <SaveView
                        openSaveViewPopper={openSaveViewPopper}
                        setOpenSaveViewPopper={setOpenSaveViewPopper}
                        bounds={bounds}
                        viewport={viewport}
                        draw={draw}
                        onCloseDraw={onClose}
                        savedViewId={savedViewId}
                        isLoaded={isLoaded}
                        filters={filters}
                        mapStyle={mapStyle}
                        map={map}
                        datasets={datasets}
                        savedPolygons={savedPolygons}
                        isRunningOnSF={isRunningOnSF}
                    />
                )}
                {/* MARK: Hidden as per Jose's request */}
                {/* <AiPromptContainer
                    handleViewportChange={handleViewportChange}
                    prompt={prompt}
                    setPrompt={setPrompt}
                    map={map}
                    draw={draw}
                    setFunctionType={setFunctionType}
                    snapToView={snapToView}
                    polygons={polygons}
                    handleMultiPolygons={handleMultiPolygons}
                    setShowAiPrompt={setShowAiPrompt}
                    showAiPrompt={showAiPrompt}
                /> */}
                <Accreditation />
                {canResetView && !openSaveViewPopper && (
                    <ResetView resetView={resetView} />
                )}
                {!!isDataLoading && <MapLoadingView />}
                {!isSelectMode && !isDrawMode ? (
                    // Wrapping this in a Stack is necessary, otherwise it throws a Node error when you try
                    // to draw after opening the tooltip.
                    <Stack>
                        <DataPointTooltip />
                        {/* <HeatMapTooltip /> */}
                    </Stack>
                ) : null}
            </Box>
        </Fade>
    );
};
