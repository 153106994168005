import { ApolloQueryResult } from "@apollo/client";
import {
    DataSource,
    Exact,
    FetchUnPreviewedMarketplaceDatasetsQuery,
    InputFetchUnPreviewedDatasets,
    MarketplaceDataset,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import { Either } from "fp-ts/lib/Either";
import MarketplaceDataView from "../../map/mapbox/views/MarketplaceDataView";
import { CallBacksType } from "../../utils/types";

export interface IMarketplaceDataViewContainer {
    readonly onClose: () => void;
    readonly mpDatasetsLoading: boolean;
    readonly mpDatasetsData:
        | FetchUnPreviewedMarketplaceDatasetsQuery
        | undefined;
    readonly onClickPreviewDataset: (
        marketplaceDataset: MarketplaceDataset,
        callbacks?: CallBacksType<DataSource>
    ) => void;
    readonly isRunningOnSF: boolean;
    readonly onClickPreviewInfo: (
        input?: Either<
            {
                marketplaceDatasetId: string;
            },
            {
                dataSourceId: string;
            }
        >
    ) => void;
    readonly refetchUnPreviewedMarketplaceDatasets: (
        variables?:
            | Partial<
                  Exact<{
                      input: InputFetchUnPreviewedDatasets;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchUnPreviewedMarketplaceDatasetsQuery>>;
}

const MarketplaceDataViewContainer = ({
    mpDatasetsLoading,
    mpDatasetsData,
    ...props
}: IMarketplaceDataViewContainer) => {
    return mpDatasetsLoading ? (
        <LoadingBar />
    ) : (
        <MarketplaceDataView
            {...props}
            marketplaceDatasets={
                mpDatasetsData?.fetchUnPreviewedMarketplaceDatasets
                    .marketplaceDatasets || []
            }
        />
    );
};

export default MarketplaceDataViewContainer;
