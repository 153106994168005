import {
    AnyResponse,
    InputPolygon,
    InputViewBox,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    FlexScrollArea,
    FlexScrollAreaContainer,
    HorizontalScroller,
    MapLayoutHeader,
    Severity,
    Tab,
    TabGroup,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import replace from "lodash/replace";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { MapTableTabs } from "../../map-wrappers/MapViewWrapper";
import { MapContextFilter, useMap } from "../../mapbox/context";
import { onFlyToMapFeature } from "../../mapbox/utils/data-hooks-utils";
import { MapFilterCriteriaDataset } from "../utils/utils";
import DatasetTableContainer from "./DatasetTableContainer";

interface IMainFilteredDatasetTableContainer {
    readonly tab: MapTableTabs;
    readonly filter: MapContextFilter;
    readonly handleClose: (id: string) => void;
    readonly channelId: string;
    readonly geospatialSelection:
        | {
              viewport: InputViewBox;
          }
        | {
              multipolygon: InputPolygon[];
          };
    readonly isRunningOnSF: boolean;
}

const MainFilteredDatasetTableContainer = ({
    tab,
    filter,
    handleClose,
    channelId,
    geospatialSelection,
    isRunningOnSF,
}: IMainFilteredDatasetTableContainer) => {
    const rdxDispatch = useDispatch();
    const { dispatch, datasets, map, isLoaded } = useMap();
    const [filteredDataset, setFilteredDataset] =
        useState<MapFilterCriteriaDataset>(filter.filterCriteria[0]);

    const dataset = pipe(
        datasets,
        A.findFirst((d) => d.dataSource.id === filteredDataset.dataSourceId),
        O.fold(
            () => undefined,
            (dataset) => dataset
        )
    );

    const tableId = dataset?.dataSource.tableId || undefined;

    const dggsIndexName = pipe(
        dataset?.dataSource.geographyColumn,
        O.fromNullable,
        O.fold(
            () => "DGGS_INDEX",
            (col) => `DGGS_INDEX_${replace(col, /[^\w\s]/gi, "_")}`
        )
    );

    const updateFilter = (i: {
        index: number;
        items: MapFilterCriteriaDataset;
    }) => {
        dispatch?.({
            type: "UPDATE_FILTER",
            values: {
                id: filter.id,
                filterCriteria: pipe(
                    filter.filterCriteria,
                    A.mapWithIndex((idx, f) =>
                        isEqual(idx, i.index) ? i.items : f
                    )
                ),
            },
        });

        if (isEqual(filter.filterCriteria.indexOf(filteredDataset), i.index)) {
            setFilteredDataset(i.items);
        }
    };

    const onGoToClick = (rows: AnyResponse["data"]) => {
        onFlyToMapFeature({
            rows,
            map,
            isLoaded,
            tableId,
            geographyColumn: dataset?.dataSource.geographyColumn || undefined,
            datasetId: filteredDataset.dataSourceId,
            onError: () => {
                rdxDispatch(
                    toasterActions.openToast({
                        open: true,
                        title: `Cannot fly to point. Longitude or Latitude coordinates are missing for dataset #${filteredDataset.label}.`,
                        autoHideDuration: 5000,
                        severity: Severity.error,
                    })
                );
            },
        });
    };

    return (
        <FlexScrollAreaContainer>
            <MapLayoutHeader
                vertical={false}
                header={"Table"}
                onClick={() => {
                    if (filter) {
                        handleClose(filter.id);
                    }
                }}
            />
            <HorizontalScroller
                sx={{
                    paddingX: 4,
                }}
            >
                <TabGroup value={filteredDataset.dataSourceId}>
                    {pipe(
                        filter.filterCriteria,
                        A.map((t) => (
                            <Tab
                                key={t.label}
                                value={t.dataSourceId}
                                onClick={() => setFilteredDataset(t)}
                            >
                                {t.label}
                            </Tab>
                        ))
                    )}
                </TabGroup>
            </HorizontalScroller>
            <FlexScrollArea
                flexDirection="column"
                gap={4}
                height={"100%"}
                width={"100%"}
                sx={{
                    padding: 4,
                }}
            >
                <DatasetTableContainer
                    mainTab={tab}
                    data={filteredDataset}
                    channelId={channelId}
                    tableId={tableId}
                    geospatialSelection={geospatialSelection}
                    type="filteredData"
                    isRunningOnSF={isRunningOnSF}
                    filterId={filter.id}
                    dggsIndexName={dggsIndexName}
                    heatmap={
                        filter.styles.dataAggregation?.heatmap || undefined
                    }
                    onGoToClick={onGoToClick}
                    updateFilter={(filters) =>
                        updateFilter({
                            index: filter.filterCriteria.indexOf(
                                filteredDataset
                            ),
                            items: {
                                ...filteredDataset,
                                filters,
                            },
                        })
                    }
                />
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};

export default MainFilteredDatasetTableContainer;
