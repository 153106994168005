import { useFetchMarketplaceDatasetByDataSourceIdQuery } from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import MarketplaceDatasetPreview from "../views/MarketplaceDatasetPreview";
import { IMarketplaceDatasetPreviewContainer } from "./MarketplaceDatasetPreviewContainer";

interface IMarketplaceDatasetPreviewByDataSourceId
    extends Omit<IMarketplaceDatasetPreviewContainer, "previewDataset"> {
    readonly dataSourceId: string;
}

const MarketplaceDatasetPreviewByDataSourceId = ({
    dataSourceId,
    onClickPreviewInfo,
    isPreviewBtnDisabled,
    ...props
}: IMarketplaceDatasetPreviewByDataSourceId) => {
    const { remote } = useFetchMarketplaceDatasetByDataSourceIdQuery({
        variables: {
            dataSourceId,
        },
    });

    return match(remote, {
        _: () => <LoadingBar />,
        Success: (data) => {
            const result = data.fetchMarketplaceDatasetByDataSourceId;

            return (
                <MarketplaceDatasetPreview
                    {...props}
                    marketplaceDataset={result.marketplaceDataset}
                    dataSourceId={dataSourceId}
                    isPreviewBtnDisabled={
                        result.dataSource?.isPreview || isPreviewBtnDisabled
                    }
                    onClose={() => onClickPreviewInfo(undefined)}
                />
            );
        },
    });
};

export default MarketplaceDatasetPreviewByDataSourceId;
