import { DatasetGeometryPoint } from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import every from "lodash/every";
import groupBy from "lodash/groupBy";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import mapValues from "lodash/mapValues";
import size from "lodash/size";
import some from "lodash/some";
import uniq from "lodash/uniq";
import { useMap } from "../../mapbox/context";
import { setDatasetContext } from "../../mapbox/context/context-utils";
import { setDatasetVisibility } from "../../mapbox/utils/data-layers-utils";
import { DatasetTableSelectedRows } from "../components/DatasetTableSelectedRows";

const DatasetTableSelectedRowsContainer = () => {
    const { datasets, map, isLoaded } = useMap();
    const { updateDataset } = setDatasetContext();
    const allSelectedRows = pipe(
        datasets,
        A.filter(
            (d) => isEqual(d.isSelected, true) && !isEmpty(d.selectedRows)
        ),
        A.flatMap((d) => d.selectedRows)
    );
    const allPinnedRows = pipe(
        datasets,
        A.filter((d) => isEqual(d.isSelected, true) && !isEmpty(d.pinnedRows)),
        A.flatMap((d) => d.pinnedRows)
    );

    const isFocused = some(
        datasets,
        (d) =>
            !isEmpty(d.selectedRows) &&
            isEqual(d.configuration.showPoints, false)
    );

    const onPinnedRowsChange = (i: {
        action: "pin" | "unpin";
        rows: DatasetGeometryPoint[];
    }) => {
        const { action, rows } = i;
        const groupedRowsByDataset = groupBy(rows, (r) => r.dataSourceId);

        mapValues(groupedRowsByDataset, (rows, dataSourceId) => {
            const dataset = datasets.find(
                (d) => d.dataSource.id === dataSourceId
            );
            const tableId = dataset?.dataSource.tableId || undefined;

            if (dataset && tableId) {
                const pinnedRows = dataset.pinnedRows || [];

                if (isEqual(action, "pin")) {
                    updateDataset?.({
                        dataSourceId,
                        dataset: {
                            pinnedRows: pipe(pinnedRows, A.concat(rows)),
                        },
                    });
                }

                if (isEqual(action, "unpin")) {
                    updateDataset?.({
                        dataSourceId,
                        dataset: {
                            pinnedRows: pipe(
                                pinnedRows,
                                A.filter((r) => !includes(rows, r))
                            ),
                        },
                    });
                }
            }
        });
    };

    const onCloseClick = () => {
        return pipe(
            allSelectedRows,
            A.map((r) => r.dataSourceId),
            uniq,
            A.map((dataSourceId) => {
                updateDataset?.({
                    dataSourceId,
                    dataset: {
                        selectedRows: [],
                    },
                });
            })
        );
    };

    const onFocusClick = () => {
        return pipe(
            allSelectedRows,
            A.map((r) => r.dataSourceId),
            uniq,
            A.map((dataSourceId) => {
                const dataset = datasets.find(
                    (d) => d.dataSource.id === dataSourceId
                );

                if (dataset) {
                    updateDataset?.({
                        dataSourceId,
                        dataset: {
                            configuration: {
                                showPoints: !dataset.configuration.showPoints,
                            },
                        },
                    });

                    if (map.current && isLoaded) {
                        setDatasetVisibility({
                            category: "points",
                            visibility: isEqual(
                                dataset.configuration.showPoints,
                                false
                            )
                                ? "visible"
                                : "none",
                            map: map.current,
                            isLoaded,
                            prefix: dataset.dataSource.id,
                            levelSets: [],
                            styles:
                                dataset.mapTemplateDataset?.styles || undefined,
                        });
                    }
                }
            })
        );
    };

    return isEmpty(allSelectedRows) ? null : (
        <DatasetTableSelectedRows
            rows={pipe(allSelectedRows, size)}
            isPinSelectedDisabled={every(allSelectedRows, (p) =>
                allPinnedRows.map((p) => p.row.id).includes(p.row.id)
            )}
            onPinSelectedClick={() => {
                onPinnedRowsChange({
                    action: "pin",
                    rows: pipe(
                        allSelectedRows,
                        A.filter(
                            (s) =>
                                !allPinnedRows
                                    .map((p) => p.row.id)
                                    .includes(s.row.id)
                        )
                    ),
                });
            }}
            onUnPinAllClick={() =>
                onPinnedRowsChange({
                    action: "unpin",
                    rows: allPinnedRows,
                })
            }
            isUnPinAllHidden={isEmpty(allPinnedRows)}
            onFocusClick={() => onFocusClick()}
            onFocusRemoveClick={isFocused ? () => onFocusClick() : undefined}
            onCloseClick={() => onCloseClick()}
        />
    );
};

export default DatasetTableSelectedRowsContainer;
