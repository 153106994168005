import {
    AnyResponse,
    ColumnAttributes,
    DatasetGeometryPoint,
    FilterCriteriaDatasetItem,
    LogicOperator,
    SchemaRow,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Box,
    FlexScrollArea,
    FlexScrollAreaContainer,
    IFilterSearchPaginate,
    Stack,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import { getLinearGradient } from "../../../common/utils/gradient";
import { MapContextDatasetFilter } from "../../mapbox/context";
import { DEFAULT_SHAPE_COLOR } from "../../mapbox/hooks/style-hooks";
import { DatasetTableTab } from "../containers/DatasetTableContainer";
import {
    DEFAULT_LOGIC_OPERATOR,
    getSnowflakeTableColumns,
} from "../utils/utils";
import DatasetTableView from "./DatasetTableView";

export type DatasetTableProps = {
    readonly type: "data" | "filteredData";
    readonly view: DatasetTableTab;
    readonly setView: (v: DatasetTableTab) => void;
    readonly columns: SchemaRow[];
    readonly data: AnyResponse;
    readonly loading: boolean;
    readonly tableId?: string;
    readonly dataPaginationProps: IFilterSearchPaginate;
    readonly heatmap?: ColorSwatchOption;
    readonly initialFilters?: MapContextDatasetFilter;
    readonly updateFilter?: (
        filters: FilterCriteriaDatasetItem[],
        logicOperator: LogicOperator
    ) => void;
    readonly updateDataset?: (
        i: Partial<{
            filters: FilterCriteriaDatasetItem[];
            logicOperator: LogicOperator;
            selectedRows: Omit<DatasetGeometryPoint, "dataSourceId">[];
            pinnedRows: Omit<DatasetGeometryPoint, "dataSourceId">[];
        }>
    ) => void;
    readonly onGoToClick: (rows: AnyResponse["data"]) => void;
    readonly dggsIndexName: string;
    readonly isGoToFieldHidden: boolean;
    readonly selectedRows: AnyResponse["data"];
    readonly pinnedRows: AnyResponse["data"];
    readonly isFocused: boolean;
};

export const DatasetTable = ({
    type,
    columns: c,
    data,
    loading,
    dataPaginationProps,
    heatmap,
    initialFilters,
    updateFilter,
    updateDataset,
    onGoToClick,
    dggsIndexName,
    view,
    setView,
    isGoToFieldHidden,
    tableId,
    selectedRows,
    pinnedRows,
}: DatasetTableProps) => {
    const columns = getSnowflakeTableColumns(c);

    const gradient = heatmap ? getLinearGradient(heatmap) : undefined;

    const hasDggsColumn = c.some((d) => d.columnName === dggsIndexName);

    const onPinnedRowsChange = (i: {
        action: "pin" | "unpin";
        rows: AnyResponse["data"];
    }) => {
        const { action, rows } = i;

        if (isEqual(action, "pin")) {
            updateDataset?.({
                pinnedRows: pipe(
                    pinnedRows,
                    A.concat(rows),
                    A.map((row) => ({
                        tableId,
                        row,
                    }))
                ),
            });
        }

        if (isEqual(action, "unpin")) {
            updateDataset?.({
                pinnedRows: pipe(
                    pinnedRows,
                    A.filter((r) => !includes(rows, r)),
                    A.map((row) => ({
                        tableId,
                        row,
                    }))
                ),
            });
        }
    };

    const onSelectedRowsChange = (rows: AnyResponse["data"]) => {
        updateDataset?.({
            selectedRows: pipe(
                rows,
                A.map((row) => ({
                    tableId,
                    row,
                }))
            ),
        });
    };

    const onSaveCriteria = (values: MapContextDatasetFilter) => {
        updateFilter?.(values.filters, values.logicOperator);
    };

    const onApplyFilter = (f: FilterCriteriaDatasetItem[]) => {
        updateDataset?.({ filters: f });
    };

    const onOperatorChange = (operator: LogicOperator) => {
        updateDataset?.({ logicOperator: operator });
    };

    const getColumnAttributes = (
        column: string
    ): ColumnAttributes | undefined => {
        return pipe(
            c,
            A.findFirst((i) => i.columnName === column),
            O.fold(
                () => undefined,
                (schema) => schema.attributes || undefined
            )
        );
    };

    return (
        <Stack
            height={"100%"}
            width={"100%"}
            sx={{
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
                borderTopLeftRadius: (theme) => theme.radius.xs5,
                borderTopRightRadius: (theme) => theme.radius.xs5,
            }}
        >
            <Box
                sx={{
                    borderTopLeftRadius: "inherit",
                    borderTopRightRadius: "inherit",
                    height: 2,
                    width: "100%",
                    background: gradient
                        ? `${gradient}`
                        : `linear-gradient(90deg, #EBEFFE 0%, ${DEFAULT_SHAPE_COLOR} 100%)`,
                }}
            />
            <FlexScrollAreaContainer height="100%" width="100%">
                <FlexScrollArea
                    flexDirection="column"
                    height="100%"
                    width="100%"
                    sx={{
                        padding: 3,
                    }}
                >
                    <DatasetTableView
                        type={type}
                        tableId={tableId}
                        view={view}
                        setView={setView}
                        initialValues={
                            initialFilters || {
                                logicOperator: DEFAULT_LOGIC_OPERATOR,
                                filters: [],
                            }
                        }
                        onSubmit={onSaveCriteria}
                        data={data}
                        isFilteringMenuDisabled={!hasDggsColumn}
                        isGoToFieldHidden={isGoToFieldHidden}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={onSelectedRowsChange}
                        loading={loading}
                        dataPaginationProps={dataPaginationProps}
                        getColumnAttributes={getColumnAttributes}
                        columns={isEmpty(c) ? [] : columns}
                        onApplyFilter={onApplyFilter}
                        onGoToClick={onGoToClick}
                        onOperatorChange={onOperatorChange}
                        pinnedRows={pinnedRows}
                        onPinnedRowsChange={onPinnedRowsChange}
                    />
                </FlexScrollArea>
            </FlexScrollAreaContainer>
        </Stack>
    );
};
