import { InputPolygon, InputViewBox } from "@biggeo/bg-server-lib/datascape-ai";
import { Box, Grid, ToggleButton, ToggleButtonGroup } from "@biggeo/bg-ui/lab";
import {
    AddOutline,
    PublicOutline,
    RemoveOutline,
    TwoDOutline,
} from "@biggeo/bg-ui/lab/icons";

interface IMapControls {
    zoomIn: () => void;
    zoomOut: () => void;
    projection: "mercator" | "globe";
    changeProjection: (proj: "mercator" | "globe") => void;
    geospatialSelection?:
        | { viewport: InputViewBox }
        | { multipolygon: InputPolygon[] };
    channelId: string;
}

const MapControls = ({
    zoomIn,
    zoomOut,
    projection,
    changeProjection,
}: IMapControls) => {
    return (
        <Box
            sx={{
                position: "absolute",
                right: 4,
                top: 5,
            }}
        >
            <Grid
                container
                gap={2}
                flexDirection={"column"}
                alignItems="center"
            >
                <Grid item>
                    <ToggleButtonGroup
                        sx={{
                            flexDirection: "column",
                            background: (theme) =>
                                theme.palette.background.main,
                        }}
                    >
                        <ToggleButton onClick={zoomIn}>
                            <AddOutline />
                        </ToggleButton>
                        <ToggleButton onClick={zoomOut}>
                            <RemoveOutline />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item>
                    <ToggleButtonGroup
                        value={projection}
                        sx={{
                            flexDirection: "column",
                            background: (theme) =>
                                theme.palette.background.main,
                        }}
                    >
                        <ToggleButton
                            value="mercator"
                            onClick={() => {
                                changeProjection("mercator");
                            }}
                        >
                            <TwoDOutline />
                        </ToggleButton>
                        <ToggleButton
                            value="globe"
                            onClick={() => {
                                changeProjection("globe");
                            }}
                        >
                            <PublicOutline />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MapControls;
