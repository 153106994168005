import {
    Button,
    Divider,
    IconButton,
    OverflowingTypography,
    OverlayWrapper,
    Stack,
} from "@biggeo/bg-ui/lab";
import {
    CloseOutline,
    FilterCenterFocusOutline,
    KeepOffOutline,
    KeepOutline,
    UploadOutline,
} from "@biggeo/bg-ui/lab/icons";

export type DatasetTableSelectedRowsProps = {
    rows: number;
    onCloseClick: () => void;

    onPinSelectedClick: () => void;
    isPinSelectedDisabled?: boolean;

    onUnPinAllClick: () => void;
    isUnPinAllHidden?: boolean;

    onFocusClick: () => void;
    onFocusRemoveClick?: () => void;

    onExportClick?: () => void;
};

export const DatasetTableSelectedRows = ({
    rows,
    onCloseClick,

    onPinSelectedClick,
    isPinSelectedDisabled = false,

    onUnPinAllClick,
    isUnPinAllHidden = true,

    onFocusClick,
    onFocusRemoveClick,

    onExportClick,
}: DatasetTableSelectedRowsProps) => {
    return (
        <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                position: "absolute",
                left: isUnPinAllHidden ? "50%" : "45%",
                bottom: 10,
                backgroundColor: (theme) => theme.palette.background.main,
                borderRadius: (theme) => theme.radius.xs3,
                border: 1,
                borderColor: (theme) => theme.palette.stroke[100],
                zIndex: 99999,
                maxHeight: (theme) => theme.spacing(11.5),
            }}
        >
            <OverflowingTypography
                variant="body3"
                fontWeight="semibold"
                sx={{
                    padding: 2,
                }}
            >
                {`${rows} Selected`}
            </OverflowingTypography>
            <Stack
                flexDirection="row"
                gap={2}
                sx={{
                    padding: 2,
                }}
            >
                <Button
                    variant="outlined"
                    density="dense"
                    endNode={<KeepOutline size="xs" />}
                    onClick={onPinSelectedClick}
                    disabled={isPinSelectedDisabled}
                >
                    Pin Selected
                </Button>
                {!isUnPinAllHidden && (
                    <Button
                        variant="outlined"
                        density="dense"
                        endNode={<KeepOffOutline size="xs" />}
                        onClick={onUnPinAllClick}
                    >
                        Un-Pin All
                    </Button>
                )}
                {onFocusRemoveClick ? (
                    <OverlayWrapper
                        placement={{ horizontal: "right", vertical: "top" }}
                        placementOffset={{ horizontal: -2, vertical: -1 }}
                        content={
                            <CloseOutline
                                size="xxs"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onFocusRemoveClick();
                                }}
                                sx={{
                                    cursor: "pointer",
                                    padding: 0.5,
                                    backgroundColor: (theme) =>
                                        theme.palette.surface.main,
                                    color: (theme) =>
                                        theme.palette.surface.onMain,
                                    borderRadius: (theme) => theme.radius.xs5,
                                }}
                            />
                        }
                    >
                        <Button
                            variant="outlined"
                            density="dense"
                            endNode={<FilterCenterFocusOutline size="xs" />}
                            readonly={!!onFocusRemoveClick}
                        >
                            Focus
                        </Button>
                    </OverlayWrapper>
                ) : (
                    <Button
                        variant="outlined"
                        density="dense"
                        endNode={<FilterCenterFocusOutline size="xs" />}
                        onClick={onFocusClick}
                    >
                        Focus
                    </Button>
                )}
                <Button
                    variant="outlined"
                    density="dense"
                    endNode={<UploadOutline size="xs" />}
                    disabled
                    onClick={onExportClick}
                >
                    Export
                </Button>
            </Stack>
            <Divider
                orientation="vertical"
                color={100}
                sx={{
                    margin: 0,
                }}
            />
            <Stack
                sx={{
                    paddingX: 1,
                }}
            >
                <IconButton
                    variant="ghost"
                    disableActiveEffect
                    disableFocusEffect
                    disableHoverEffect
                    onClick={onCloseClick}
                >
                    <CloseOutline size="xs" />
                </IconButton>
            </Stack>
        </Stack>
    );
};
