import {
    DataProvider,
    MarketplaceDataset,
    useFetchMarketplaceDatasetsQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    AspectRatio,
    Image,
    LoadingBar,
    PreviewDataListing,
} from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import bytes from "bytes";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router";
import { ErrorPage } from "../../common/components/ErrorPage";

export const MarketplacePreviewDataListingContainer = ({
    marketplaceDataset,
    provider,
}: { marketplaceDataset: MarketplaceDataset; provider: DataProvider }) => {
    const { remote: providerListingRd } = useFetchMarketplaceDatasetsQuery({
        variables: {
            input: {
                fkProviderIds: [provider.id],
            },
        },
    });
    const toPage = useNavigate();
    const onConnect = (id: string) => {
        console.log("id", id);
    };

    const onRequest = (type: "access" | "purchase", productId: string) => {
        console.log(type, productId);
    };

    return (
        <>
            {match(providerListingRd, {
                _: () => <LoadingBar />,
                Success: ({ fetchMarketplaceDatasets }) => {
                    const datasets =
                        fetchMarketplaceDatasets.marketplaceDatasets;

                    return (
                        <PreviewDataListing
                            contactSales={() => {
                                //
                            }}
                            hasAccessKey={true}
                            marketplaceId={marketplaceDataset.id}
                            name={marketplaceDataset.name}
                            categories={provider.category}
                            provider={provider.name}
                            content={
                                marketplaceDataset.description || undefined
                            }
                            img={marketplaceDataset.img || undefined}
                            promoAssets={marketplaceDataset.promoAssets?.map(
                                (asset, i) => {
                                    return (
                                        <AspectRatio
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            key={i}
                                            variant="16/9"
                                            height="auto"
                                            width="100%"
                                        >
                                            <Image
                                                src={asset.url}
                                                alt={asset.originalName}
                                                sx={{
                                                    objectFit: "contain",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        </AspectRatio>
                                    );
                                }
                            )}
                            pricing={marketplaceDataset.price.map((p) => {
                                return {
                                    price: p.price.toLocaleString(),
                                    type: p.priceOption,
                                };
                            })}
                            size={bytes(marketplaceDataset.size) || "0"}
                            contactLink={marketplaceDataset.contactLink || ""}
                            marketplaceDataset={datasets.map((dataset) => ({
                                id: dataset.id,
                                title: dataset.name,
                                description: dataset.description || "",
                                source: {
                                    image: dataset.img || "",
                                    name: dataset.name,
                                },
                                size: bytes(dataset.size) || "0",
                                bgIndexed: true,
                                isAdmin: false,
                                onContactSales: () => {
                                    const url = dataset.contactLink || "";
                                    if (!url || isEmpty(url)) {
                                        return;
                                    }

                                    if (url.startsWith("http")) {
                                        window.open(url, "_blank");
                                    } else {
                                        toPage(url);
                                    }
                                },
                                onConnect: (id: string) => {
                                    onConnect(id);
                                },
                                onClick: (id) => {
                                    toPage(`/explore-dataset/${id}`);
                                },
                                onRequest: ({
                                    type,
                                    productId,
                                }: {
                                    type: "access" | "purchase";
                                    productId: string;
                                }) => {
                                    onRequest(type, productId);
                                },
                                price: dataset.price,
                                isPurchased: false,
                            }))}
                        />
                    );
                },
                Failure: (err) => {
                    return <ErrorPage subtitle={err.message} />;
                },
            })}
        </>
    );
};
