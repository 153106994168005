import { BreadcrumbsButton, BreadcrumbsGroup, Stack } from "@biggeo/bg-ui/lab";
import { ColorSwatchOption } from "../../common/components/ColorSwatchSelector";
import { MapContextPreviewDataset } from "../mapbox/context/map";
import { PreviewDatasetCardMapItem } from "./PreviewDatasetCardMapTem";

export interface IPreviewDatasetMenu {
    readonly preview: MapContextPreviewDataset;
    readonly onBack: (dataSourceId: string) => void;
    readonly extraOptions?: ColorSwatchOption[];
    readonly updateHeatMapColor: (
        value: ColorSwatchOption,
        preview: MapContextPreviewDataset
    ) => void;
}

const PreviewDatasetMenu = ({
    onBack,
    preview,
    ...props
}: IPreviewDatasetMenu) => {
    return (
        <Stack gap={4}>
            <BreadcrumbsGroup value="styles">
                <BreadcrumbsButton
                    variant="minimal"
                    value="datasets"
                    onClick={() => onBack(preview.dataSourceId)}
                >
                    Datasets
                </BreadcrumbsButton>
                <BreadcrumbsButton
                    variant="minimal"
                    value="styles"
                    hideSeparator
                >
                    Styles
                </BreadcrumbsButton>
            </BreadcrumbsGroup>
            <PreviewDatasetCardMapItem {...props} preview={preview} />
        </Stack>
    );
};

export default PreviewDatasetMenu;
