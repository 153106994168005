import { Grid, HorizontalScroller, Stack, Typography } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import size from "lodash/size";
import { FeedbackChip } from "../../../common/components/FeedbackChip";
import { ShapeIcon } from "../../../common/components/ShapeIcon";
import { TableFilterChip } from "../../../common/components/TableFilterChip";
import { MapContextDataset } from "../../mapbox/context";
import { SetDatasetContextType } from "../../mapbox/context/context-utils";
import { getDatasetShapeColor } from "../../utils/style-utils";
import { mapFilterCriteriaSign } from "../utils/utils";

interface IFilterAppliedChip
    extends Pick<SetDatasetContextType, "updateDataset"> {
    datasets: MapContextDataset[];
}

const FilterAppliedChip = ({ datasets, updateDataset }: IFilterAppliedChip) => {
    const filtersCount = pipe(
        datasets,
        A.filter((d) => !isEmpty(d.filters)),
        A.flatMap((d) => d.filters.filters || []),
        size
    );

    const clearFilters = () => {
        pipe(
            datasets,
            A.map((d) =>
                updateDataset({
                    dataSourceId: d.dataSource.id,
                    dataset: {
                        filters: {
                            filters: [],
                        },
                    },
                })
            )
        );
    };

    return (
        <FeedbackChip
            text={"Filters Applied"}
            onModalSaveClick={() => {
                clearFilters();
            }}
            count={filtersCount}
            buttons={{
                text1: "Keep Filters",
                text2: "Yes, Clear Filters",
            }}
            startNode={
                <Stack flexDirection="row" gap={0.5}>
                    {pipe(
                        datasets,
                        A.map((d) => (
                            <ShapeIcon
                                oval
                                key={d.dataSource.id}
                                {...getDatasetShapeColor(d)}
                            />
                        ))
                    )}
                </Stack>
            }
            sx={{
                minHeight: (theme) => theme.spacing(32),
            }}
            subtitle={
                <Stack width={"100%"} height={"100%"} gap={2}>
                    <Typography>
                        Proceeding will clear these filters, are you sure you
                        want to proceed?
                    </Typography>
                    <HorizontalScroller>
                        <Grid container flexWrap="nowrap" gap={1}>
                            {pipe(
                                datasets,
                                A.flatMap((d) => d.filters.filters),
                                A.map((f) => {
                                    const styles = pipe(
                                        datasets.find((d) =>
                                            includes(d.filters.filters, f)
                                        ),
                                        O.fromNullable,
                                        O.fold(
                                            () => undefined,
                                            (d) =>
                                                d.mapTemplateDataset?.styles ||
                                                undefined
                                        )
                                    );

                                    return (
                                        <Grid item key={f.id}>
                                            <TableFilterChip
                                                column={f.column}
                                                condition={mapFilterCriteriaSign(
                                                    {
                                                        operator: f.operator,
                                                        verbose: true,
                                                    }
                                                )}
                                                value={f.value || undefined}
                                                styles={
                                                    styles
                                                        ? pick(styles, [
                                                              "fill",
                                                              "stroke",
                                                          ])
                                                        : undefined
                                                }
                                            />
                                        </Grid>
                                    );
                                })
                            )}
                        </Grid>
                    </HorizontalScroller>
                </Stack>
            }
        />
    );
};

export default FilterAppliedChip;
