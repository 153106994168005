import {
    DatasetGeometryPoint,
    PointDataInput,
    useFetchSnowflakePointDataQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    Checkbox,
    Grid,
    IconButton,
    LoadingBar,
    OverflowingTypography,
    ProgressBar,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    CloseOutline,
    KeepOffOutline,
    KeepOutline,
} from "@biggeo/bg-ui/lab/icons";
import { bgLongToString } from "@biggeo/bg-utils";
import * as O from "fp-ts/Option";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import startCase from "lodash/startCase";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { formatNumberWithCommas } from "../utils/utils";
import {
    checkboxTooltipId,
    dataPointTooltipCloseIconId,
    pinTooltipId,
} from "./map-tooltip/redux/hooks";
import { useMap } from "./mapbox/context";
import { setDatasetContext } from "./mapbox/context/context-utils";
import { setSelectedRowPulsingDot } from "./mapbox/utils/map-utils";

const ErrorView = ({
    title,
    message,
    ctaText,
    cta,
}: {
    title?: string;
    message?: string;
    ctaText?: string;
    cta?: () => void;
}) => {
    return (
        <Stack
            gap={4}
            sx={{
                padding: 2,
                borderRadius: (theme) => theme.radius.default,
                background: (theme) => theme.palette.info.container,
                color: (theme) => theme.palette.info.onContainer,
            }}
        >
            <Stack gap={2}>
                <Typography variant="title2" fontWeight="bold">
                    {title}
                </Typography>
                <Typography variant="title3">{message}</Typography>
            </Stack>
            {ctaText && cta && (
                <Button
                    color="info"
                    onClick={cta}
                    sx={{ alignSelf: "flex-end" }}
                >
                    Re-index Dataset
                </Button>
            )}
        </Stack>
    );
};

const LoadingView = ({
    totalSize,
    progress,
}: { totalSize?: number; progress?: number }) => {
    return (
        <Stack>
            <Stack gap={1} sx={{ padding: 2 }}>
                <Grid container justifyContent="space-between" gap={1}>
                    <Typography variant="title4">
                        {progress && totalSize
                            ? `Processing ${progress}KB of ${totalSize}KB`
                            : "Processing"}
                    </Typography>
                    {progress && totalSize && (
                        <Typography variant="title2" fontWeight="bold">
                            {`${Math.round((progress / totalSize) * 100)}% Done`}
                        </Typography>
                    )}
                </Grid>
                {progress && totalSize ? (
                    <ProgressBar color="success" value={progress / totalSize} />
                ) : (
                    <LoadingBar color="success" />
                )}
            </Stack>
            <Stack
                gap={1}
                sx={{ paddingX: 2, paddingTop: 2, paddingBottom: 4 }}
            >
                <Typography variant="title2" fontWeight="bold" align="center">
                    Nothing to see yet...
                </Typography>
                <Typography
                    variant="title4"
                    align="center"
                    textColor="disabled.onContainer"
                >
                    The dataset is currently processing, once complete you will
                    be able to see the point details here.
                </Typography>
            </Stack>
        </Stack>
    );
};

export const PointDataContainer = ({
    selectedPoint,
}: {
    selectedPoint: PointDataInput;
}) => {
    const toPage = useNavigate();
    const checkboxElement = document.getElementById(checkboxTooltipId);
    const pinElement = document.getElementById(pinTooltipId);

    const { datasets, map } = useMap();
    const { updateDataset } = setDatasetContext();

    const dataset = datasets.find(
        (d) => d.dataSource.id === selectedPoint.databaseId
    );
    const selectedRows = dataset?.selectedRows || [];
    const pinnedRows = dataset?.pinnedRows || [];

    const tableId = pipe(
        dataset,
        O.fromNullable,
        O.fold(
            () => undefined,
            (data) => data.dataSource.tableId || undefined
        )
    );
    const hasTableId = Boolean(tableId);

    const id =
        (selectedPoint.id.uint64
            ? bgLongToString(selectedPoint.id.uint64)
            : selectedPoint.id.string) || undefined;

    const {
        queryReturn: { data, error, loading },
    } = useFetchSnowflakePointDataQuery({
        variables: {
            input: selectedPoint,
        },
        skip: !hasTableId,
    });
    const pointData = data?.fetchSnowflakePointData.data;

    const isPresent = (i: {
        tableId: string;
        id: string;
        rows: DatasetGeometryPoint[];
    }) => Boolean(i.rows.find(({ row }) => row[i.tableId].toString() === i.id));

    const onCheckboxClick = (i: {
        tableId: string;
        id: string;
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        data: any;
        rows: DatasetGeometryPoint[];
    }) => {
        const isPointChecked = isPresent({
            tableId: i.tableId,
            id: i.id,
            rows: i.rows,
        });

        const row: DatasetGeometryPoint = {
            dataSourceId: selectedPoint.databaseId,
            mapTemplateDatasetId: dataset?.mapTemplateDataset?.id,
            tableId: i.tableId,
            row: { ...i.data, id: i.data[i.tableId], idType: "tableId" },
        };
        const selectedRows = isPointChecked
            ? pipe(
                  i.rows,
                  A.filter(({ row }) => row[i.tableId].toString() !== i.id)
              )
            : pipe(i.rows, A.concat([row]));

        updateDataset({
            dataSourceId: selectedPoint.databaseId,
            dataset: {
                selectedRows,
            },
        });

        if (map.current) {
            setSelectedRowPulsingDot({
                action: isPointChecked ? "update" : "add",
                datasets: pipe(
                    datasets,
                    A.map((d) =>
                        d.dataSource.id === selectedPoint.databaseId
                            ? { ...d, selectedRows }
                            : d
                    )
                ),
                map,
                isLoaded: true,
            });
        }
    };
    const onPinClick = (i: {
        tableId: string;
        id: string;
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        data: any;
        rows: DatasetGeometryPoint[];
    }) => {
        const isPointPinned = isPresent({
            tableId: i.tableId,
            id: i.id,
            rows: i.rows,
        });

        const row: DatasetGeometryPoint = {
            dataSourceId: selectedPoint.databaseId,
            mapTemplateDatasetId: dataset?.mapTemplateDataset?.id,
            tableId: i.tableId,
            row: { ...i.data, id: i.data[i.tableId], idType: "tableId" },
        };

        const pinnedRows = isPointPinned
            ? pipe(
                  i.rows,
                  A.filter(({ row }) => row[i.tableId].toString() !== i.id)
              )
            : pipe(i.rows, A.concat([row]));

        updateDataset({
            dataSourceId: selectedPoint.databaseId,
            dataset: {
                pinnedRows,
            },
        });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        // It seems that the popper stops event propagation.
        // This is the only way to catch the click event on the checkbox or pin.
        // Using onClick or onChange direclty on the Checkbox or pin IconButton doesn't work.
        if (tableId && id && pointData) {
            if (checkboxElement) {
                checkboxElement.addEventListener("click", () => {
                    onCheckboxClick({
                        tableId,
                        id,
                        data: pointData,
                        rows: selectedRows,
                    });
                });
            }
            if (pinElement) {
                pinElement.addEventListener("click", () => {
                    onPinClick({
                        tableId,
                        id,
                        data: pointData,
                        rows: pinnedRows,
                    });
                });
            }
        }

        return () => {
            if (tableId && id && pointData) {
                if (checkboxElement) {
                    checkboxElement.removeEventListener("click", () => {
                        onCheckboxClick({
                            tableId,
                            id,
                            data: pointData,
                            rows: selectedRows,
                        });
                    });
                }
                if (pinElement) {
                    pinElement.removeEventListener("click", () => {
                        onPinClick({
                            tableId,
                            id,
                            data: pointData,
                            rows: pinnedRows,
                        });
                    });
                }
            }
        };
    }, [
        checkboxElement,
        pinElement,
        tableId,
        id,
        selectedRows.length,
        pinnedRows.length,
        pointData,
    ]);

    return (
        <Stack
            sx={{
                maxHeight: 60.75,
                width: 56.25,
            }}
        >
            <Grid
                container
                gap={1}
                flexWrap="nowrap"
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{
                    padding: 2,
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                }}
            >
                <Stack flexDirection="row" gap={2} alignItems="center">
                    {tableId && id && pointData && (
                        <Stack flexDirection="row" gap={2} alignItems="center">
                            <Checkbox
                                id={checkboxTooltipId}
                                checked={isPresent({
                                    tableId,
                                    id,
                                    rows: selectedRows,
                                })}
                                disabled={!!error || loading}
                            />
                            <IconButton
                                id={pinTooltipId}
                                variant="minimal"
                                density="dense"
                            >
                                {isPresent({
                                    tableId,
                                    id,
                                    rows: pinnedRows,
                                }) ? (
                                    <KeepOffOutline size="xs" />
                                ) : (
                                    <KeepOutline size="xs" />
                                )}
                            </IconButton>
                        </Stack>
                    )}
                    <span>
                        <OverflowingTypography
                            variant="title4"
                            fontWeight="bold"
                            sx={{
                                maxWidth: (theme) => theme.spacing(35),
                            }}
                        >
                            {pipe(
                                dataset,
                                O.fromNullable,
                                O.fold(
                                    () => {
                                        return startCase(
                                            selectedPoint.databaseId
                                        );
                                    },
                                    (data) => {
                                        return (
                                            data.dataSource.label ||
                                            data.dataSource.tableName
                                        );
                                    }
                                )
                            ) || "No data found"}
                        </OverflowingTypography>
                    </span>
                </Stack>
                <IconButton
                    id={dataPointTooltipCloseIconId}
                    variant="ghost"
                    sx={{ padding: 0.5 }}
                >
                    <CloseOutline size="xxs" />
                </IconButton>
            </Grid>

            <Stack sx={{ overflow: "auto", padding: 2 }}>
                {pipe(
                    pointData,
                    O.fromNullable,
                    O.fold(
                        () =>
                            error || !hasTableId ? (
                                <ErrorView
                                    title={
                                        error
                                            ? undefined
                                            : "We need to re-index your dataset"
                                    }
                                    message={
                                        error
                                            ? undefined
                                            : "The Dataset that you're trying to access is missing the table id. Please re-index the dataset by clicking the button below."
                                    }
                                    ctaText={
                                        error ? undefined : "Re-index Dataset"
                                    }
                                    cta={
                                        error
                                            ? undefined
                                            : () => toPage("/datasets")
                                    }
                                />
                            ) : (
                                <LoadingView />
                            ),
                        (pointData) => (
                            <Stack gap={1}>
                                {Object.entries(pointData).map(([k, v]) => {
                                    return (
                                        <Grid
                                            key={k}
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                            flexWrap="nowrap"
                                        >
                                            <Grid item xs={6}>
                                                <OverflowingTypography
                                                    variant="body3"
                                                    textColor="disabled.onContainer"
                                                >
                                                    {k}
                                                </OverflowingTypography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <OverflowingTypography
                                                    variant="body4"
                                                    fontWeight="semibold"
                                                >
                                                    {typeof v === "string"
                                                        ? v
                                                        : typeof v === "number"
                                                          ? formatNumberWithCommas(
                                                                v
                                                            )
                                                          : JSON.stringify(v)}
                                                </OverflowingTypography>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Stack>
                        )
                    )
                )}
            </Stack>
        </Stack>
    );
};
