import {
    CollectionProgressStatus,
    DataSource,
    DatabaseType,
    InputUpdateDataSource,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    DragAndDropContext,
    DroppableColumn,
    EmptyState,
    Stack,
} from "@biggeo/bg-ui/lab";
import { ActionKeyOutline } from "@biggeo/bg-ui/lab/icons";
import { WithPartialValues, toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { ColorSwatchOption } from "../../common/components/ColorSwatchSelector";
import { PreviewDatasetCellItem } from "../../common/components/PreviewDatasetCellItem";
import { StatisticsDownloadProgressWrapper } from "../../common/components/StatisticsDownloadProgressWrapper";
import { getLinearGradient } from "../../common/utils/gradient";
import { SetDatasetContextType } from "../../map/mapbox/context/context-utils";
import {
    MapContextDataset,
    MapContextPreviewDataset,
    useMap,
} from "../../map/mapbox/context/map";
import { DEFAULT_SHAPE_COLOR } from "../../map/mapbox/hooks/style-hooks";
import { setDatasetVisibility } from "../../map/mapbox/utils/data-layers-utils";
import {
    setPreviewDatasetLayers,
    setPreviewDatasetVisibility,
} from "../../map/mapbox/utils/preview-data-layers-utils";
import PreviewDatasetItem from "./PreviewDatasetItem";

export type LinearGradientWithId = {
    offset: number;
    color: string;
    id: number;
};

export type ExploreDatasetsViewProps = {
    readonly datasets: MapContextDataset[];
    readonly exploreDataset: () => void;
    readonly onClickPreviewInfo: (
        input?: E.Either<
            {
                marketplaceDatasetId: string;
            },
            {
                dataSourceId: string;
            }
        >
    ) => void;
    readonly onEdit?: (id: string) => void;
    readonly onRemove?: (id: string) => void;
    readonly onView?: (id: string) => void;
    readonly map: React.MutableRefObject<mapboxgl.Map | null>;
    readonly isLoaded: boolean;
    readonly setPreviewDataset: (values: {
        dataSourceId: string;
        dataset: Partial<WithPartialValues<MapContextPreviewDataset>>;
    }) => void;
    readonly updateDataSource: (input: InputUpdateDataSource) => void;
} & Pick<SetDatasetContextType, "updateDataset" | "reorderDatasets">;

export const ExploreDatasetsView = ({
    datasets,
    exploreDataset,
    onClickPreviewInfo,
    onEdit,
    onRemove,
    onView,
    updateDataset,
    reorderDatasets,
    map,
    isLoaded,
    setPreviewDataset,
    updateDataSource,
}: ExploreDatasetsViewProps) => {
    const { previews } = useMap();

    const handleVisibility = (
        item: MapContextDataset,
        preview?: MapContextPreviewDataset
    ) => {
        if (map.current) {
            if (preview) {
                setPreviewDatasetVisibility({
                    map: map.current,
                    isLoaded,
                    visibility: preview.isVisible ? "none" : "visible",
                });
                setPreviewDataset({
                    dataSourceId: item.dataSource.id,
                    dataset: {
                        isVisible: !preview.isVisible,
                    },
                });
            }

            setDatasetVisibility({
                map: map.current,
                isLoaded,
                prefix: item.dataSource.id,
                levelSets: [],
                visibility: item.isVisible ? "none" : "visible",
                styles: item.mapTemplateDataset?.styles || undefined,
            });
        }

        updateDataset({
            dataSourceId: item.dataSource.id,
            dataset: {
                isVisible: !item.isVisible,
            },
        });
    };

    const onClickPreview = (dataSource: DataSource) => {
        if (dataSource.type === DatabaseType.point) {
            // Statistics table can only be generated for datasets of type point.
            onClickPreviewInfo(
                E.right({
                    dataSourceId: dataSource.id,
                })
            );
        } else {
            onClickPreviewInfo(undefined);
        }
    };

    const updatePreviewHeatmapColor = (i: {
        preview: MapContextPreviewDataset;
        value: ColorSwatchOption;
    }) => {
        const { preview, value } = i;
        const heatmap = {
            ...value,
            id: value.id.toString(),
        };

        if (map.current && isLoaded) {
            const prefix = `${preview.tableName}-${preview.dataSourceId}`;

            setPreviewDatasetLayers({
                map,
                isLoaded,
                prefix,
                styles: {
                    ...preview.styles,
                    dataAggregation: {
                        heatmap,
                    },
                },
            });

            setPreviewDataset({
                dataSourceId: preview.dataSourceId,
                dataset: {
                    styles: {
                        dataAggregation: {
                            heatmap,
                        },
                    },
                },
            });
        }

        updateDataSource({
            id: preview.dataSourceId,
            heatmapColor: JSON.stringify(heatmap),
        });
    };

    return (
        <Stack
            gap={3}
            sx={{
                width: "100%",
                padding: 3,
                cursor: "pointer",
            }}
        >
            {pipe(
                datasets,
                O.fromPredicate((x) => !isEmpty(x)),
                O.foldW(
                    () => (
                        <EmptyState
                            icon={<ActionKeyOutline size={"md"} />}
                            title={"No Datasets To Preview"}
                            subtitle={
                                "When datasets from the marketplace are ready to be previewed, they will appear here"
                            }
                            buttonText={"Data Marketplace"}
                            buttonIcon={<ActionKeyOutline size={"sm"} />}
                            onClick={() => exploreDataset()}
                            border={false}
                        />
                    ),
                    (data) => (
                        <Stack gap={2}>
                            <Button
                                startNode={<ActionKeyOutline />}
                                onClick={() => exploreDataset()}
                                variant="outlined"
                            >
                                Data Marketplace
                            </Button>
                            <PreviewDatasetItem
                                datasets={data}
                                onEdit={onEdit}
                                onRemove={onRemove}
                                onView={onView}
                                updateDataset={updateDataset}
                                reorderDatasets={reorderDatasets}
                                handleVisibility={handleVisibility}
                                onClickPreview={onClickPreview}
                                previews={previews}
                                setPreviewDataset={setPreviewDataset}
                                updatePreviewHeatmapColor={
                                    updatePreviewHeatmapColor
                                }
                            />
                        </Stack>
                    )
                )
            )}
        </Stack>
    );
};
