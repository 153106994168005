import { ApolloQueryResult } from "@apollo/client";
import {
    DataGridFetchInput,
    DatabaseType,
    Exact,
    FetchUnPreviewedMarketplaceDatasetsQuery,
    FilterData,
    FilterItem,
    FilterType,
    InputFetchUnPreviewedDatasets,
    LogicOperator,
    MarketplaceDataset,
    WhereOperator,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    ChipState,
    EmptyState,
    FilterChipPopper,
    FilterTag,
    Grid,
    HorizontalScroller,
    IconButton,
    LogicOperatorPanelContainer,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import {
    CloseOutline,
    DatabaseOutline,
    ExpandMoreOutline,
} from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useState } from "react";
import { match } from "ts-pattern";
import { OnlyAvailableInSnowflakeView } from "../../../common/views/OnlyAvailableInSnowflakeView";
import { IMarketplaceDataViewContainer } from "../../../marketplace/containers/MarketplaceDataViewContainer";
import MarketplaceDatasetPreviewCard from "../../../marketplace/views/MarketplaceDatasetPreviewCard";
import MarketplaceChipPanel from "./MarketplaceChipPanel";

const breakpoints = {
    cxs: 12,
    csm: 6,
    cmd: 6,
    clg: 4,
    cxl: 3,
};

interface IMarketplaceDataView
    extends Omit<
        IMarketplaceDataViewContainer,
        "mpDatasetsLoading" | "mpDatasetsData"
    > {
    readonly isRunningOnSF: boolean;
    readonly marketplaceDatasets: MarketplaceDataset[];
    readonly refetchUnPreviewedMarketplaceDatasets: (
        variables?:
            | Partial<
                  Exact<{
                      input: InputFetchUnPreviewedDatasets;
                  }>
              >
            | undefined
    ) => Promise<ApolloQueryResult<FetchUnPreviewedMarketplaceDatasetsQuery>>;
}

const MarketplaceDataView = ({
    onClose,
    marketplaceDatasets,
    onClickPreviewDataset,
    onClickPreviewInfo,
    refetchUnPreviewedMarketplaceDatasets,
    isRunningOnSF,
}: IMarketplaceDataView) => {
    const getFilterTypeByField = (field: string) =>
        match(field)
            .with("name", () => FilterType.StringType)
            .with("source", () => FilterType.StringType)
            .with("industry", () => FilterType.StringType)
            .otherwise(() => FilterType.NumberType);

    const getFilterData = ({
        type,
        value,
    }: { type: FilterType; value: string | number | boolean }) =>
        match<FilterType, FilterData>(type)
            .with(FilterType.StringType, () => ({
                stringData: value as string,
            }))
            .with(FilterType.NumberType, () => ({
                numberData: Number(value),
            }))
            .otherwise(() => ({
                booleanData: Boolean(value),
            }));

    const getFilterOperator = (type: FilterType) =>
        match(type)
            .with(FilterType.StringType, () => WhereOperator.contains)
            .with(FilterType.NumberType, () => WhereOperator.gte)
            .otherwise(() => WhereOperator.eq);

    const [currentPreviewDataset, setCurrentPreviewDataset] = useState<
        string | undefined
    >(undefined);

    const onCardClick = (marketplaceDataset: MarketplaceDataset) => {
        // Statistics table can only be generated for datasets of type point.
        if (marketplaceDataset.type === DatabaseType.point) {
            onClickPreviewInfo(
                E.left({
                    marketplaceDatasetId: marketplaceDataset.id,
                })
            );
        } else {
            onClickPreviewInfo(undefined);
        }
    };

    const onPreviewClick = (marketplaceDataset: MarketplaceDataset) => {
        setCurrentPreviewDataset(marketplaceDataset.id);
        onClickPreviewDataset(marketplaceDataset, {
            onSuccess: () => {
                setCurrentPreviewDataset(undefined);
            },
            onError: () => {
                setCurrentPreviewDataset(undefined);
            },
        });
    };

    const [datasetFilters, setDatasetFilters] = useState<DataGridFetchInput>({
        pageLimit: 20,
        pageIndex: 0,
        filterObject: {
            filters: [],
            logicOperator: LogicOperator.and,
        },
    });

    const [localLogicOperator, setLocalLogicOperator] = useState<LogicOperator>(
        LogicOperator.and
    );

    const filters = [
        {
            label: "Name",
            field: "name",
        },
        {
            label: "Source",
            field: "source",
        },
        {
            label: "Size",
            field: "size",
        },
        {
            label: "Price",
            field: "price",
        },
        {
            label: "Industry",
            field: "industry",
        },
    ];

    const [chipState, setChipState] = useState<ChipState[]>([
        ...pipe(
            filters,
            A.map((item) => ({ id: item.field, open: false }))
        ),
        ...pipe(
            filters,
            A.map((item) => ({ id: `${item.field}-1`, open: false }))
        ),
        ...[
            { id: "Operator", open: false },
            { id: "Operator-1", open: false },
        ],
    ]);
    const getChipState = (field?: string) => {
        return pipe(
            chipState,
            A.findFirst((item) => isEqual(item.id, field)),
            O.foldW(
                () => undefined,
                (data) => data
            )
        );
    };

    const toggleHeaderOpen = ({ id, open }: ChipState) => {
        const idToCompare = isEqual(id, "Operator")
            ? "Operator"
            : id.toLowerCase();
        setChipState((prev) => {
            return pipe(
                prev,
                A.map((item) =>
                    isEqual(item.id, idToCompare) ? { ...item, open } : item
                )
            );
        });
    };

    const toggleFilterLogicOperator = (value: LogicOperator) => {
        setLocalLogicOperator(value);
        setDatasetFilters((prev) => {
            const { filterObject } = prev;
            const updatedFilterObject = filterObject && {
                ...filterObject,
                logicOperator: value,
            };
            pipe(
                datasetFilters.filterObject?.filters,
                O.fromPredicate((x) => !isEmpty(x)),
                O.foldW(
                    () => {},
                    () => {
                        refetchUnPreviewedMarketplaceDatasets({
                            input: {
                                dataGridFetchInput: {
                                    ...prev,
                                    filterObject: updatedFilterObject,
                                },
                            },
                        });
                    }
                )
            );
            return { ...prev, filterObject: updatedFilterObject };
        });
    };

    const handleApplyFilter = (field: string) => {
        toggleHeaderOpen({ id: field, open: false });
    };

    const updateFilter = ({
        field,
        value,
    }: { field: string; value: string | number | boolean }) => {
        setDatasetFilters((prev: DataGridFetchInput) => {
            const { filterObject } = prev;
            if (filterObject?.filters) {
                const type = getFilterTypeByField(field);
                filterObject.filters = pipe(
                    filterObject.filters,
                    O.fromPredicate((x) =>
                        pipe(
                            x,
                            A.some((item) => isEqual(item.column, field))
                        )
                    ),
                    O.foldW(
                        () => {
                            return [
                                ...filterObject.filters,
                                {
                                    type,
                                    column: field,
                                    data: getFilterData({ type, value }),
                                    operator: getFilterOperator(type),
                                },
                            ];
                        },
                        (filters) =>
                            pipe(
                                filters,
                                A.map((item: FilterItem) => {
                                    if (isEqual(item.column, field)) {
                                        return {
                                            ...item,
                                            data: getFilterData({
                                                type,
                                                value,
                                            }),
                                        } as FilterItem;
                                    }
                                    return item;
                                })
                            )
                    )
                );
            }
            refetchUnPreviewedMarketplaceDatasets({
                input: {
                    dataGridFetchInput: { ...prev, filterObject },
                },
            });
            return { ...prev, filterObject };
        });
    };

    const getFieldValue = (field: string): string | number | boolean =>
        pipe(
            datasetFilters.filterObject,
            O.fromNullable,
            O.foldW(
                () => undefined,
                ({ filters: filterObjFilters }) => {
                    return pipe(
                        filterObjFilters,
                        A.findFirst((_item) => isEqual(_item.column, field)),
                        O.foldW(
                            () => undefined,
                            (result) => {
                                return Object.values(result.data)[0];
                            }
                        )
                    );
                }
            )
        );

    const removeFilter = (field: string) => {
        setDatasetFilters((prev) => {
            const { filterObject } = prev;
            if (filterObject?.filters) {
                const updatedFilters = pipe(
                    filterObject.filters,
                    A.filter((item) => !isEqual(item.column, field))
                );
                filterObject.filters = updatedFilters;

                refetchUnPreviewedMarketplaceDatasets({
                    input: {
                        dataGridFetchInput: { ...prev, filterObject },
                    },
                });

                return { ...prev, filterObject };
            }
            return prev;
        });
    };

    return (
        <Stack height={"100%"}>
            <Grid
                container
                gap={2}
                sx={{
                    padding: 2,
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                }}
            >
                <Grid item xs minWidth={0} alignSelf="center">
                    <Typography
                        variant="title3"
                        fontWeight="bold"
                        textColor="background.onMain"
                        truncate
                    >
                        Data Marketplace
                    </Typography>
                </Grid>
                <IconButton
                    variant="ghost"
                    onClick={onClose}
                    sx={{ padding: 2, borderRadius: "default" }}
                >
                    <ExpandMoreOutline size="xs" />
                </IconButton>
            </Grid>
            {isRunningOnSF ? (
                <Stack
                    gap={4}
                    flexDirection="column"
                    sx={{
                        padding: 3,
                        overflow: "auto",
                        containerType: "inline-size",
                    }}
                >
                    <Stack gap={1}>
                        {!isEmpty(marketplaceDatasets) && (
                            <>
                                <HorizontalScroller>
                                    <Grid container flexWrap="nowrap" gap={2}>
                                        {filters.map((item) => {
                                            const chipState = getChipState(
                                                item.field
                                            );
                                            return (
                                                <FilterChipPopper
                                                    key={item.field}
                                                    chipLabel={item.label}
                                                    content={
                                                        <MarketplaceChipPanel
                                                            item={item}
                                                            updateFilter={
                                                                updateFilter
                                                            }
                                                            handleApplyFilter={
                                                                handleApplyFilter
                                                            }
                                                            defaultValue={getFieldValue(
                                                                item.field
                                                            )}
                                                        />
                                                    }
                                                    open={chipState?.open}
                                                    setOpen={toggleHeaderOpen}
                                                />
                                            );
                                        })}
                                        {datasetFilters.filterObject &&
                                            !isEmpty(
                                                datasetFilters.filterObject
                                                    .filters
                                            ) && (
                                                <FilterChipPopper
                                                    chipLabel={"Operator"}
                                                    content={
                                                        <LogicOperatorPanelContainer
                                                            colLogicOperator={
                                                                localLogicOperator
                                                            }
                                                            handleLogicOperatorChange={
                                                                toggleFilterLogicOperator
                                                            }
                                                            onClose={() => {
                                                                toggleHeaderOpen(
                                                                    {
                                                                        id: "Operator",
                                                                        open: false,
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    open={
                                                        getChipState("Operator")
                                                            ?.open
                                                    }
                                                    setOpen={toggleHeaderOpen}
                                                />
                                            )}
                                    </Grid>
                                </HorizontalScroller>
                                <HorizontalScroller>
                                    <Grid container flexWrap="nowrap" gap={2}>
                                        {pipe(
                                            filters,
                                            A.map((item) => {
                                                const headerState =
                                                    getChipState(
                                                        `${item.field}-1`
                                                    );
                                                return pipe(
                                                    getFieldValue(item.field),
                                                    O.fromNullable,
                                                    O.fold(
                                                        () => <></>,
                                                        () => {
                                                            const type =
                                                                getFilterTypeByField(
                                                                    item.field
                                                                );
                                                            return (
                                                                <FilterTag
                                                                    key={
                                                                        item.field
                                                                    }
                                                                    startNode={
                                                                        <IconButton
                                                                            variant="minimal"
                                                                            onClick={() =>
                                                                                removeFilter(
                                                                                    item.field
                                                                                )
                                                                            }
                                                                        >
                                                                            <CloseOutline size="xs" />
                                                                        </IconButton>
                                                                    }
                                                                    field={capitalize(
                                                                        item.field
                                                                    )}
                                                                    operator={getFilterOperator(
                                                                        type
                                                                    )}
                                                                    content={
                                                                        <MarketplaceChipPanel
                                                                            item={
                                                                                item
                                                                            }
                                                                            updateFilter={
                                                                                updateFilter
                                                                            }
                                                                            handleApplyFilter={
                                                                                handleApplyFilter
                                                                            }
                                                                            defaultValue={getFieldValue(
                                                                                item.field
                                                                            )}
                                                                            isSubChip
                                                                        />
                                                                    }
                                                                    open={
                                                                        headerState?.open
                                                                    }
                                                                    setOpen={
                                                                        toggleHeaderOpen
                                                                    }
                                                                />
                                                            );
                                                        }
                                                    )
                                                );
                                            })
                                        )}
                                    </Grid>
                                </HorizontalScroller>
                            </>
                        )}
                    </Stack>
                    {pipe(
                        marketplaceDatasets,
                        O.fromPredicate((x) => !A.isEmpty(x)),
                        O.fold(
                            () => (
                                <EmptyState
                                    icon={<DatabaseOutline />}
                                    title={"No Datasets Enabled"}
                                    subtitle={
                                        "Datasets available to purchase from data providers will appear here."
                                    }
                                    border={false}
                                />
                            ),
                            (datasets) => (
                                <>
                                    <Grid container spacing={4}>
                                        {datasets.map((dataset) => {
                                            return (
                                                <Grid
                                                    item
                                                    key={dataset.id}
                                                    {...breakpoints}
                                                >
                                                    <MarketplaceDatasetPreviewCard
                                                        price={dataset.price}
                                                        name={dataset.name}
                                                        readonly={
                                                            dataset.type ===
                                                            DatabaseType.polygon
                                                        }
                                                        onPreviewClick={() => {
                                                            onPreviewClick(
                                                                dataset
                                                            );
                                                        }}
                                                        onCardClick={() =>
                                                            onCardClick(dataset)
                                                        }
                                                        marketplaceDatasetId={
                                                            dataset.id
                                                        }
                                                        image={
                                                            dataset.img || ""
                                                        }
                                                        size={dataset.size}
                                                        isDisabled={
                                                            !!currentPreviewDataset
                                                        }
                                                    />
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </>
                            )
                        )
                    )}
                </Stack>
            ) : (
                <Stack sx={{ overflow: "auto" }}>
                    <OnlyAvailableInSnowflakeView />
                </Stack>
            )}
        </Stack>
    );
};

export default MarketplaceDataView;
