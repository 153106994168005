import { DataSource } from "@biggeo/bg-server-lib/datascape-ai";
import {
    EmptyState,
    FlexScrollArea,
    FlexScrollAreaContainer,
    Grid,
    MarketplaceBanner,
    MarketplaceDatasetCard,
    Pricing,
} from "@biggeo/bg-ui/lab";
import { DatabaseOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router";
import { boolean } from "zod";
import { Routes } from "../../navigation/redux/model";
import { formatNumberWithCommas } from "../../utils/utils";

export type AvailableDataSource = DataSource & {
    readonly isPurchased?: boolean;
    readonly fkProviderId: string;
    readonly price: Pricing[];
    readonly contactLink?: string;
    readonly path: string;
};
export type MarketplaceDatasetsProps = {
    readonly datasources: readonly AvailableDataSource[];
};

export const MarketplaceDatasets = ({
    datasources,
}: MarketplaceDatasetsProps) => {
    const toPage = useNavigate();

    const onConnect = (id: string) => {
        console.log("id", id);
    };

    const onRequest = (type: "access" | "purchase", productId: string) => {
        console.log(type, productId);
    };
    return (
        <FlexScrollAreaContainer>
            <FlexScrollArea gap={4} flexDirection="column" sx={{ padding: 4 }}>
                <MarketplaceBanner
                    title="Datasets"
                    subtitle="Find indexed and ready to go datasets from your favorite data providers"
                />
                {pipe(
                    datasources,
                    O.fromPredicate((x) => !isEmpty(x)),
                    O.fold(
                        () => (
                            <EmptyState
                                title="No Datasets Added Yet"
                                subtitle="When data providers publish dataset listings, they will appear here."
                                icon={<DatabaseOutline size="md" />}
                            />
                        ),
                        (datasource) => {
                            return (
                                <Grid
                                    container
                                    spacing={4}
                                    alignItems="stretch"
                                >
                                    {pipe(
                                        datasource,
                                        toNonReadonlyArray,
                                        A.map((dataset) => {
                                            const handleSalesClick = () => {
                                                const url =
                                                    dataset.contactLink || "";
                                                if (url.startsWith("http")) {
                                                    window.open(url, "_blank");
                                                } else {
                                                    toPage(url);
                                                }
                                            };
                                            return (
                                                <Grid
                                                    item
                                                    key={dataset.id}
                                                    sx={{ display: "flex" }}
                                                    xl={4}
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <MarketplaceDatasetCard
                                                        productId={dataset.id}
                                                        bgIndexed={
                                                            dataset.isConnected
                                                        }
                                                        description={
                                                            dataset.description ||
                                                            ""
                                                        }
                                                        size={`${formatNumberWithCommas(dataset.size)} rows`}
                                                        source={{
                                                            image:
                                                                dataset.icon ||
                                                                "",
                                                            name: "",
                                                        }}
                                                        title={
                                                            dataset.collectionName
                                                        }
                                                        price={dataset.price}
                                                        isAdmin={false}
                                                        onConnect={() => {
                                                            onConnect(
                                                                dataset.id
                                                            );
                                                        }}
                                                        onRequest={({
                                                            type,
                                                            productId,
                                                        }) => {
                                                            onRequest(
                                                                type,
                                                                productId
                                                            );
                                                        }}
                                                        isPurchased={
                                                            dataset.isPurchased
                                                        }
                                                        onClick={() => {
                                                            toPage(
                                                                `${Routes.exploreDataset}/${dataset.id}`
                                                            );
                                                        }}
                                                        onContactSales={
                                                            handleSalesClick
                                                        }
                                                    />
                                                </Grid>
                                            );
                                        })
                                    )}
                                </Grid>
                            );
                        }
                    )
                )}
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};
