import {
    DataSourceExtended,
    DataSourcesExtended,
    FetchMapTemplateQuery,
    useFetchMapTemplateQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar, Stack } from "@biggeo/bg-ui/lab";
import { map2, match as vtMatch } from "@vividtheory/remotedata";
import { useState } from "react";
import { ErrorPage } from "../../common/components/ErrorPage";
import { Consumers } from "../../common/redux/model";
import { usePreviewDatasets } from "../../database-meta-data/redux/hooks";
import MapViewWrapper, { MapTabs } from "../../map/map-wrappers/MapViewWrapper";
import { useMapTemplateDatasets } from "../../map/mapbox/context/hooks";

// interface IMarketPlaceContainer {
//     readonly setIsExporting: (isExporting: boolean) => void;
// }

export const MarketplaceContainer = () => {
    const [consumption, setConsumption] = useState(Consumers.mapbox);
    const {
        queryReturn: { data },
        remote: fetchMapTemplateRd,
    } = useFetchMapTemplateQuery({
        variables: {
            input: {
                isMarketplaceMapTemplate: true,
            },
        },
    });

    const previewDatasets = usePreviewDatasets();
    const { datasets, loading, error } = useMapTemplateDatasets({
        fkMapTemplateId: Number(data?.fetchMapTemplate.id),
    });

    const mapped = map2(
        (mapTemplate: FetchMapTemplateQuery) => (_p: DataSourceExtended[]) => ({
            datasets,
            mapTemplate: mapTemplate.fetchMapTemplate,
        }),
        fetchMapTemplateRd,
        previewDatasets
    );

    if (loading) {
        return <LoadingBar />;
    }

    if (error) {
        return <ErrorPage subtitle={error.message} />;
    }

    return (
        <Stack width="100%">
            {vtMatch(mapped, {
                _: () => <LoadingBar />,
                Success: ({ mapTemplate }) => {
                    return (
                        <MapViewWrapper
                            currentTab={MapTabs.map}
                            activeConsumption={consumption}
                            setConsumption={setConsumption}
                            mapTemplateId={mapTemplate.id}
                            isFromMarketplace={true}
                            datasets={datasets}
                        />
                    );
                },
            })}
        </Stack>
    );
};
