import {
    AnyResponse,
    FilterCriteriaDatasetItem,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColumnHeaderParams, GridRenderCellParams } from "@biggeo/bg-ui";
import {
    BadgeDot,
    Grid,
    IconButton,
    LogicOperator,
    OverflowingTypography,
    Stack,
} from "@biggeo/bg-ui/lab";
import {
    DisplayExternalInputOutline,
    KeepOffOutline,
    KeepOutline,
} from "@biggeo/bg-ui/lab/icons";
import { Formik } from "formik";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import size from "lodash/size";
import { formatNumberWithCommas } from "../../../utils/utils";
import { MapContextDatasetFilter } from "../../mapbox/context";
import { updateSelectedById } from "../../utils/utils";
import { DatasetTableProps } from "./DatasetTable";
import { DatasetTableGrid, DatasetTableGridProps } from "./DatasetTableGrid";
import { DatasetTableHeader } from "./DatasetTableHeader";

interface IDatasetTableView
    extends Pick<
            DatasetTableProps,
            "type" | "onGoToClick" | "view" | "setView"
        >,
        Pick<
            DatasetTableGridProps,
            | "loading"
            | "dataPaginationProps"
            | "columns"
            | "getColumnAttributes"
            | "selectedRows"
            | "data"
            | "tableId"
        > {
    readonly initialValues: MapContextDatasetFilter;
    readonly onSubmit: (values: MapContextDatasetFilter) => void;
    readonly onApplyFilter: (f: FilterCriteriaDatasetItem[]) => void;
    readonly isFilteringMenuDisabled: boolean;
    readonly isGoToFieldHidden?: boolean;
    readonly onOperatorChange: (operator: LogicOperator) => void;
    readonly onSelectedRowsChange: (r: AnyResponse["data"]) => void;
    readonly pinnedRows: AnyResponse["data"];
    readonly onPinnedRowsChange: (i: {
        action: "pin" | "unpin";
        rows: AnyResponse["data"];
    }) => void;
}

const DatasetTableView = ({
    type,
    initialValues,
    onSubmit,
    data,
    dataPaginationProps,
    loading,
    columns,
    selectedRows,
    onSelectedRowsChange,
    getColumnAttributes,
    onApplyFilter,
    onGoToClick,
    isFilteringMenuDisabled,
    onOperatorChange,
    isGoToFieldHidden = false,
    view,
    setView,
    tableId,
    pinnedRows,
    onPinnedRowsChange,
}: IDatasetTableView) => {
    return (
        <Formik<MapContextDatasetFilter>
            validateOnMount
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                actions.setSubmitting(false);
                onSubmit(values);
            }}
        >
            {({ values, setValues, isValid, dirty, handleSubmit }) => {
                return (
                    <Stack
                        height={"100%"}
                        width={"100%"}
                        sx={{
                            border: (theme) =>
                                `1px dashed ${theme.palette.stroke[200]}`,
                            borderRadius: (theme) => theme.radius.xs3,
                        }}
                    >
                        <DatasetTableHeader
                            filters={values}
                            view={view}
                            setView={setView}
                            isExportDisabled
                            isRemoveDisabled={type === "filteredData"}
                            isSaveCriteriaDisabled={!isValid || !dirty}
                            isClearFilterDisabled={type === "filteredData"}
                            showing={{
                                current: formatNumberWithCommas(
                                    data.data.length
                                ),
                                all: formatNumberWithCommas(data.count || 0),
                            }}
                            onOperatorChange={onOperatorChange}
                            onClearFilters={() => {
                                setValues({ ...values, filters: [] });
                                if (type === "data") {
                                    onApplyFilter([]);
                                }
                            }}
                            onFilterRemove={(id) => {
                                const f = pipe(
                                    values.filters,
                                    A.filter((i) => i.id !== id)
                                );

                                setValues({ ...values, filters: f });

                                if (type === "data") {
                                    onApplyFilter(f);
                                }
                            }}
                            onSaveCriteria={
                                type === "filteredData"
                                    ? () => handleSubmit()
                                    : undefined
                            }
                        />
                        <DatasetTableGrid
                            data={data}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={onSelectedRowsChange}
                            pinnedRows={pinnedRows}
                            loading={loading}
                            tableId={tableId}
                            dataPaginationProps={dataPaginationProps}
                            getColumnAttributes={getColumnAttributes}
                            isFilteringMenuDisabled={isFilteringMenuDisabled}
                            hasFilters={!isEmpty(values.filters)}
                            onClearFilters={() => {
                                setValues({ ...values, filters: [] });
                                if (type === "data") {
                                    onApplyFilter([]);
                                }
                            }}
                            onApplyFilters={(filter) => {
                                const f = updateSelectedById(
                                    filter,
                                    values.filters
                                );

                                setValues({ ...values, filters: f });

                                if (type === "data") {
                                    onApplyFilter(f);
                                }
                            }}
                            columns={
                                isEmpty(columns)
                                    ? []
                                    : [
                                          ...(isGoToFieldHidden
                                              ? []
                                              : [
                                                    {
                                                        field: "goTo",
                                                        headerName: "Go to",
                                                        minWidth: 80,
                                                        sortable: false,
                                                        filterable: true,
                                                        disableColumnMenu: true,
                                                        renderCell: (
                                                            params: GridRenderCellParams
                                                        ) => (
                                                            <IconButton
                                                                variant="ghost"
                                                                onClick={() =>
                                                                    onGoToClick(
                                                                        [
                                                                            params.row,
                                                                        ]
                                                                    )
                                                                }
                                                            >
                                                                <DisplayExternalInputOutline />
                                                            </IconButton>
                                                        ),
                                                    },
                                                ]),
                                          {
                                              field: "pin",
                                              headerName: "Pin",
                                              minWidth: 60,
                                              sortable: false,
                                              filterable: true,
                                              disableColumnMenu: true,
                                              renderCell: (
                                                  params: GridRenderCellParams
                                              ) => {
                                                  const isPinned =
                                                      pinnedRows.includes(
                                                          params.row
                                                      );

                                                  return (
                                                      <IconButton
                                                          variant="ghost"
                                                          density={"dense"}
                                                          disableActiveEffect
                                                          disableHoverEffect
                                                          disableFocusEffect
                                                          onClick={() => {
                                                              onPinnedRowsChange(
                                                                  {
                                                                      action: isPinned
                                                                          ? "unpin"
                                                                          : "pin",
                                                                      rows: [
                                                                          params.row,
                                                                      ],
                                                                  }
                                                              );
                                                          }}
                                                      >
                                                          {isPinned ? (
                                                              <KeepOffOutline size="xs" />
                                                          ) : (
                                                              <KeepOutline size="xs" />
                                                          )}
                                                      </IconButton>
                                                  );
                                              },
                                          },
                                          ...pipe(
                                              columns,
                                              A.map((column) => {
                                                  const filtersCount = pipe(
                                                      values.filters,
                                                      A.filter(
                                                          (v) =>
                                                              v.column ===
                                                              column.field
                                                      ),
                                                      size
                                                  );

                                                  return {
                                                      ...column,
                                                      sortable: true,
                                                      minWidth: 180,
                                                      renderHeader: (
                                                          params: GridColumnHeaderParams
                                                      ) => (
                                                          <DatasetTableRenderedHeader
                                                              field={
                                                                  params.field
                                                              }
                                                              filters={
                                                                  isEqual(
                                                                      filtersCount,
                                                                      0
                                                                  )
                                                                      ? undefined
                                                                      : filtersCount
                                                              }
                                                          />
                                                      ),
                                                      ...(column.type ===
                                                          "boolean" && {
                                                          renderCell: (
                                                              params: GridRenderCellParams
                                                          ) => (
                                                              <OverflowingTypography
                                                                  variant="body3"
                                                                  fontWeight="semibold"
                                                              >
                                                                  {params.value.toString()}
                                                              </OverflowingTypography>
                                                          ),
                                                      }),
                                                  };
                                              })
                                          ),
                                      ]
                            }
                        />
                    </Stack>
                );
            }}
        </Formik>
    );
};

export default DatasetTableView;

const DatasetTableRenderedHeader = ({
    field,
    filters,
}: { field: string; filters?: number }) => {
    return (
        <Grid
            container
            width={"100%"}
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            gap={2}
        >
            <Grid item xs>
                <OverflowingTypography
                    variant="body3"
                    fontWeight="semibold"
                    sx={{
                        maxWidth: (theme) => theme.spacing(25),
                    }}
                >
                    {field}
                </OverflowingTypography>
            </Grid>
            {filters && (
                <Grid item>
                    <BadgeDot color="primary">{filters}</BadgeDot>
                </Grid>
            )}
        </Grid>
    );
};
