import {
    Button,
    CircularLoading,
    IconAvatar,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { MapSearch } from "@biggeo/bg-ui/lab/icons";
import { match } from "ts-pattern";

interface IDatasetTableStates {
    readonly state: "empty" | "loading";
    readonly title?: string;
    readonly button?: {
        onClick: () => void;
        text?: string;
    };
}

export const DatasetTableStates = ({
    state,
    title: t,
    button,
}: IDatasetTableStates) => {
    const title = t
        ? t
        : match(state)
              .with("empty", () => "No results found")
              .with("loading", () => "Loading results...")
              .exhaustive();

    return (
        <Stack
            gap={2}
            justifyContent="center"
            alignItems="center"
            height={"100%"}
            width={"100%"}
            sx={{
                height: (theme) => theme.spacing(50),
            }}
        >
            <IconAvatar square variant="tonal" color="surface">
                {match(state)
                    .with("empty", () => <MapSearch />)
                    .with("loading", () => <CircularLoading size="md" />)
                    .exhaustive()}
            </IconAvatar>
            <Typography variant="body2" fontWeight="bold">
                {title}
            </Typography>
            {button && (
                <Button variant="outlined" onClick={button.onClick}>
                    {button.text ?? "Clear Filters"}
                </Button>
            )}
        </Stack>
    );
};
