import {
    Applications,
    FileUploadState,
    FileWithUploadState,
    SingleFileUploadProvider,
    getCookies,
} from "@biggeo/bg-common";
import { IFileUpload } from "@biggeo/bg-common/lib/files/utils/type";
import { Button, ButtonProps, CircularLoading } from "@biggeo/bg-ui/lab";
import { UploadOutline } from "@biggeo/bg-ui/lab/icons";
import isEqual from "lodash/isEqual";
import { CallBacksType } from "../../utils/types.ts";

interface BaseUploadOptions<T> {
    readonly callbacks: CallBacksType<T>;
}

type WithButton<T> = BaseUploadOptions<T> & {
    readonly withButton: true;

    readonly label?: string;
    readonly startIcon?: JSX.Element;
    readonly endIcon?: JSX.Element;
    readonly buttonProps?: ButtonProps;
    readonly disabled?: boolean;
};

type WithComponent<T> = BaseUploadOptions<T> & {
    readonly withButton?: false;

    readonly children: React.FC<{
        readonly file?: FileWithUploadState<T>;
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        readonly onClick: (e: any) => void;
    }>;
};

type UploadFileComponentType<T> = WithButton<T> | WithComponent<T>;

export const UploadSingleImage = <T,>(props: UploadFileComponentType<T>) => {
    const uploadOptions: IFileUpload<T> = {
        fileTypes: ["image/*"],
        cookies: getCookies(Applications.datascape),
        onError: (err) => {
            props.callbacks.onError?.(err.message);
        },
        onSuccess: (file) => {
            props.callbacks.onSuccess?.(file);
        },
        onLoad: () => {
            props.callbacks.onFetch?.();
        },
    };
    return (
        <SingleFileUploadProvider uploadOptions={uploadOptions}>
            {({ input, onClick, file }) => (
                <>
                    {props.withButton ? (
                        <Button
                            variant="outlined"
                            density="dense"
                            onClick={onClick}
                            startNode={
                                isEqual(file.state, FileUploadState.loading) ? (
                                    <CircularLoading size={"xs"} />
                                ) : (
                                    props.startIcon || (
                                        <UploadOutline size={"xs"} />
                                    )
                                )
                            }
                            endNode={props.endIcon}
                            disabled={
                                isEqual(file.state, FileUploadState.loading) ||
                                props.disabled
                            }
                            {...props.buttonProps}
                        >
                            {props.label || "Upload Image"}
                        </Button>
                    ) : (
                        props.children({
                            onClick,
                            file,
                        })
                    )}

                    {input}
                </>
            )}
        </SingleFileUploadProvider>
    );
};
