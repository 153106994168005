import { DatasetStyles } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Divider,
    IconButton,
    OverflowingTypography,
    Stack,
    Tag,
    tagClasses,
} from "@biggeo/bg-ui/lab";
import { CloseOutline } from "@biggeo/bg-ui/lab/icons";
import { ShapeIcon } from "./ShapeIcon";

export type TableFilterChipProps = {
    column: string;
    condition: string;
    value?: string;
    onRemoveClick?: () => void;
    isRemoveDisabled?: boolean;
    styles?: Pick<DatasetStyles, "fill" | "stroke">;
};

export const TableFilterChip = ({
    column,
    condition,
    value,
    onRemoveClick,
    isRemoveDisabled = false,
    styles,
}: TableFilterChipProps) => (
    <Stack flexDirection="row" alignItems="center">
        <Tag
            variant="outlined"
            density="dense"
            rounded
            startNode={
                onRemoveClick ? (
                    <IconButton
                        variant="ghost"
                        density="none"
                        onClick={onRemoveClick}
                        disabled={isRemoveDisabled}
                        disableActiveEffect
                        disableHoverEffect
                        disableFocusEffect
                    >
                        <CloseOutline size="xs" />
                    </IconButton>
                ) : undefined
            }
            sx={{
                [`&.${tagClasses.outlined}`]: {
                    borderLeft: 1,
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.outline,
                    borderRadius: 0,
                    borderTopLeftRadius: (theme) => theme.radius.full,
                    borderBottomLeftRadius: (theme) => theme.radius.full,
                    boxShadow: "none",
                },
            }}
        >
            <Stack flexDirection="row" alignItems="center" gap={2}>
                {styles && (
                    <ShapeIcon
                        oval
                        fill={styles.fill || undefined}
                        stroke={styles.stroke || undefined}
                    />
                )}
                <OverflowingTypography
                    variant="body3"
                    fontWeight="semibold"
                    sx={{
                        maxWidth: (theme) => theme.spacing(14),
                        minWidth: (theme) => theme.spacing(14),
                    }}
                >
                    {column}
                </OverflowingTypography>
            </Stack>
        </Tag>
        <Divider
            orientation="vertical"
            color={200}
            sx={{
                margin: 0,
            }}
        />
        <Tag
            variant="outlined"
            density="dense"
            rounded
            sx={{
                [`&.${tagClasses.outlined}`]: {
                    borderRight: 1,
                    borderTop: 1,
                    borderBottom: 1,
                    borderColor: (theme) => theme.palette.outline,
                    borderRadius: 0,
                    borderTopRightRadius: (theme) => theme.radius.full,
                    borderBottomRightRadius: (theme) => theme.radius.full,
                    boxShadow: "none",
                },
            }}
        >
            <OverflowingTypography
                variant="body3"
                fontWeight="semibold"
                sx={{
                    maxWidth: (theme) => theme.spacing(18),
                    minWidth: (theme) => theme.spacing(18),
                }}
            >
                {value ? `${condition}: ${value}` : condition}
            </OverflowingTypography>
        </Tag>
    </Stack>
);
