import {
    AvatarChip,
    BreakpointSwapper,
    Button,
    Grid,
    HorizontalScroller,
    IconButton,
    Menu,
    Stack,
    Tab,
    TabGroup,
    UserAvatar,
} from "@biggeo/bg-ui/lab";

import { MenuOutline } from "@biggeo/bg-ui/lab/icons";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isAppRunningOnSF } from "../common/redux/hooks";
import { MapTabs } from "../map/map-wrappers/MapViewWrapper";
import { useMap } from "../map/mapbox/context";
import { mapDataActions } from "../map/redux/model";
import { getMapFeatures } from "../map/utils/utils";
import { Routes } from "../navigation/redux/model";

interface IMapHeader {
    readonly currentTab: MapTabs;
    readonly handleMapTabs: (tab: MapTabs) => void;
    readonly saveView: () => void;
    readonly publish?: () => void;
    readonly isExporting?: boolean;
    readonly setIsExporting?: (isExporting: boolean) => void;
}

export const MapHeader = ({
    currentTab,
    handleMapTabs,
    publish,
    saveView,
    isExporting,
    setIsExporting,
}: IMapHeader) => {
    const toPage = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const savedViewId = queryParams.get("savedViewId");
    const { draw, isLoaded, datasets, mapState } = useMap();

    const isRunningOnSF = isAppRunningOnSF();

    const handleDataExport = () => {
        setIsExporting?.(true);
    };

    const onlyInSnowflake = () => {
        toPage(Routes.onlyInSnowflake);
    };

    const selectedSavedAreas = pipe(
        mapState?.selectedSavedAreas.selectableItems,
        O.fromNullable,
        O.fold(
            () => [],
            (savedAreas) =>
                pipe(
                    savedAreas,
                    A.flatMap((item) =>
                        pipe(
                            item.subItems,
                            toNonReadonlyArray,
                            A.filter((sub) => sub.selected),
                            A.map((sub) => sub.id)
                        )
                    )
                )
        )
    );

    const canSaveView =
        !isEmpty(getMapFeatures(draw, isLoaded)) ||
        !isEmpty(
            pipe(
                datasets,
                A.filter((d) => isEqual(d.isSelected, true))
            )
        ) ||
        !isEmpty(selectedSavedAreas);

    const dispatch = useDispatch();
    const isSavedViewPage =
        !isEmpty(location.search) && location.search.includes("savedViewId");

    const exit = () => {
        toPage(
            isSavedViewPage ? Routes.mapTemplateSavedViews : Routes.mapTemplates
        );
        dispatch(mapDataActions.updateMapData({}));
    };

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{
                padding: 4,
                borderBottom: 1,
                borderColor: (theme) => theme.palette.stroke[100],
                height: 16.5,
            }}
        >
            <Grid item xs minWidth={0}>
                <Grid container alignItems="center" flexWrap="nowrap" gap={4}>
                    <AvatarChip
                        rounded
                        density="dense"
                        onClick={exit}
                        startNode={
                            <UserAvatar
                                src="https://biggeo.blob.core.windows.net/media/MapTemplateLogo.png"
                                size="xxs"
                            />
                        }
                        sx={{ flexShrink: 0 }}
                    >
                        Exit
                    </AvatarChip>
                    <Grid item xs minWidth={0}>
                        <HorizontalScroller>
                            <TabGroup
                                variant="tonal"
                                color="surface"
                                value={currentTab}
                                density="dense"
                            >
                                {Object.values(MapTabs).map((tab) => (
                                    <Tab
                                        key={tab}
                                        sx={{ flexShrink: 0 }}
                                        onClick={() => handleMapTabs(tab)}
                                        value={tab}
                                    >
                                        {startCase(tab)}
                                    </Tab>
                                ))}
                            </TabGroup>
                        </HorizontalScroller>
                    </Grid>
                </Grid>
            </Grid>
            {isEqual(currentTab, MapTabs.map) && (
                <BreakpointSwapper
                    item
                    desktop={
                        <Grid item>
                            <Grid container gap={2}>
                                <Grid item flexShrink={0}>
                                    <Button
                                        variant="outlined"
                                        onClick={saveView}
                                        density="dense"
                                        disabled={!canSaveView}
                                    >
                                        {savedViewId
                                            ? "Save Changes"
                                            : "Save View"}
                                    </Button>
                                </Grid>
                                {!isExporting && (
                                    <Button
                                        variant="filled"
                                        onClick={
                                            !isRunningOnSF
                                                ? onlyInSnowflake
                                                : handleDataExport
                                        }
                                        disabled={
                                            !pipe(
                                                datasets,
                                                A.some(
                                                    (item) => item.isSelected
                                                )
                                            )
                                        }
                                    >
                                        Export
                                    </Button>
                                )}
                                {publish && (
                                    <Button
                                        color="surface"
                                        density="dense"
                                        onClick={publish}
                                    >
                                        Publish
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    }
                    mobile={
                        <Grid item>
                            <Menu
                                placement="bottom-start"
                                content={
                                    <Stack
                                        flexGrow={1}
                                        gap={2}
                                        sx={{
                                            width: (theme) => theme.spacing(33),
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={saveView}
                                            density="dense"
                                            disabled={!canSaveView}
                                            fullWidth
                                        >
                                            {savedViewId
                                                ? "Save Changes"
                                                : "Save View"}
                                        </Button>
                                        {/* TODO: To be re-enabled once the rework of dataset export is done */}
                                        {!isExporting && (
                                            <Button
                                                variant="filled"
                                                onClick={
                                                    !isRunningOnSF
                                                        ? onlyInSnowflake
                                                        : handleDataExport
                                                }
                                                disabled={
                                                    !pipe(
                                                        datasets,
                                                        A.some(
                                                            (item) =>
                                                                item.isSelected
                                                        )
                                                    )
                                                }
                                            >
                                                Export
                                            </Button>
                                        )}
                                        {publish && (
                                            <Button
                                                color="surface"
                                                onClick={publish}
                                                fullWidth
                                                density="dense"
                                            >
                                                Publish
                                            </Button>
                                        )}
                                    </Stack>
                                }
                            >
                                <IconButton
                                    variant={"outlined"}
                                    density={"dense"}
                                >
                                    <MenuOutline size="xs" />
                                </IconButton>
                            </Menu>
                        </Grid>
                    }
                />
            )}
        </Grid>
    );
};
