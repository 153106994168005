import {
    AreaExtended,
    PolygonProperties,
    SavedArea,
} from "@biggeo/bg-server-lib/datascape-ai";
import { SelectableTreeMenuItem } from "@biggeo/bg-ui/lab";
import { MapLayoutTabs } from "@biggeo/bg-ui/lab/layouts";
import { WithPartialValues, toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as S from "fp-ts/string";
import compact from "lodash/compact";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import { match } from "ts-pattern";
import { snapToView } from "../../../utils/utils";
import { InputPolygonWithId } from "../../hooks/pure-data-string-hook";
import {
    FunctionType,
    SavedPolygonSource,
    getConflictAreas,
    getPolygonsFromSavedAreas,
    handleSelectedAreas,
} from "../../utils/utils";
import { MapContextDataset, MapContextType } from "../context";
import { SavedPolygonType } from "../hooks/saved-polygon-hooks";
import { getPointFeatures } from "./data-hooks-utils";
import { setPointPulsingDot } from "./data-layers-utils";

export const resizeMap = (input: Pick<MapContextType, "map" | "isLoaded">) => {
    window.setTimeout(() => {
        if (input.map.current) {
            input.map.current.on("load", () => {
                input.map.current?.resize();
            });
        }
    }, 400);
};

export const removeMap = (
    input: {
        isSavedViewPage: boolean;
        geocode?: React.MutableRefObject<MapboxGeocoder | null>;
    } & Pick<MapContextType, "map" | "draw" | "isLoaded" | "dispatch">
) => {
    const { isSavedViewPage, geocode, map, draw, isLoaded, dispatch } = input;

    const isSavedViewStyleLoaded = isSavedViewPage ? isLoaded : true;

    if (map.current && isSavedViewStyleLoaded) {
        if (draw.current) {
            // Cleanup the context when you leave the map page or switch tabs.
            const hasDrawControl = map.current.hasControl(draw.current);

            if (hasDrawControl) {
                map.current.removeControl(draw.current);
            }

            dispatch?.({
                type: "SET_MAP",
                values: {
                    draw: { current: null },
                },
            });
        }

        if (geocode?.current) {
            const hasGeocodeControl = map.current.hasControl(geocode.current);

            if (hasGeocodeControl) {
                map.current.removeControl(geocode.current);
            }
        }

        const geocoderElement = document.getElementById("geocoder");

        if (geocoderElement) {
            geocoderElement.remove();
        }

        map.current.remove();

        dispatch?.({
            type: "SET_MAP",
            values: {
                map: { current: null },
                isLoaded: false,
                mapStates: [],
            },
        });
    }
};

export const handleSelectedSavedPolygons = (i: {
    savedAreas: SavedArea[];
    selectableItems: SelectableTreeMenuItem[];
    isConflict?: boolean;
    setFilterItems: React.Dispatch<
        React.SetStateAction<SelectableTreeMenuItem[]>
    >;
    setSavedPolygons: (i: Partial<SavedPolygonType>) => void;
    drawnPolygons: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >[];
    map: React.MutableRefObject<mapboxgl.Map | null>;
}) => {
    const {
        savedAreas,
        selectableItems,
        isConflict,
        setSavedPolygons,
        setFilterItems,
        drawnPolygons,
        map,
    } = i;

    setSavedPolygons({
        source: SavedPolygonSource.savedArea,
        polygons: savedAreas,
        isConflict: isUndefined(isConflict)
            ? !isEmpty(savedAreas) && !isEmpty(drawnPolygons)
            : isConflict,
    });

    snapToView({
        polygons: getPolygonsFromSavedAreas(savedAreas),
        map,
    });

    setFilterItems(selectableItems);
};

export const selectSavedArea = (i: {
    items: SelectableTreeMenuItem[];
    skipConflicts?: boolean;
    setFilterItems: React.Dispatch<
        React.SetStateAction<SelectableTreeMenuItem[]>
    >;
    setFunctionType: (f: FunctionType) => void;
    setSavedPolygons: (i: Partial<SavedPolygonType>) => void;
    data: AreaExtended[];
    drawnPolygons: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >[];
    polygons: InputPolygonWithId[];
    deleteShape: (id: string) => void;
}) => {
    const {
        items,
        skipConflicts = false,
        setFilterItems,
        setFunctionType,
        setSavedPolygons,
        data,
        drawnPolygons,
        polygons,
        deleteShape,
    } = i;

    setFilterItems(items);
    setFunctionType(FunctionType.savedPolygon);

    const selectedIds = pipe(
        items,
        toNonReadonlyArray,
        A.flatMap((item) =>
            pipe(
                item.subItems,
                toNonReadonlyArray,
                A.filter((sub) => sub.selected),
                A.map((sub) => sub.id)
            )
        )
    );

    const savedAreaPolygons = pipe(
        data,
        toNonReadonlyArray,
        A.flatMap(({ savedAreas }) =>
            pipe(
                savedAreas,
                A.filter((sa) => includes(selectedIds, sa.id))
            )
        )
    );

    if (skipConflicts) {
        setSavedPolygons({
            source: SavedPolygonSource.savedArea,
            polygons: savedAreaPolygons,
            isConflict: false,
        });
    } else {
        const conflicts =
            !isEmpty(drawnPolygons) && !isEmpty(savedAreaPolygons)
                ? pipe(
                      drawnPolygons,
                      A.map((shape) =>
                          getConflictAreas({
                              shape,
                              savedAreas: savedAreaPolygons,
                          })
                      ),
                      A.map(({ intersection }) => intersection),
                      compact
                  )
                : [];

        setSavedPolygons({
            source: SavedPolygonSource.savedArea,
            polygons: savedAreaPolygons,
            isConflict: !isEmpty(conflicts),
        });
    }

    // Delete unselected area from polygons & refetch
    if (isEmpty(drawnPolygons)) {
        const selectedPolygonIds = pipe(
            items,
            toNonReadonlyArray,
            A.filter((item) => item.selected),
            A.concat(
                pipe(
                    items,
                    toNonReadonlyArray,
                    A.flatMap((item) =>
                        pipe(
                            item.subItems,
                            toNonReadonlyArray,
                            A.filter((sub) => sub.selected)
                        )
                    )
                )
            ),
            A.mapWithIndex((index, { id }) => `${id}-${index}`)
        );

        pipe(
            polygons,
            A.map((item) => {
                if (!selectedPolygonIds.includes(item.id)) {
                    deleteShape(item.id);
                }
            })
        );
    }
};

export const selectSavedAreas = (i: {
    savedAreas: Pick<PolygonProperties, "name" | "areaId" | "savedAreaId">[];
    filterItems: SelectableTreeMenuItem[];
    setFilterItems: React.Dispatch<
        React.SetStateAction<SelectableTreeMenuItem[]>
    >;
    setFunctionType: (f: FunctionType) => void;
    setSavedPolygons: (i: Partial<SavedPolygonType>) => void;
    data: AreaExtended[];
    drawnPolygons: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >[];
    polygons: InputPolygonWithId[];
    deleteShape: (id: string) => void;
}) => {
    const { savedAreas, filterItems, ...rest } = i;
    const savedAreaIds = pipe(
        savedAreas,
        A.map((s) => s.savedAreaId),
        compact
    );

    if (!isEmpty(savedAreaIds)) {
        const currentSelectedIds = pipe(
            filterItems,
            toNonReadonlyArray,
            A.flatMap((item) =>
                pipe(
                    item.subItems,
                    toNonReadonlyArray,
                    A.filter((sub) => sub.selected),
                    A.map((sub) => sub.id)
                )
            )
        );

        if (!isEqual(savedAreaIds, currentSelectedIds)) {
            setTimeout(() => {
                selectSavedArea({
                    skipConflicts: true,
                    items: handleSelectedAreas({
                        ids: savedAreaIds,
                        type: "subItem",
                        items: filterItems,
                    }),
                    ...rest,
                });
            }, 250);
        }
    }
};

export const getMapTab = (mapTab: string | null): MapLayoutTabs => {
    return match<string | null, MapLayoutTabs>(mapTab)
        .with("dataset", () => "datasets")
        .with("shapeLayers", () => "shapeLayers")
        .with("boundaries", () => "boundaries")
        .with("maps", () => "maps")
        .with("configuration", () => "configuration")
        .otherwise(() => "datasets");
};

export const handleDatasetTable = (i: {
    state: boolean;
    datasets: MapContextDataset[];
    updateDataset: (values: {
        dataSourceId: string;
        dataset: Partial<WithPartialValues<MapContextDataset>>;
    }) => void;
}) => {
    const { state, datasets, updateDataset } = i;

    const selectedDatasets = pipe(
        datasets,
        A.filter((d) => isEqual(d.isSelected, true))
    );

    pipe(
        selectedDatasets,
        A.map((d) =>
            updateDataset({
                dataSourceId: d.dataSource.id,
                dataset: {
                    isTableViewed: state,
                },
            })
        )
    );
};

export const setSelectedRowPulsingDot = (i: {
    action: "add" | "remove" | "update";
    datasets: MapContextDataset[];
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
}) => {
    const { action, datasets, map, isLoaded } = i;

    const rows = pipe(
        datasets,
        A.filter((d) => isEqual(d.isSelected, true)),
        A.flatMap((d) => d.selectedRows)
    );

    pipe(
        datasets,
        A.filter((d) => isEqual(d.isSelected, true)),
        A.map((dataset) => {
            if (map.current && isLoaded) {
                const geographyColumn = pipe(
                    dataset.dataSource.geographyColumn,
                    O.fromNullable,
                    O.fold(
                        () => [],
                        (column) => pipe(column, S.split(","), compact)
                    )
                );

                setPointPulsingDot({
                    datasetId: dataset.dataSource.id,
                    action: isEmpty(rows) ? "remove" : action,
                    map,
                    isLoaded,
                    color:
                        dataset.mapTemplateDataset?.styles?.fill?.color ||
                        dataset.dataSource.color ||
                        undefined,
                    features: isEmpty(rows)
                        ? []
                        : getPointFeatures({
                              datasetId: dataset.dataSource.id,
                              tableId: dataset.dataSource.tableId || undefined,
                              geographyColumn,
                              rows: pipe(
                                  dataset.selectedRows,
                                  A.map(({ row }) => row)
                              ),
                          }),
                });
            }
        })
    );
};
