import { ApolloError } from "@apollo/client";
import {
    InputMapTemplateDataset,
    useFetchMapTemplateDatasetsExtendedLazyQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { useEffect, useState } from "react";
import { isAppRunningOnSF } from "../../../common/redux/hooks";
import { DEFAULT_LOGIC_OPERATOR } from "../../filter-criteria/utils/utils";
import { getDefaultDatasetConfiguration } from "./context-utils";
import { MapContextDataset, useMap } from "./map";

export const useMapTemplateDatasets = (
    input: InputMapTemplateDataset,
    options?: Partial<{ skip: boolean }>
): {
    datasets: MapContextDataset[];
    loading: boolean;
    error?: ApolloError;
} => {
    const { dispatch, datasets } = useMap();
    const isRunningOnSF = isAppRunningOnSF();

    const skip = options && !isUndefined(options.skip) ? options.skip : false;
    const [isLoading, setIsLoading] = useState<boolean>(
        !skip && isEmpty(datasets)
    );

    const {
        executeQuery: getMapTemplateDatasets,
        queryReturn: [_, { loading, error }],
    } = useFetchMapTemplateDatasetsExtendedLazyQuery();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!skip) {
            getMapTemplateDatasets({
                variables: { input },
                onCompleted: (data) => {
                    setIsLoading(false);
                    dispatch?.({
                        type: "ADD_DATASETS",
                        values: pipe(
                            data.fetchMapTemplateDatasetsExtended,
                            A.map((d) => ({
                                dataSource: d.dataSource,
                                mapTemplateDataset: d.mapTemplateDataset,
                                isSelected: false,
                                isVisible: false,
                                isGettingStyled: false,
                                isLegendOpen: false,
                                isTableViewed: false,
                                configuration:
                                    getDefaultDatasetConfiguration(
                                        isRunningOnSF
                                    ),
                                filters: {
                                    logicOperator: DEFAULT_LOGIC_OPERATOR,
                                    filters: [],
                                },
                                selectedRows: [],
                                pinnedRows: [],
                            }))
                        ),
                    });
                },
            });
        }
    }, [
        isRunningOnSF,
        skip,
        input.fkMapTemplateId,
        input.compute,
        input.mapUse,
        input.isPreview,
    ]);

    return { datasets, loading: loading || isLoading, error };
};
