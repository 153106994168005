import { ShapeStyle } from "@biggeo/bg-server-lib/datascape-ai";
import { ColorPicker, Stack, colorToHexString } from "@biggeo/bg-ui/lab";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import { AccordionWithDivider } from "../../common/components/AccordionWithDivider";
import { HeatMapClusterColorMapper } from "../../common/components/HeatMapClusterColorMapper";
import FilterCriteriaStylesShape from "../../map/filter-criteria/views/FilterCriteriaStylesShape";
import DatasetCustomMarker from "../../map/mapbox/views/DatasetCustomMarker";
import {
    generateHeatmapFromColor,
    getDatasetShapeColor,
    getHeatmapFormattedValue,
} from "../../map/utils/style-utils";
import { IPreviewDatasetMenu } from "../../map/views/PreviewDatasetMenu";
import { DEFAULT_SHAPE_COLOR } from "../mapbox/hooks/style-hooks";

type PreviewDatasetCardMapItemProps = Omit<IPreviewDatasetMenu, "onBack">;

export const PreviewDatasetCardMapItem = ({
    preview,
    updateHeatMapColor,
    extraOptions,
}: PreviewDatasetCardMapItemProps) => {
    const styles = preview.styles || undefined;
    const heatmap = pipe(
        getHeatmapFormattedValue(styles?.dataAggregation?.heatmap || undefined),
        O.fromNullable,
        O.fold(
            () =>
                generateHeatmapFromColor(
                    styles?.fill?.color || DEFAULT_SHAPE_COLOR
                ),
            (h) => h
        )
    );

    return (
        <Stack gap={2} width="100%">
            <AccordionWithDivider expanded label="Data Aggregation">
                <HeatMapClusterColorMapper
                    heatMapValue={heatmap}
                    onClickHeatMapValue={(value) =>
                        updateHeatMapColor(value, preview)
                    }
                    extraOptions={extraOptions}
                />
            </AccordionWithDivider>
        </Stack>
    );
};
