import {
    DataSourceExport,
    usePrepareDataSourcesForExportQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Divider,
    Grid,
    LoadingBar,
    Severity,
    Stack,
    StickyFabPlacementHelper,
    SubmittingButton,
    TextField,
    Typography,
} from "@biggeo/bg-ui/lab";
import { match as vtMatch } from "@vividtheory/remotedata";
import { Formik } from "formik";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { isAppRunningOnSF } from "../../common/redux/hooks";
import { useMap } from "../../map/mapbox/context";
import {
    getInputPolygon,
    getPolygonsFromSavedAreas,
} from "../../map/utils/utils";
import { toasterActions } from "../../toaster/containers/redux/model";
import { getFiltersForExport } from "../../utils/utils";
import { DatasetCardList } from "../components/DatasetCardList";
import { ExportToSFHeader } from "../views/ExportToSFHeader";

export const ExportToSFContainer = ({
    onSubmit,
    loading,
    setDatasetsForExport,
}: {
    onSubmit: (data: string[], tableName: string) => void;
    loading: boolean;
    setDatasetsForExport: (values: string[]) => void;
}) => {
    const dispatch = useDispatch();
    const isRunningOnSF = isAppRunningOnSF();
    const { datasets, filters, viewport, mapState } = useMap();

    const selectedSavedAreas = mapState?.selectedSavedAreas;
    const areas = selectedSavedAreas
        ? getPolygonsFromSavedAreas(selectedSavedAreas.savedAreas)
        : [];
    const polygons = mapState?.featureCollection?.features || [];

    const initialValues: { datasets: string[]; tableName?: string } = {
        datasets: [],
        tableName: undefined,
    };
    const [localDatalist, setLocalDatalist] = useState<DataSourceExport[]>([]);
    const [preparingExport, setPreparingExport] = useState<boolean>(false);
    const { remote: exportRd } = usePrepareDataSourcesForExportQuery({
        variables: {
            input: {
                viewport,
                multipolygon: [
                    ...areas.map((polygon) => ({
                        outer: polygon.outer,
                        inners: polygon.inners,
                        properties: polygon.properties,
                    })),
                    ...polygons.map((c) => {
                        const inputPolygon = getInputPolygon(c);
                        return {
                            outer: inputPolygon.outer,
                            inners: inputPolygon.inners,
                            properties: inputPolygon.properties,
                        };
                    }),
                ],
                filters: getFiltersForExport({ datasets, filters }),
            },
        },
        onCompleted: ({ prepareDataSourcesForExport }) => {
            setPreparingExport(false);
            pipe(
                prepareDataSourcesForExport,
                O.fromPredicate((x) => !isEmpty(x)),
                O.foldW(
                    () => {},
                    (data) => {
                        setLocalDatalist(data);
                    }
                )
            );
        },
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        vtMatch(exportRd, {
            _: () => {
                setPreparingExport(true);
            },
            Success: ({ prepareDataSourcesForExport }) => {
                pipe(
                    prepareDataSourcesForExport,
                    O.fromPredicate((x) => !isEmpty(x)),
                    O.foldW(
                        () => {},
                        (data) => {
                            setLocalDatalist(data);
                        }
                    )
                );
            },
            Failure: (e) => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        severity: Severity.error,
                        title: e.message || "Error preparing for export",
                        autoHideDuration: 5000,
                    })
                );
            },
        });
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={toFormikValidationSchema(
                z.object({
                    datasets: z.string().array(),
                    tableName: z.string(),
                })
            )}
            onSubmit={(values) => {
                if (values.tableName)
                    onSubmit(values.datasets, values.tableName);
            }}
            validateOnMount
            enableReinitialize
        >
            {({ values, dirty, isValid, handleSubmit, setFieldValue }) => {
                return (
                    <Stack gap={4}>
                        <ExportToSFHeader isRunningOnSF={isRunningOnSF} />
                        {preparingExport ? (
                            <LoadingBar />
                        ) : (
                            <Stack gap={4}>
                                <Stack gap={4}>
                                    <Grid container gap={1}>
                                        <Grid item>
                                            <Typography
                                                variant="title2"
                                                fontWeight="bold"
                                            >
                                                1.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs minWidth={0}>
                                            <Stack width="100%">
                                                <Typography
                                                    variant="title2"
                                                    fontWeight="bold"
                                                    truncate
                                                >
                                                    Enter Table Name
                                                </Typography>
                                                <Typography
                                                    variant="body3"
                                                    textColor="disabled.onContainer"
                                                    truncate
                                                >
                                                    Specify the name of the
                                                    table where the exported
                                                    data will be stored
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        label="Exported Table Name"
                                        fullWidth
                                        required
                                        onChange={(values) =>
                                            setFieldValue(
                                                "tableName",
                                                values?.target.value ||
                                                    undefined
                                            )
                                        }
                                        placeholder="Enter name"
                                    />
                                    <Divider />
                                </Stack>
                                <Stack gap={4}>
                                    <Grid container gap={1}>
                                        <Grid item>
                                            <Typography
                                                variant="title2"
                                                fontWeight="bold"
                                            >
                                                2.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs minWidth={0}>
                                            <Stack width="100%">
                                                <Typography
                                                    variant="title2"
                                                    fontWeight="bold"
                                                    truncate
                                                >
                                                    Select Dataset to Export
                                                </Typography>
                                                <Typography
                                                    variant="body3"
                                                    textColor="disabled.onContainer"
                                                    truncate
                                                >
                                                    Select the dataset you want
                                                    to export
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <DatasetCardList
                                        tableName={values.tableName}
                                        dataList={localDatalist}
                                        onSelect={(i) => {
                                            if (!values.datasets.includes(i)) {
                                                setDatasetsForExport([i]);
                                                return setFieldValue(
                                                    "datasets",
                                                    [i]
                                                );
                                            }
                                            setDatasetsForExport([]);
                                            return setFieldValue(
                                                "datasets",
                                                []
                                            );
                                        }}
                                        selectedIds={values.datasets}
                                    />
                                </Stack>
                            </Stack>
                        )}
                        <StickyFabPlacementHelper
                            placement="right"
                            sx={{
                                paddingY: 4,
                                backgroundColor: (theme) =>
                                    theme.palette.background.container,
                            }}
                        >
                            <Stack
                                flexDirection="row"
                                justifyContent="flex-end"
                            >
                                <SubmittingButton
                                    type="exporting"
                                    loading={loading}
                                    disabled={
                                        !isValid || !dirty || isEmpty(values)
                                    }
                                    onClick={() => handleSubmit()}
                                >
                                    Export Dataset
                                </SubmittingButton>
                            </Stack>
                        </StickyFabPlacementHelper>
                    </Stack>
                );
            }}
        </Formik>
    );
};
