import {
    DataSource,
    DatabaseType,
    DatasetGeometryPoint,
    DatasetStyles,
    FilterCriteriaDatasetItem,
    InputViewBox,
    MapStyleType,
    MapTemplateDataset,
    ReqOptions,
    SavedArea,
} from "@biggeo/bg-server-lib/datascape-ai";
import { LogicOperator, SelectableTreeMenuItem } from "@biggeo/bg-ui/lab";
import {
    WithPartialValues,
    WithRequiredProperty,
    isObjectNotEmpty,
    toNonReadonlyArray,
} from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";
import last from "lodash/last";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import uniqWith from "lodash/uniqWith";
import { createContext, useContext, useEffect } from "react";
import uuid from "react-uuid";
import { MapFilterCriteriaForm } from "../../filter-criteria/utils/utils";
import {
    DEFAULT_VIEWPORT,
    InputPolygonWithId,
} from "../../hooks/pure-data-string-hook";
import { DrawEventType } from "../../utils/draw-modes-types";
import { isFeature, isFeatureCollection } from "../../utils/draw-modes-utils";
import { setCustomShapeStyling } from "../../utils/style-utils";
import {
    FunctionType,
    SavedPolygonSource,
    getInputPolygon,
    getMapFeatures,
    getPolygonsFromSavedAreas,
    isPolygonFeature,
    updateArray,
} from "../../utils/utils";
import { DEFAULT_MAP_STYLE } from "../../views/MapStyles";
import { SavedPolygonType } from "../hooks/saved-polygon-hooks";

export const MAP_STATES_LIMIT = 10;
export type MapType = React.MutableRefObject<mapboxgl.Map | null>;
export type DrawType = React.MutableRefObject<MapboxDraw | null>;
export type MapState = {
    id?: string;
    map: MapType;
    draw: DrawType;
    featureCollection?: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >;
    datasets: MapContextDataset[];
    modes: Record<MapModes, boolean>;
    functionType?: FunctionType;
    viewport?: Partial<{
        bounds: mapboxgl.LngLatBounds;
        center: mapboxgl.LngLat;
        view: InputViewBox;
        zoom: number;
    }>;
    selectedSavedAreas: {
        savedAreas: SavedArea[];
        selectableItems: SelectableTreeMenuItem[];
        isConflict: boolean;
    };
    styleUrl?: string;
    filters: MapContextFilter[];
};

export type MapContextFilter = MapFilterCriteriaForm & {
    id: string;
    visible: boolean;
    selected: boolean;
    disabled: boolean;
    viewed?: boolean;
    preview: boolean;
};

export type MapContextDatasetConfiguration = {
    isOpen: boolean;
    options: ReqOptions;
    showLevelSets: boolean;
    showPoints: boolean;
};

export type MapContextDatasetFilterItem = FilterCriteriaDatasetItem & {
    id: string;
    filterId?: string;
};

export type MapContextDatasetFilter = {
    logicOperator: LogicOperator;
    filters: MapContextDatasetFilterItem[];
};

export type MapContextDataset = {
    dataSource: DataSource;
    mapTemplateDataset?: MapTemplateDataset;
    isSelected: boolean;
    isVisible: boolean;
    isGettingStyled: boolean;
    isTableViewed: boolean;
    tableOrder?: number;
    isLegendOpen: boolean;
    configuration: MapContextDatasetConfiguration;
    filters: MapContextDatasetFilter;
    selectedRows: DatasetGeometryPoint[];
    pinnedRows: DatasetGeometryPoint[];
};

export type MapContextPreviewDataset = {
    id: string;
    dataSourceId: string;
    marketplaceDatasetId: string;
    tableName: string;
    column?: string;
    geometry: string[];
    type: DatabaseType;
    isVisible: boolean;
    isGettingStyled: boolean;
    styles?: Pick<DatasetStyles, "fill" | "dataAggregation">;
};

export type MapContextType = {
    readonly map: MapType;
    readonly draw: DrawType;
    readonly isLoaded: boolean;
    readonly viewport: InputViewBox;
    readonly polygons: InputPolygonWithId[];
    readonly functionType: FunctionType;
    readonly savedPolygons: SavedPolygonType;
    readonly selectedShapes: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >;
    readonly modes?: {
        [MapModes.select]: {
            isSelectMode: boolean;
            selectMode: (s: boolean) => void;
        };
    };
    readonly dispatch?: React.Dispatch<MapAction>;
    readonly mapState?: MapState;
    readonly mapStates: MapState[];
    readonly filters: MapContextFilter[];
    readonly mapStyle?: MapStyleType;
    readonly datasets: MapContextDataset[];
    readonly isDataLoading?: boolean;
    readonly isRunningOnSF: boolean;
    readonly previews: MapContextPreviewDataset[];
};

export enum MapModes {
    draw = "draw",
    select = "select",
}

export const mapInitialState: MapState = {
    id: undefined,
    map: { current: null },
    draw: { current: null },
    featureCollection: {
        type: "FeatureCollection",
        features: [],
    },
    datasets: [],
    modes: {
        draw: false,
        select: false,
    },
    functionType: FunctionType.viewport,
    selectedSavedAreas: {
        savedAreas: [],
        selectableItems: [],
        isConflict: false,
    },
    viewport: undefined,
    styleUrl: DEFAULT_MAP_STYLE.url,
    filters: [],
};

export const MapContext = createContext<MapContextType>({
    map: { current: null },
    draw: { current: null },
    isLoaded: false,
    selectedShapes: {
        type: "FeatureCollection",
        features: [],
    },
    dispatch: undefined,
    mapState: mapInitialState,
    mapStates: [],
    modes: undefined,
    filters: [],
    mapStyle: undefined,
    datasets: [],
    isRunningOnSF: false,
    previews: [],
    viewport: DEFAULT_VIEWPORT,
    polygons: [],
    functionType: FunctionType.viewport,
    savedPolygons: {
        source: SavedPolygonSource.savedArea,
        polygons: [],
        isConflict: false,
    },
});

export const useMap = (): MapContextType => useContext(MapContext);

export type MapAction =
    | {
          readonly type: "SET_MAP";
          readonly values: Partial<MapContextType>;
      }
    | {
          readonly type: "SET_POLYGONS";
          readonly values: { p: InputPolygonWithId | InputPolygonWithId[] };
      }
    | {
          readonly type: "DELETE_POLYGON";
          readonly values: { id: string };
      }
    | {
          readonly type: "SET_PREVIOUS_STATE";
          readonly values: {
              feature?: GeoJSON.Feature<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
              >;
              featureCollection?: GeoJSON.FeatureCollection<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
              >;
              state?: Partial<MapState>;
              type?:
                  | "draw.create"
                  | "draw.update"
                  | "draw.delete"
                  | "draw.render";
          };
      }
    | {
          readonly type: "SET_MAP_STATES";
          readonly values: {
              feature?: GeoJSON.Feature<
                  GeoJSON.Geometry,
                  GeoJSON.GeoJsonProperties
              >;
          };
      }
    | {
          readonly type: "SET_SELECTED_SHAPES";
          readonly values:
              | GeoJSON.FeatureCollection<
                    GeoJSON.Geometry,
                    GeoJSON.GeoJsonProperties
                >
              | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
      }
    | {
          readonly type: "UPDATE_SELECTED_SHAPE";
          readonly values: GeoJSON.Feature<
              GeoJSON.Geometry,
              GeoJSON.GeoJsonProperties
          >;
      }
    | {
          type: "ADD_FILTER";
          values: MapContextFilter;
      }
    | {
          type: "UPDATE_FILTER";
          values: WithRequiredProperty<Partial<MapContextFilter>, "id">;
      }
    | {
          type: "REMOVE_FILTER";
          values: string;
      }
    | { type: "CLEAR_FILTERS" }
    | {
          type: "EDIT_FILTER";
          values: string;
      }
    | {
          type: "SAVE_FILTER";
          values: string;
      }
    | {
          type: "RESET_FILTERS";
          values: {
              disable?: boolean;
          };
      }
    | {
          type: "SET_FILTERS";
          values: MapContextFilter[];
      }
    | {
          type: "SET_MAP_STYLE";
          values: MapStyleType | undefined;
      }
    | {
          type: "ADD_DATASETS";
          values: MapContextDataset | MapContextDataset[];
      }
    | {
          type: "UPDATE_DATASET";
          values: {
              dataSourceId: string;
              dataset: Partial<WithPartialValues<MapContextDataset>>;
          };
      }
    | {
          type: "REMOVE_DATASET";
          values: {
              dataSourceId: string;
              mapTemplateDatasetId?: number;
          };
      }
    | {
          type: "OVERRIDE_DATASETS";
          values: MapContextDataset[];
      }
    | {
          type: "REORDER_DATASETS";
          values: {
              dataSourceIds: string[];
          };
      }
    | {
          type: "SET_DATA_LOADING";
          values: {
              state: boolean;
          };
      }
    | {
          type: "SET_PREVIEW_DATASETS";
          values: MapContextPreviewDataset;
      }
    | {
          type: "REMOVE_PREVIEW_DATASET";
          values: { id?: string; dataSourceId: string };
      }
    | {
          type: "UPDATE_PREVIEW_DATASET";
          values: {
              id?: string;
              dataSourceId: string;
              dataset: Partial<WithPartialValues<MapContextPreviewDataset>>;
          };
      };

export const MapReducer = (
    state: MapContextType,
    action: MapAction
): MapContextType => {
    switch (action.type) {
        case "SET_MAP": {
            return {
                ...state,
                ...omitBy(action.values, isNil),
            };
        }
        case "SET_POLYGONS": {
            return {
                ...state,
                polygons: updateArray(action.values.p, state.polygons),
            };
        }
        case "DELETE_POLYGON": {
            return {
                ...state,
                polygons: state.polygons.filter(
                    (p) => p.id !== action.values.id
                ),
            };
        }
        case "SET_PREVIOUS_STATE": {
            const feature = action.values.feature;
            const featureCollection = action.values.featureCollection;
            const draw = action.values.state?.draw || state.draw;

            if (!action.values.type && !feature && featureCollection) {
                return {
                    ...state,
                    mapState: state.mapState
                        ? {
                              ...state.mapState,
                              featureCollection,
                          }
                        : state.mapState,
                };
            }

            if (action.values.type === "draw.delete" && feature) {
                return {
                    ...state,
                    mapState: {
                        ...(state.mapState as MapState),
                        ...action.values.state,
                        featureCollection: draw
                            ? {
                                  type: "FeatureCollection",
                                  features: pipe(
                                      getMapFeatures(draw, state.isLoaded),
                                      A.filter((f) => f.id !== feature.id)
                                  ),
                              }
                            : state.mapState?.featureCollection,
                    },
                };
            }

            return {
                ...state,
                mapState: {
                    ...(state.mapState as MapState),
                    ...action.values.state,
                    featureCollection: draw
                        ? {
                              type: "FeatureCollection",
                              features: feature
                                  ? updateArray(
                                        feature,
                                        getMapFeatures(draw, state.isLoaded)
                                    )
                                  : getMapFeatures(draw, state.isLoaded),
                          }
                        : state.mapState?.featureCollection,
                },
            };
        }
        case "SET_MAP_STATES": {
            if (isEqual(state.mapStates.length, MAP_STATES_LIMIT)) {
                return state;
            }

            const currentState = state.mapState;
            const lastState = last(state.mapStates);

            /*
                This check is important because after drawing shapes,
                the draw mode and functionType go back momentarily to `false`
                and `viewport`. It's needed for the draw to work (to draw shapes 
                consecutively), but we don't want to store that state.
            */
            const isDifferent =
                action.values.feature &&
                !isEqual(
                    omit(currentState, ["id", "functionType", "modes"]),
                    omit(lastState, ["id", "functionType", "modes"])
                );

            const updatedState = pipe(
                currentState,
                O.fromNullable,
                O.fold(
                    () => state.mapStates,
                    (current) =>
                        pipe(
                            updateArray(current, state.mapStates),
                            A.map((i) =>
                                i.id === current.id && isDifferent
                                    ? { ...current, id: uuid() }
                                    : i
                            )
                        )
                )
            );

            return {
                ...state,
                mapStates: updatedState,
            };
        }
        case "SET_SELECTED_SHAPES": {
            const feature = action.values;
            const found = isFeature(feature)
                ? state.selectedShapes.features.find((f) => f.id === feature.id)
                : undefined;

            return {
                ...state,
                selectedShapes: isFeatureCollection(action.values)
                    ? action.values
                    : {
                          type: "FeatureCollection",
                          features: found
                              ? pipe(
                                    state.selectedShapes.features,
                                    A.filter((f) => f.id !== found.id)
                                )
                              : isFeature(feature)
                                ? pipe(
                                      state.selectedShapes.features,
                                      A.concat([feature])
                                  )
                                : state.selectedShapes.features,
                      },
            };
        }
        case "UPDATE_SELECTED_SHAPE": {
            const feature = action.values;

            const found = state.selectedShapes.features.find(
                (f) => f.id === feature.id
            );

            if (found) {
                return {
                    ...state,
                    selectedShapes: {
                        ...state.selectedShapes,
                        features: pipe(
                            state.selectedShapes.features,
                            A.map((f) =>
                                f.id === found.id ? { ...f, ...feature } : f
                            )
                        ),
                    },
                };
            }

            return state;
        }
        case "ADD_FILTER": {
            const hasPreviewProp = !isUndefined(action.values.preview);

            const filters = hasPreviewProp
                ? pipe(
                      state.filters,
                      A.filter((f) => {
                          const isNotPreview =
                              isEqual(f.preview, false) &&
                              !isEmpty(f.filterCriteria);
                          const isEdit =
                              isEqual(f.preview, true) &&
                              isEqual(f.selected, true);

                          return isNotPreview || isEdit;
                      })
                  )
                : state.filters;

            return {
                ...state,
                filters: pipe(
                    filters,
                    A.map((f) => ({ ...f, preview: false })),
                    A.concat([action.values]),
                    A.map((f) => ({ ...f, disabled: false, viewed: false }))
                ),
            };
        }
        case "UPDATE_FILTER": {
            const hasViewedProp = !isUndefined(action.values.viewed);
            const hasEditProp = !isUndefined(action.values.selected);

            return {
                ...state,
                filters: pipe(
                    hasViewedProp || hasEditProp
                        ? pipe(
                              state.filters,
                              A.filter((f) => {
                                  const isNotPreview =
                                      isEqual(f.preview, false) &&
                                      !isEmpty(f.filterCriteria);
                                  const isEdit =
                                      isEqual(f.preview, true) &&
                                      isEqual(f.selected, true);

                                  return isNotPreview || isEdit;
                              })
                          )
                        : state.filters,
                    A.map((f) =>
                        f.id === action.values.id
                            ? {
                                  ...f,
                                  ...action.values,
                                  ...(hasViewedProp && {
                                      selected: false,
                                      preview: false,
                                  }),
                              }
                            : {
                                  ...f,
                                  disabled: false,
                                  ...(hasViewedProp && {
                                      viewed: false,
                                      selected: false,
                                      preview: false,
                                  }),
                              }
                    )
                ),
                datasets: hasViewedProp
                    ? pipe(
                          state.datasets,
                          A.map((d) => ({
                              ...d,
                              isTableViewed: false,
                          }))
                      )
                    : state.datasets,
            };
        }
        case "REMOVE_FILTER": {
            const id = action.values;

            return {
                ...state,
                filters: pipe(
                    state.filters,
                    A.filter((f) => f.id !== id)
                ),
            };
        }
        case "CLEAR_FILTERS": {
            return {
                ...state,
                filters: [],
            };
        }
        case "EDIT_FILTER": {
            const id = action.values;

            return {
                ...state,
                filters: pipe(
                    state.filters,
                    A.filter((f) => {
                        const isNotPreview =
                            isEqual(f.preview, false) &&
                            !isEmpty(f.filterCriteria);
                        const isEdit =
                            isEqual(f.preview, true) &&
                            isEqual(f.selected, true);

                        return isNotPreview || isEdit;
                    }),
                    A.map((f) =>
                        f.id === id
                            ? {
                                  ...f,
                                  selected: true,
                                  visible: true,
                                  preview: true,
                              }
                            : {
                                  ...f,
                                  visible: false,
                                  disabled: true,
                                  selected: false,
                                  preview: false,
                              }
                    )
                ),
            };
        }
        case "SAVE_FILTER": {
            const id = action.values;

            return {
                ...state,
                filters: pipe(
                    state.filters,
                    A.map((f) =>
                        f.id === id
                            ? { ...f, selected: false }
                            : {
                                  ...f,
                                  visible: false,
                                  disabled: false,
                                  selected: false,
                              }
                    )
                ),
            };
        }
        case "RESET_FILTERS": {
            const disabled = isNil(action.values.disable)
                ? false
                : action.values.disable;

            return {
                ...state,
                filters: pipe(
                    state.filters,
                    A.map((f) => ({
                        ...f,
                        disabled,
                        selected: false,
                        visible: false,
                        preview: false,
                    }))
                ),
            };
        }
        case "SET_FILTERS": {
            return {
                ...state,
                filters: action.values,
            };
        }
        case "SET_MAP_STYLE": {
            return {
                ...state,
                mapStyle: action.values,
            };
        }
        case "ADD_DATASETS": {
            const payload = action.values;

            if (isArray(payload)) {
                return {
                    ...state,
                    datasets: pipe(
                        uniqWith(
                            pipe(state.datasets, A.concat(payload)),
                            (x, y) => isEqual(x.dataSource.id, y.dataSource.id)
                        ),
                        A.map((p) => {
                            const found = state.datasets.find(
                                (f) => f.dataSource.id === p.dataSource.id
                            );

                            if (found) {
                                const {
                                    dataSource,
                                    mapTemplateDataset,
                                    ...params
                                } = found;

                                return {
                                    ...p,
                                    dataSource: {
                                        ...p.dataSource,
                                        ...found.dataSource,
                                    },
                                    mapTemplateDataset: found.mapTemplateDataset
                                        ? {
                                              ...p.mapTemplateDataset,
                                              ...found.mapTemplateDataset,
                                          }
                                        : p.mapTemplateDataset,
                                    ...omitBy(params, isNil),
                                };
                            }

                            return p;
                        })
                    ),
                };
            }

            return {
                ...state,
                datasets: uniqWith(
                    pipe(state.datasets, A.concat([payload])),
                    (x, y) => isEqual(x.dataSource.id, y.dataSource.id)
                ),
            };
        }
        case "UPDATE_DATASET": {
            const { dataSourceId, dataset } = action.values;
            const {
                dataSource,
                mapTemplateDataset,
                configuration,
                filters,
                ...params
            } = dataset;

            const hasSelectedProp =
                !isUndefined(params.isSelected) &&
                isEqual(params.isSelected, true);
            const hasTableViewedProp = !isUndefined(params.isTableViewed);
            const hasConfigurationProp = !isUndefined(configuration);

            const viewedDatasets = pipe(
                state.datasets,
                A.filter((d) => isEqual(d.isTableViewed, true))
            );

            const isTableOpen = !isEmpty(viewedDatasets);

            return {
                ...state,
                datasets: pipe(
                    state.datasets,
                    A.map((d) =>
                        d.dataSource.id === dataSourceId
                            ? {
                                  ...d,
                                  dataSource: dataSource
                                      ? {
                                            ...d.dataSource,
                                            ...dataSource,
                                        }
                                      : d.dataSource,
                                  mapTemplateDataset:
                                      mapTemplateDataset &&
                                      isObjectNotEmpty(mapTemplateDataset)
                                          ? {
                                                ...d.mapTemplateDataset,
                                                ...mapTemplateDataset,
                                                styles: {
                                                    ...d.mapTemplateDataset
                                                        ?.styles,
                                                    ...mapTemplateDataset.styles,
                                                },
                                            }
                                          : d.mapTemplateDataset,
                                  ...(hasConfigurationProp && {
                                      configuration: {
                                          ...d.configuration,
                                          ...omitBy(configuration, isNil),
                                          options: {
                                              ...d.configuration.options,
                                              ...omitBy(
                                                  configuration.options,
                                                  isNil
                                              ),
                                          },
                                      },
                                  }),
                                  filters:
                                      filters && isObjectNotEmpty(filters)
                                          ? {
                                                ...d.filters,
                                                ...omitBy(filters, isNil),
                                            }
                                          : d.filters,
                                  ...omitBy(params, isNil),
                                  ...(hasTableViewedProp && {
                                      tableOrder: viewedDatasets.length,
                                  }),
                                  ...(isTableOpen &&
                                      hasSelectedProp && {
                                          isTableViewed: true,
                                          tableOrder: viewedDatasets.length,
                                      }),
                              }
                            : d
                    )
                ),
                filters: hasTableViewedProp
                    ? pipe(
                          state.filters,
                          A.map((f) => ({ ...f, viewed: false }))
                      )
                    : state.filters,
            };
        }
        case "REMOVE_DATASET": {
            const { dataSourceId } = action.values;

            return {
                ...state,
                datasets: pipe(
                    state.datasets,
                    A.filter(
                        (dataset) => dataset.dataSource.id !== dataSourceId
                    )
                ),
            };
        }
        case "OVERRIDE_DATASETS": {
            return {
                ...state,
                datasets: action.values,
            };
        }
        case "REORDER_DATASETS": {
            return {
                ...state,
                datasets: state.datasets.sort(
                    (a, b) =>
                        action.values.dataSourceIds.indexOf(a.dataSource.id) -
                        action.values.dataSourceIds.indexOf(b.dataSource.id)
                ),
            };
        }
        case "SET_DATA_LOADING": {
            return {
                ...state,
                isDataLoading: action.values.state,
            };
        }
        case "SET_PREVIEW_DATASETS": {
            const found = state.previews.find((p) =>
                isEqual(p.tableName, action.values.tableName)
            );

            return {
                ...state,
                previews: found
                    ? pipe(
                          state.previews,
                          A.map((p) =>
                              isEqual(found.id, p.id)
                                  ? {
                                        ...found,
                                        ...action.values,
                                        isVisible: action.values.isVisible,
                                    }
                                  : p
                          )
                      )
                    : pipe(state.previews || [], A.concat([action.values])),
            };
        }
        case "REMOVE_PREVIEW_DATASET": {
            return {
                ...state,
                previews: action.values.id
                    ? pipe(
                          state.previews || [],
                          A.filter(
                              (preview) =>
                                  !isEqual(preview.id, action.values.id)
                          )
                      )
                    : pipe(
                          state.previews || [],
                          A.filter(
                              (preview) =>
                                  !isEqual(
                                      preview.dataSourceId,
                                      action.values.dataSourceId
                                  )
                          )
                      ),
            };
        }
        case "UPDATE_PREVIEW_DATASET": {
            const { dataSourceId, dataset } = action.values;

            return {
                ...state,
                previews: pipe(
                    state.previews,
                    A.map((d) =>
                        d.dataSourceId === dataSourceId
                            ? {
                                  ...d,
                                  ...omitBy(dataset, isNil),
                              }
                            : d
                    )
                ),
            };
        }
        default:
            return state;
    }
};

export const useMapState = ({
    isLoaded,
    state,
    dispatch,
    callbacks,
}: {
    isLoaded: boolean;
    state: MapState;
    dispatch?: React.Dispatch<MapAction>;
    callbacks?: {
        deleteShape: (id: string) => void;
    };
}) => {
    const {
        map,
        draw,
        datasets,
        modes,
        filters,
        selectedSavedAreas,
        featureCollection,
        ...params
    } = state;

    const selected = pipe(
        selectedSavedAreas.selectableItems,
        toNonReadonlyArray,
        A.flatMap((item) =>
            pipe(
                item.subItems,
                toNonReadonlyArray,
                A.filter((sub) => sub.selected),
                A.map((sub) => sub.id)
            )
        )
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const handleMapReset = (e?: DrawEventType) => {
            if (isLoaded) {
                const isSavedAreaMode = !isEmpty(selectedSavedAreas.savedAreas);
                const feature =
                    e && !isSavedAreaMode ? e.features[0] : undefined;
                const id = feature?.id ? feature.id.toString() : undefined;

                if (e && e.type === "draw.delete" && callbacks && id) {
                    callbacks.deleteShape(id);
                }

                dispatch?.({
                    type: "SET_PREVIOUS_STATE",
                    values: {
                        type: e && !isSavedAreaMode ? e.type : undefined,
                        feature,
                        state: {
                            map,
                            draw,
                            datasets,
                            modes,
                            filters,
                            selectedSavedAreas,
                            ...omitBy(params, isNil),
                        },
                    },
                });

                dispatch?.({
                    type: "SET_MAP_STATES",
                    values: { feature },
                });
            }
        };

        if (map.current) {
            map.current.on("draw.create", handleMapReset);
            map.current.on("draw.update", handleMapReset);
            map.current.on("draw.delete", handleMapReset);

            handleMapReset();
        }

        return () => {
            if (map.current) {
                map.current.off("draw.create", handleMapReset);
                map.current.off("draw.update", handleMapReset);
                map.current.off("draw.delete", handleMapReset);
            }
        };
    }, [
        map.current,
        draw.current,
        isLoaded,
        datasets.length,
        params.functionType,
        params.styleUrl,
        params.viewport?.view,
        modes.draw,
        modes.select,
        selected.length,
        filters.length,
    ]);
};

export const onMapReset = ({
    map,
    draw,
    state,
    setState,
    polygons: OGPolygons,
}: {
    map: mapboxgl.Map;
    draw?: MapboxDraw;
    state: MapState;
    polygons: InputPolygonWithId[];
    setState: {
        setDatasets?: (d: MapContextDataset[]) => void;
        setPolygons: (polygons: InputPolygonWithId[]) => void;
        setFunctionType: (f: FunctionType) => void;
        setMode: (m: boolean) => void;
        setFilters: (f: MapContextFilter[]) => void;
        setViewport: (
            v: Partial<{
                bounds: mapboxgl.LngLatBounds;
                center: mapboxgl.LngLat;
                view: InputViewBox;
                zoom: number;
            }>
        ) => void;
        setStyle?: (url: string) => void;
        setSavedAreas: (a: {
            savedAreas: SavedArea[];
            selectableItems: SelectableTreeMenuItem[];
        }) => void;
    };
}) => {
    const {
        setDatasets,
        setPolygons,
        setFunctionType,
        setFilters,
        setViewport,
        setStyle,
        setSavedAreas,
        setMode,
    } = setState;

    const {
        featureCollection,
        functionType,
        viewport,
        filters,
        selectedSavedAreas,
        styleUrl,
        datasets,
        modes,
    } = state;

    map.on("load", () => {
        if (featureCollection && draw) {
            draw.set(featureCollection);

            setTimeout(() => {
                setCustomShapeStyling({
                    map,
                    isLoaded: true,
                    datasets,
                    geometries: featureCollection.features,
                });
            }, 200);
        }

        if (styleUrl && setStyle) {
            const currentStyle = map.getStyle();

            if (!isEqual(currentStyle.sprite, styleUrl)) {
                setStyle(styleUrl);
            }
        }

        const drawnShapes = draw?.getAll().features;

        const polygons = pipe(
            featureCollection?.features || [],
            A.map((feature) =>
                isPolygonFeature(feature) ? getInputPolygon(feature) : undefined
            ),
            compact
        );

        if (!!drawnShapes && !isEmpty(drawnShapes)) {
            // Wait till the shapes are drawn, then set the polygons state
            setPolygons(
                uniqWith(
                    pipe(
                        polygons,
                        A.concat(
                            getPolygonsFromSavedAreas(
                                selectedSavedAreas.savedAreas
                            )
                        )
                    ),
                    isEqual
                )
            );
        }

        if (
            viewport &&
            isEmpty(selectedSavedAreas.savedAreas) &&
            isEmpty(drawnShapes) &&
            isEmpty(featureCollection?.features)
        ) {
            setViewport(viewport);
        }

        if (!isEmpty(polygons)) {
            // Wait till the polygons state is set, then fetch the datasets
            if (!isEmpty(OGPolygons)) {
                setDatasets?.(datasets);
            }
        } else {
            setDatasets?.(datasets);
        }
    });

    map.on("style.load", () => {
        if (functionType) {
            setFunctionType(functionType);
        }

        setMode(modes.select);
        setFilters(filters);
        setSavedAreas(selectedSavedAreas);
    });
};
