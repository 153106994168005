import {
    AvatarGroup,
    Chip,
    CompanyAvatar,
    Grid,
    Stack,
    Typography,
    UserAvatar,
} from "@biggeo/bg-ui/lab";

export const DatascapeDemoBanner = ({
    users,
    status,
    isTemporary,
}: {
    readonly users: readonly {
        readonly id: string;
        readonly email: string;
        readonly imgUrl: string;
    }[];
    readonly status: string;
    readonly isTemporary?: boolean;
}) => {
    return (
        <Grid
            container
            alignItems="center"
            gap={2}
            sx={{
                borderRadius: (theme) => theme.radius.xs3,
                padding: 4,
                border: (theme) => `1px solid ${theme.palette.stroke[100]}`,
            }}
        >
            <Grid item>
                <CompanyAvatar
                    square
                    src={
                        "https://biggeo.blob.core.windows.net/media/Datascape-Logo-Img.png"
                    }
                    sx={{
                        borderColor: (theme) => theme.palette.stroke[100],
                    }}
                    alt="Datascape"
                />
            </Grid>
            <Grid item xs>
                <Stack>
                    <Typography
                        variant="title2"
                        fontWeight="bold"
                        sx={{
                            color: (theme) => theme.palette.surface.onContainer,
                        }}
                    >
                        Datascape
                    </Typography>
                    {!isTemporary && (
                        <Typography
                            variant="body3"
                            sx={{
                                color: (theme) =>
                                    theme.palette.disabled.onContainer,
                            }}
                        >
                            We’re using this product to create sales regions
                            maps
                        </Typography>
                    )}
                </Stack>
            </Grid>
            {!isTemporary && (
                <Grid item>
                    <Grid container gap={1} alignItems="center">
                        <AvatarGroup max={4} size="xxs">
                            {users.map((user) => (
                                <UserAvatar
                                    key={user.id}
                                    src={user.imgUrl}
                                    alt={user.email}
                                />
                            ))}
                        </AvatarGroup>
                        {/* TODO: set color based on status value */}
                        <Chip variant={"tonal"} color={"success"}>
                            {status}
                        </Chip>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
