import {
    CollectionProgressStatus,
    DataSource,
    DatabaseType,
} from "@biggeo/bg-server-lib/datascape-ai";
import { DragAndDropContext, DroppableColumn, Stack } from "@biggeo/bg-ui/lab";
import { toNonReadonlyArray } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import { ColorSwatchOption } from "../../common/components/ColorSwatchSelector";
import { PreviewDatasetCellItem } from "../../common/components/PreviewDatasetCellItem";
import { StatisticsDownloadProgressWrapper } from "../../common/components/StatisticsDownloadProgressWrapper";
import { getLinearGradient } from "../../common/utils/gradient";
import {
    MapContextDataset,
    MapContextPreviewDataset,
} from "../../map/mapbox/context";
import { DEFAULT_SHAPE_COLOR } from "../../map/mapbox/hooks/style-hooks";
import PreviewDatasetMenu from "../../map/views/PreviewDatasetMenu";
import { ExploreDatasetsViewProps } from "./ExploreDatasetsView";

interface IPreviewDatasetItem
    extends Omit<
        ExploreDatasetsViewProps,
        | "exploreDataset"
        | "onClickPreviewInfo"
        | "map"
        | "isLoaded"
        | "updateDataSource"
    > {
    handleVisibility: (
        item: MapContextDataset,
        preview?: MapContextPreviewDataset
    ) => void;
    onClickPreview: (dataSource: DataSource) => void;
    previews: MapContextPreviewDataset[];
    updatePreviewHeatmapColor: (i: {
        preview: MapContextPreviewDataset;
        value: ColorSwatchOption;
    }) => void;
}

const PreviewDatasetItem = ({
    datasets: data,
    onEdit,
    onRemove,
    onView,
    updateDataset,
    reorderDatasets,
    handleVisibility,
    onClickPreview,
    previews,
    setPreviewDataset,
    updatePreviewHeatmapColor,
}: IPreviewDatasetItem) => {
    const preview = useMemo(
        () =>
            pipe(
                previews,
                A.findFirst((f) => isEqual(f.isGettingStyled, true)),
                O.fold(
                    () => undefined,
                    (f) => f
                )
            ),
        [previews]
    );

    const isGettingStyled = preview ? preview.isGettingStyled : false;

    return isGettingStyled && preview ? (
        <PreviewDatasetMenu
            preview={preview}
            onBack={() =>
                setPreviewDataset({
                    dataSourceId: preview.dataSourceId,
                    dataset: {
                        isGettingStyled: false,
                    },
                })
            }
            updateHeatMapColor={(value, p) =>
                updatePreviewHeatmapColor({ value, preview: p })
            }
        />
    ) : (
        <DragAndDropContext
            onDragEnd={(result) => {
                if (!result.destination || !data) {
                    return;
                }

                const items = Array.from(data);
                const [reorderedItem] = items.splice(result.source.index, 1);
                items.splice(result.destination.index, 0, reorderedItem);

                reorderDatasets(items.map((i) => i.dataSource.id));
            }}
        >
            <DroppableColumn droppableId="explore-datasets">
                <Stack
                    sx={{
                        "& > *": {
                            marginY: 1,
                        },
                    }}
                >
                    {pipe(
                        data,
                        toNonReadonlyArray,
                        A.mapWithIndex((index, dataset) => {
                            const preview = pipe(
                                previews,
                                A.findFirst(
                                    (p) =>
                                        p.dataSourceId === dataset.dataSource.id
                                ),
                                O.fold(
                                    () => undefined,
                                    (p) => p
                                )
                            );

                            return (
                                <StatisticsDownloadProgressWrapper
                                    dataSourceId={dataset.dataSource.id}
                                    key={dataset.dataSource.id}
                                    updateDataset={updateDataset}
                                >
                                    {({ progress, status }) => (
                                        <PreviewDatasetCellItem
                                            key={dataset.dataSource.id}
                                            visibility={preview?.isVisible}
                                            setVisibility={() => [
                                                handleVisibility(
                                                    dataset,
                                                    preview
                                                ),
                                            ]}
                                            color={
                                                dataset.dataSource.color ||
                                                DEFAULT_SHAPE_COLOR
                                            }
                                            gradient={
                                                dataset.dataSource.heatmapColor
                                                    ? getLinearGradient(
                                                          JSON.parse(
                                                              dataset.dataSource
                                                                  .heatmapColor
                                                          )
                                                      )
                                                    : undefined
                                            }
                                            disabled={
                                                dataset.dataSource.progress
                                                    ? dataset.dataSource
                                                          .progress < 100
                                                    : status !==
                                                      CollectionProgressStatus.complete
                                            }
                                            index={index}
                                            dataset={{
                                                id: dataset.dataSource.id,
                                                name:
                                                    dataset.dataSource.label ||
                                                    dataset.dataSource
                                                        .tableName,
                                                image:
                                                    dataset.dataSource.icon ||
                                                    "",
                                                progress:
                                                    dataset.dataSource
                                                        .progress ??
                                                    Number(progress),
                                            }}
                                            readonly={
                                                dataset.dataSource.type ===
                                                DatabaseType.polygon
                                            }
                                            onClickCard={() => {
                                                onClickPreview(
                                                    dataset.dataSource
                                                );
                                            }}
                                            onEdit={onEdit}
                                            onView={onView}
                                            onRemove={onRemove}
                                        />
                                    )}
                                </StatisticsDownloadProgressWrapper>
                            );
                        })
                    )}
                </Stack>
            </DroppableColumn>
        </DragAndDropContext>
    );
};

export default PreviewDatasetItem;
