import {
    DataSource,
    DataSourceExtended,
    DataSourcesExtended,
    ShapeColor,
    ShapeStyle,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Failure,
    Initialized,
    Pending,
    RemoteData,
    Success,
    map,
} from "@vividtheory/remotedata";
import {
    ActionType,
    action,
    createAsyncAction,
    getType,
} from "typesafe-actions";

export interface UpdateComputeUse {
    readonly id: string;
    readonly compute: boolean;
}
import { ApolloError } from "@apollo/client";
import { ColorSwatchOption } from "../../common/components/ColorSwatchSelector";

export type UpdateMapTemplateDatasetContext = {
    mapTemplateDatasetId: number;
    icon?: string;
    heatMapColor?: ColorSwatchOption;
    color?: string;
    stroke?: ShapeColor;
    shape?: ShapeStyle;
};

interface IModel {
    readonly dataSourcesRemoteData: RemoteData<string, DataSourcesExtended>;
    readonly previewDatasets: RemoteData<ApolloError, DataSourceExtended[]>;
}

const initialState: IModel = {
    previewDatasets: new Initialized(),
    dataSourcesRemoteData: new Initialized(),
};

const fetchDataSources = createAsyncAction(
    "F_fetchDataSources",
    "R_fetchDataSources",
    "E_fetchDataSources"
)<void, DataSourcesExtended, string>();
const fetchPreviewDataSources = createAsyncAction(
    "F_fetchPreviewDataSources",
    "R_fetchPreviewDataSources",
    "E_fetchPreviewDataSources"
)<void, DataSourceExtended[], ApolloError>();
const turnOnComputeOnDownloadComplete = (id: string) =>
    action("TURN_ON_COMPUTE_ON_DOWNLOAD_COMPLETE", id);

const updatePreviewDatasets = (
    p: RemoteData<ApolloError, DataSourceExtended[]>
) => action("UPDATE_PREVIEW_DATASETS", p);
const updatePreviewDataset = (p: Pick<DataSource, "id" | "heatmapColor">) =>
    action("UPDATE_PREVIEW_DATASET", p);

export const databaseMetaDataActions = {
    fetchDataSources,
    fetchPreviewDataSources,
    updatePreviewDatasets,
    turnOnComputeOnDownloadComplete,
    updatePreviewDataset,
};

export type DatabaseActionType = ActionType<typeof databaseMetaDataActions>;

type CombinedActions = DatabaseActionType;

export const dataSourcesReducer = (
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    state: IModel = initialState,
    action: CombinedActions
): IModel => {
    switch (action.type) {
        case getType(fetchPreviewDataSources.success):
            return {
                ...state,
                previewDatasets: new Success(action.payload),
            };
        case getType(fetchPreviewDataSources.failure):
            return {
                ...state,
                previewDatasets: new Failure(action.payload),
            };
        case getType(fetchPreviewDataSources.request):
            return {
                ...state,
                previewDatasets: new Pending(),
            };
        case getType(fetchDataSources.request):
            return { ...state, dataSourcesRemoteData: new Pending() };
        case getType(fetchDataSources.success):
            return {
                ...state,
                dataSourcesRemoteData: new Success(action.payload),
            };
        case getType(fetchDataSources.failure):
            return {
                ...state,
                dataSourcesRemoteData: new Failure(action.payload),
            };
        case "TURN_ON_COMPUTE_ON_DOWNLOAD_COMPLETE": {
            return {
                ...state,
                previewDatasets: map(
                    (data) =>
                        data.map((ds) => ({
                            ...ds,
                            dataSource:
                                ds.dataSource.collectionName === action.payload
                                    ? {
                                          ...ds.dataSource,
                                          compute: true,
                                          isConnected: true,
                                          progress: 100,
                                      }
                                    : ds.dataSource,
                        })),
                    state.previewDatasets
                ),
            };
        }
        case "UPDATE_PREVIEW_DATASETS": {
            return {
                ...state,
                previewDatasets: action.payload,
            };
        }
        case "UPDATE_PREVIEW_DATASET": {
            return {
                ...state,
                previewDatasets: map(
                    (data) =>
                        data.map((ds) => ({
                            ...ds,
                            dataSource:
                                ds.dataSource.id === action.payload.id
                                    ? {
                                          ...ds.dataSource,
                                          heatmapColor:
                                              action.payload.heatmapColor,
                                      }
                                    : ds.dataSource,
                        })),
                    state.previewDatasets
                ),
            };
        }
        default:
            return state;
    }
};
