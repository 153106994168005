import {
    BadgeDot,
    Divider,
    ExitIntentModal,
    ExitIntentModalProps,
    IconButton,
    OverflowingTypography,
    Stack,
    Tag,
    tagClasses,
} from "@biggeo/bg-ui/lab";
import { CloseOutline } from "@biggeo/bg-ui/lab/icons";
import { useDispatch } from "react-redux";
import { compose } from "redux";
import { modalActions } from "../../modal/redux/model";

export type FeedbackChipProps = {
    startNode: JSX.Element;
    text: string;
    count: number;

    onClick?: () => void;
    onModalSaveClick: () => void;
} & Pick<ExitIntentModalProps, "buttons" | "subtitle" | "sx">;

export const FeedbackChip = ({
    startNode,
    text,
    count,

    buttons,
    subtitle,
    sx,

    onClick,
    onModalSaveClick,
}: FeedbackChipProps) => {
    const dispatch = useDispatch();
    const openModal = compose(dispatch, modalActions.openModal);
    const closeModal = compose(dispatch, modalActions.closeModal);

    const onCloseClick = () => {
        openModal({
            modalType: "new-dialog",
            component: (
                <ExitIntentModal
                    withLoading={false}
                    subtitle={subtitle}
                    discard={() => {
                        // Close and discard
                        closeModal();
                    }}
                    save={() => {
                        // Continue and save
                        onModalSaveClick();
                        closeModal();
                    }}
                    buttons={buttons}
                    sx={sx}
                />
            ),
            dialogProps: {
                variant: "centered-small",
                onClose: undefined,
            },
        });
    };

    return (
        <Tag
            rounded
            density="dense"
            variant="outlined"
            onClick={onClick}
            startNode={
                <IconButton
                    variant="ghost"
                    density="none"
                    disableActiveEffect
                    disableFocusEffect
                    disableHoverEffect
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onCloseClick();
                    }}
                >
                    <CloseOutline size="xs" />
                </IconButton>
            }
            endNode={<BadgeDot color="primary">{count}</BadgeDot>}
            sx={{
                [`&.${tagClasses.outlined}`]: {
                    cursor: onClick ? "pointer" : undefined,
                },
            }}
        >
            <Stack
                flexDirection="row"
                alignItems="center"
                gap={1}
                width="100%"
                height="100%"
                sx={{
                    maxWidth: (theme) => theme.spacing(38.75),
                }}
            >
                <Stack>
                    <Divider
                        orientation="vertical"
                        color={200}
                        sx={{
                            height: (theme) => theme.spacing(4),
                        }}
                    />
                </Stack>
                {startNode}
                <OverflowingTypography
                    variant="body3"
                    fontWeight="semibold"
                    sx={{
                        maxWidth: (theme) => theme.spacing(30),
                    }}
                >
                    {text}
                </OverflowingTypography>
            </Stack>
        </Tag>
    );
};
