import { Pricing } from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    DatasetAccessKeyCellItem,
    Divider,
    Grid,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { Link } from "react-router-dom";
import { Routes } from "../../navigation/redux/model";
import { formatNumberWithCommas } from "../../utils/utils";

interface IMarketplaceDatasetPreviewInfo {
    readonly price: readonly Pricing[];
    readonly size: number;
}

const MarketplaceDatasetPreviewInfo = ({
    price,
    size,
}: IMarketplaceDatasetPreviewInfo) => {
    return (
        <Stack gap={4}>
            <Stack gap={4}>
                {price.map((p) => (
                    <Stack
                        key={`${p.price} + ${p.priceOption}`}
                        flexDirection="row"
                        alignItems="center"
                        gap={1}
                    >
                        <Typography variant="h4" fontWeight="bold">
                            ${`${p.price}`}
                        </Typography>
                        <Typography
                            variant="body3"
                            textColor="disabled.onContainer"
                        >
                            /{p.priceOption}
                        </Typography>
                    </Stack>
                ))}
                <Stack>
                    <Stack flexDirection="row" alignItems="center" gap={2}>
                        <Typography
                            variant="body4"
                            textColor="disabled.onContainer"
                        >
                            Size:
                        </Typography>
                        <Typography
                            variant="body3"
                            fontWeight="bold"
                        >{`${formatNumberWithCommas(size)} rows`}</Typography>
                    </Stack>
                    <Stack flexDirection="row" alignItems="center" gap={2}>
                        <Typography
                            variant="body4"
                            textColor="disabled.onContainer"
                        >
                            Refresh:
                        </Typography>
                        <Typography variant="body3" fontWeight="bold">
                            Monthly
                        </Typography>
                    </Stack>
                </Stack>
                <Typography variant="body3" fontWeight="regular">
                    By using this dataset you agree to it's{" "}
                    <Link to={"/"}>
                        <u>
                            <b>terms of use</b>
                        </u>
                    </Link>
                </Typography>
            </Stack>
            <Divider color={100} />
            <Stack gap={2}>
                <Link to={"https://biggeo.com/deploy/contact-sales"}>
                    <Button variant="filled" color="primary" fullWidth>
                        Contact Sales
                    </Button>
                </Link>
                <Link to={Routes.support}>
                    <Button variant="outlined" color="surface" fullWidth>
                        Support
                    </Button>
                </Link>
            </Stack>
            <Divider color={100} />
            <DatasetAccessKeyCellItem variant="outlined" />
        </Stack>
    );
};

export default MarketplaceDatasetPreviewInfo;
