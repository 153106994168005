import {
    MapStyleType,
    SavedView,
    useFetchAllAreasExtendedQuery,
    useFetchSavedViewByIdQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { SelectableTreeMenuItem } from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SetURLSearchParams } from "react-router-dom";
import { isAppRunningOnSF } from "../../common/redux/hooks";
import {
    getContextDatasets,
    getContextFilters,
} from "../filter-criteria/utils/utils";
import { usePureDataString } from "../hooks/pure-data-string-hook";
import { useMap } from "../mapbox/context";
import { setDatasetContext } from "../mapbox/context/context-utils";
import { selectSavedAreas } from "../mapbox/utils/map-utils";
import { useMapData } from "../redux/hooks";
import { mapDataActions } from "../redux/model";
import { setCustomShapeStyling } from "../utils/style-utils";
import {
    FunctionType,
    getInputPolygon,
    getMapFeatures,
    getSavedViewPolygons,
} from "../utils/utils";
import MapViewPage from "./MapViewPage";
import {
    IMapViewWrapper,
    MapTableTabs,
    MapTableTabsType,
    MapTabs,
} from "./MapViewWrapper";

interface ISavedViewPage extends IMapViewWrapper, MapTableTabsType {
    readonly savedViewId: number;
    readonly searchParams: URLSearchParams;
    readonly setSearchParams: SetURLSearchParams;
    readonly filterItems: SelectableTreeMenuItem[];
    readonly setFilterItems: React.Dispatch<
        React.SetStateAction<SelectableTreeMenuItem[]>
    >;
    readonly handleMapTabs: (tab: MapTabs) => void;
    readonly savedAreaId: number;
}

const SavedViewPage = ({
    savedViewId,
    mapTemplateId,
    setSearchParams,
    filterItems,
    setFilterItems,
    tab,
    setTab,
    ...props
}: ISavedViewPage) => {
    const dispatch = useDispatch();
    const savedView = useMapData();
    const isRunningOnSF = isAppRunningOnSF();
    const {
        map,
        draw,
        isLoaded,
        datasets,
        dispatch: mapDispatch,
        polygons,
        functionType,
        mapStyle,
    } = useMap();
    const { overrideDatasets } = setDatasetContext();
    const {
        setSavedPolygons,
        handleViewportChange,
        setFunctionType,
        handleSavedPolygons,
        deleteShape,
    } = usePureDataString();

    const {
        queryReturn: { data: _, refetch },
    } = useFetchSavedViewByIdQuery({
        variables: { id: savedViewId },
        notifyOnNetworkStatusChange: true,
        onCompleted: ({ fetchSavedViewById: data }) => {
            if (data) {
                dispatch(mapDataActions.updateMapData(data));

                if (
                    data.fkSavedAreaId &&
                    isLoaded &&
                    !isEqual(functionType, FunctionType.savedPolygon)
                ) {
                    setFunctionType(FunctionType.savedPolygon);
                }

                // Style needs to be set first for the map to load
                const style = data.mapSetting
                    ? data.mapSetting.mapStyle
                    : MapStyleType.light;

                if (!isEqual(mapStyle, style)) {
                    mapDispatch?.({
                        type: "SET_MAP_STYLE",
                        values: style,
                    });
                }
            }
        },
    });

    const {
        queryReturn: { data: savedAreasData },
    } = useFetchAllAreasExtendedQuery(
        savedView.savedArea
            ? {
                  variables: {
                      fkMapTemplateId: mapTemplateId,
                  },
              }
            : { skip: true }
    );

    const setSavedView = ({
        savedArea,
        viewport,
        filters: savedViewFilters,
        datasets: savedViewDatasets,
        isRunningOnSF,
    }: Pick<SavedView, "savedArea" | "viewport" | "filters" | "datasets"> & {
        isRunningOnSF: boolean;
    }) => {
        if (savedArea && !isEmpty(savedArea.geometries)) {
            const { drawnGeometries, savedAreaGeometries } =
                getSavedViewPolygons(savedArea.geometries);

            if (drawnGeometries) {
                handleSavedPolygons(
                    pipe(
                        drawnGeometries,
                        A.map((f) => getInputPolygon(f))
                    )
                );

                if (draw.current) {
                    draw.current.add({
                        type: "FeatureCollection",
                        features: drawnGeometries,
                    });

                    if (map.current) {
                        setCustomShapeStyling({
                            map: map.current,
                            isLoaded,
                            datasets,
                            geometries: drawnGeometries,
                        });
                    }
                }
            }

            if (savedAreaGeometries) {
                selectSavedAreas({
                    savedAreas: savedAreaGeometries,
                    filterItems,
                    setFilterItems,
                    setFunctionType,
                    setSavedPolygons,
                    deleteShape,
                    drawnPolygons: getMapFeatures(draw, isLoaded),
                    polygons,
                    data: savedAreasData?.fetchAllAreasExtended || [],
                });
            }
        }

        if (viewport) {
            map.current?.fitBounds([
                {
                    lat: viewport.latBounds.min,
                    lng: viewport.lngBounds.min,
                },
                {
                    lat: viewport.latBounds.max,
                    lng: viewport.lngBounds.max,
                },
            ]);

            if (!savedArea || isEmpty(savedArea.geometries)) {
                handleViewportChange({ viewport });
            }
        }

        if (savedViewFilters) {
            const filters = getContextFilters(savedViewFilters, isRunningOnSF);

            if (filters) {
                mapDispatch?.({
                    type: "SET_FILTERS",
                    values: filters,
                });
            }
        }

        if (savedViewDatasets && !isEmpty(savedViewDatasets)) {
            overrideDatasets(
                getContextDatasets({
                    savedViewDatasets: pipe(
                        savedViewDatasets,
                        A.map((d) => {
                            const dataset = datasets.find(
                                (item) =>
                                    item.dataSource.id === d.dataSource?.id
                            );

                            return dataset
                                ? {
                                      ...d,
                                      selectedRows: dataset.selectedRows,
                                      pinnedRows: dataset.pinnedRows,
                                  }
                                : d;
                        })
                    ),
                    savedViewFilters,
                    mapTemplateId,
                    isRunningOnSF,
                })
            );

            if (map.current && isLoaded) {
                const currentZoom = map.current.getZoom() || 0;

                // A barely perceptible zoom to trigger a refetch when multiple
                // datasets are toggled on.
                map.current.zoomTo(currentZoom - 0.05);
            }
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const updateSavedViewData = (data: Partial<SavedView>) => {
            const savedViewDatasets = data.datasets || undefined;
            const savedArea = data.savedArea || undefined;
            const viewport = data.viewport || undefined;
            const savedViewFilters = data.filters || undefined;

            setSavedView({
                savedArea,
                viewport,
                filters: savedViewFilters,
                datasets: savedViewDatasets,
                isRunningOnSF,
            });
        };

        if (savedView && isLoaded && map.current) {
            updateSavedViewData(savedView);
        }
    }, [
        savedView,
        isLoaded,
        map.current,
        draw.current,
        datasets.length,
        filterItems.length,
        isRunningOnSF,
        savedAreasData?.fetchAllAreasExtended.length,
    ]);

    return (
        <MapViewPage
            {...props}
            mapTemplateId={mapTemplateId}
            savedViewId={savedViewId}
            filterItems={filterItems}
            setFilterItems={setFilterItems}
            setSearchParams={setSearchParams}
            tab={tab}
            setTab={(t) => {
                setTab(t);

                // Refetch Saved View if you switch to a tab where the map should be displayed.
                if (
                    isEqual(t, MapTableTabs.map) ||
                    isEqual(t, MapTableTabs.split)
                ) {
                    refetch();
                }
            }}
        />
    );
};

export default SavedViewPage;
