import {
    AnyResponse,
    ColumnAttributes,
    FilterType,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    DataGridPro,
    GridColDef,
    GridColumnHeaders,
    datagridClasses,
} from "@biggeo/bg-ui";
import { DataGridPagination } from "@biggeo/bg-ui/lab";
import { MoreVertOutline, SwapVertOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import React from "react";
import uuid from "react-uuid";
import { match } from "ts-pattern";
import { formatNumberWithCommas } from "../../../utils/utils";
import { MapContextDatasetFilterItem } from "../../mapbox/context";
import { getFilterCriteriaOperators } from "../utils/utils";
import { DatasetTableProps } from "./DatasetTable";
import { DatasetTableHeaderMenu } from "./DatasetTableHeaderMenu";
import { DatasetTableStates } from "./DatasetTableStates";

export type DatasetTableGridProps = {
    data: AnyResponse;
    selectedRows: AnyResponse["data"];
    onSelectedRowsChange: (r: AnyResponse["data"]) => void;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    columns: GridColDef<any>[];
    onApplyFilters: (filter: MapContextDatasetFilterItem) => void;
    getColumnAttributes: (column: string) => ColumnAttributes | undefined;
    onClearFilters?: () => void;
    isFilteringMenuDisabled?: boolean;
    hasFilters: boolean;
    pinnedRows: AnyResponse["data"];
} & Pick<DatasetTableProps, "loading" | "dataPaginationProps" | "tableId">;

export const DatasetTableGrid = ({
    data,
    columns,
    loading,
    dataPaginationProps,
    selectedRows,
    onSelectedRowsChange,
    onApplyFilters,
    getColumnAttributes,
    onClearFilters,
    isFilteringMenuDisabled = false,
    hasFilters,
    tableId,
    pinnedRows,
}: DatasetTableGridProps) => {
    const rows = pipe(
        data.data,
        A.mapWithIndex((index, d) => ({
            ...d,
            id: tableId ? d[tableId] : index,
            idType: tableId ? "tableId" : "index",
            index,
        }))
    );

    return (
        <DataGridPro
            rows={rows}
            rowHeight={35}
            disableColumnMenu={isFilteringMenuDisabled}
            keepNonExistentRowsSelected
            checkboxSelection={!isEmpty(columns)}
            loading={loading}
            pinnedRows={
                isEmpty(rows)
                    ? undefined
                    : {
                          top: pinnedRows,
                      }
            }
            onRowSelectionModelChange={(r) => {
                onSelectedRowsChange(
                    pipe(
                        [r],
                        flatten,
                        A.map((identifier) => {
                            const row = rows.find((r) =>
                                isEqual(r.idType, "tableId")
                                    ? r.id.toString() === identifier.toString()
                                    : r.index.toString() ===
                                      identifier.toString()
                            );

                            if (row) {
                                return row;
                            }

                            // After you filter, sometimes the selected rows are no longer in rows.
                            const selectedRow = selectedRows.find((r) =>
                                isEqual(r.idType, "tableId")
                                    ? r.id.toString() === identifier.toString()
                                    : r.index.toString() ===
                                      identifier.toString()
                            );

                            if (!row && selectedRow) {
                                return selectedRow;
                            }
                        })
                    )
                );
            }}
            rowSelectionModel={pipe(
                selectedRows,
                A.map((r) => r.id)
            )}
            columns={columns}
            columnHeaderHeight={35}
            rowBuffer={15}
            disableColumnResize
            disableColumnSelector
            disableColumnFilter
            disableRowSelectionOnClick
            hideFooterRowCount
            filterMode="server"
            rowCount={data.count || 0}
            paginationMode="server"
            pagination
            slots={{
                columnHeaders: React.memo(GridColumnHeaders),
                loadingOverlay: () => <DatasetTableStates state="loading" />,
                noRowsOverlay: () => (
                    <DatasetTableStates
                        state="empty"
                        button={
                            isFilteringMenuDisabled || !hasFilters
                                ? undefined
                                : {
                                      onClick: () => onClearFilters?.(),
                                  }
                        }
                    />
                ),
                pagination: () => (
                    <DataGridPagination
                        currentPage={dataPaginationProps.pageIndex}
                        numberOfPages={Math.ceil(
                            data.count / dataPaginationProps.pageLimit
                        )}
                        setPage={dataPaginationProps.setPageIndex}
                        showing={{
                            current: formatNumberWithCommas(data.data.length),
                            all: formatNumberWithCommas(data.count || 0),
                        }}
                    />
                ),
                columnSortedAscendingIcon: () => <SwapVertOutline size="xs" />,
                columnSortedDescendingIcon: () => <SwapVertOutline size="xs" />,
                columnUnsortedIcon: () => <SwapVertOutline size="xs" />,
                columnMenuIcon: () => <MoreVertOutline size="xs" />,
                columnMenu: ({ colDef, hideMenu }) => {
                    const column = colDef.field;
                    const type = match(colDef.type)
                        .with("boolean", () => FilterType.BooleanType)
                        .with("date", () => FilterType.DateType)
                        .with("number", () => FilterType.NumberType)
                        .otherwise(() => FilterType.StringType);

                    const attributes =
                        type === FilterType.StringType ||
                        type === FilterType.NumberType
                            ? getColumnAttributes(column)
                            : undefined;

                    return (
                        <DatasetTableHeaderMenu
                            type={type}
                            header={column}
                            onCancelClick={() => hideMenu()}
                            operators={getFilterCriteriaOperators(type)}
                            attributes={attributes?.string?.list}
                            onApplyClick={(item) => {
                                onApplyFilters({ ...item, id: uuid() });
                            }}
                            min={attributes?.number?.min}
                            max={attributes?.number?.max}
                            limit={attributes?.string?.list.length}
                            total={attributes?.string?.total}
                        />
                    );
                },
            }}
            sx={{
                minHeight: "280px",
                borderColor: (theme) => theme.palette.stroke[100],
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                "--unstable_DataGrid-radius": 0,
                [`.${datagridClasses.columnHeader}`]: {
                    borderColor: (theme) => theme.palette.stroke[100],
                    "&:focus": {
                        outline: "none",
                    },
                    "&:focus-within": {
                        outline: "none",
                    },
                    backgroundColor: (theme) => theme.palette.surface.container,
                    color: (theme) => theme.palette.disabled.onContainer,
                    paddingX: 2,
                },
                [`.${datagridClasses.columnHeaderTitleContainerContent}`]: {
                    overflow: "unset",
                },
                [`.${datagridClasses.columnHeaderTitleContainer}`]: {
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                },
                [`.${datagridClasses.columnHeaderTitle}`]: {
                    fontFamily: (theme) => theme.typography.body3.fontFamily,
                    fontSize: (theme) => theme.typography.body3.fontSize,
                    fontWeight: (theme) => theme.typography.fontWeightSemiBold,
                },
                [`.${datagridClasses.pinnedColumnHeaders}`]: {
                    backgroundColor: (theme) =>
                        theme.palette.background.container,
                    color: (theme) => theme.palette.background.onContainer,
                },
                [`.${datagridClasses.pinnedColumns}`]: {
                    backgroundColor: (theme) =>
                        theme.palette.background.container,
                    color: (theme) => theme.palette.background.onContainer,
                },
                [`.${datagridClasses.cell}`]: {
                    paddingX: 2,
                    "&:focus": {
                        outline: "none",
                    },
                    "&:focus-within": {
                        outline: "none",
                    },
                    minHeight: 100,
                },
                [`.${datagridClasses.row}`]: {
                    "&.Mui-selected": {
                        backgroundColor: (theme) =>
                            `${theme.palette.disabled.container} !important`,
                    },
                },
                [`.${datagridClasses.withBorderColor}`]: {
                    borderColor: (theme) => theme.palette.stroke[100],
                },
                [`.${datagridClasses.footerContainer}`]: {
                    minHeight: "unset",
                },
                [`.${datagridClasses.selectedRowCount}`]: {
                    display: "none",
                },
                "& .MuiButtonBase-root": {
                    "&.MuiCheckbox-root": {
                        "&.Mui-checked": {
                            color: (theme) => theme.palette.surface.main,
                        },
                    },
                },
                [`.${datagridClasses.menuIcon}`]: {
                    visibility: "visible",
                    width: "unset",
                },
                "& .MuiDataGrid-iconButtonContainer": {
                    visibility: "visible !important",
                    width: "auto !important",
                },
                [`.${datagridClasses.pinnedRows}`]: {
                    backgroundColor: (theme) => theme.palette.surface.container,
                },
            }}
        />
    );
};
