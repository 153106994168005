import {
    DatabaseType,
    InputDatasetStatisticsFilters,
    MarketplaceDatasetColumn,
    useFetchDatasetStatisticsLazyQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { themeSpacingToNumber } from "@biggeo/bg-ui";
import {
    ChartLayoutCard,
    HorizontalFilledBarChart,
} from "@biggeo/bg-ui/charts";
import { ArrowDropDown } from "@biggeo/bg-ui/icons";
import {
    Box,
    Chip,
    Grid,
    Menu,
    MenuItem,
    ProgressBar,
    Stack,
    Typography,
} from "@biggeo/bg-ui/lab";
import { theme } from "@biggeo/bg-ui/theme";
import { Kinds, match } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import React from "react";
import { MapContextPreviewDataset, useMap } from "../../map/mapbox/context";
import { formatNumberWithCommas } from "../../utils/utils";

export interface IMarketplaceDatasetStatisticsContainer {
    readonly dataSourceId?: string;
    readonly tableName: string;
    readonly columns: MarketplaceDatasetColumn[];
    readonly filters: InputDatasetStatisticsFilters;
    readonly type: DatabaseType;
    readonly setPreviewDataset: (values: MapContextPreviewDataset) => void;
}

const MarketplaceDatasetStatisticsContainer = ({
    tableName,
    filters,
    columns,
    dataSourceId,
    setPreviewDataset,
}: IMarketplaceDatasetStatisticsContainer) => {
    const { previews } = useMap();
    const firstColumn = columns.map((c) => c.name)[0];
    const [column, setColumn] = useState<string | undefined>(firstColumn);

    const {
        executeQuery: getDatasetStatistics,
        remote,
        queryReturn: [_, { loading }],
    } = useFetchDatasetStatisticsLazyQuery();
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (column && dataSourceId) {
            getDatasetStatistics({
                variables: {
                    input: {
                        tableName,
                        column,
                        filters: {
                            ...filters,
                            multipolygon:
                                !filters.multipolygon ||
                                isEmpty(filters.multipolygon)
                                    ? undefined
                                    : filters.multipolygon,
                        },
                    },
                },
                onCompleted: (data) => {
                    const found = previews.find(
                        (p) => p.dataSourceId === dataSourceId
                    );

                    if (found) {
                        setPreviewDataset({
                            ...found,
                            tableName: data.fetchDatasetStatistics.tableName,
                            column:
                                data.fetchDatasetStatistics.column || undefined,
                            geometry: data.fetchDatasetStatistics.geometry,
                            isVisible: found ? found.isVisible : false,
                            isGettingStyled: false,
                        });
                    }
                },
            });
        }
    }, [column, filters.polygon, filters.multipolygon?.length, tableName]);
    const [open, setOpen] = useState(false);

    return (
        <ChartLayoutCard
            slotProps={{
                mainContent: {
                    sx: {
                        gap: 1,
                    },
                },
                chartContainer: { sx: { p: 2 } },
                topDivider: {
                    sx: {
                        display: "none",
                    },
                },
                root: {
                    sx: {
                        border: "none",
                    },
                },
            }}
            disablePadding
            title="Area data quality"
            loading={loading || remote.kind === Kinds.Initialized}
            topRightSection={
                <Menu
                    open={open}
                    placement="bottom-end"
                    onClick={() => setOpen(!open)}
                    content={pipe(
                        columns,
                        A.map((c) => (
                            <MenuItem
                                key={c.name}
                                density="dense"
                                label={c.name}
                                onClick={() => setColumn(c.name)}
                            />
                        ))
                    )}
                >
                    <Chip
                        endNode={
                            <ArrowDropDown
                                fontSize="inherit"
                                sx={{
                                    transform: open
                                        ? "rotate(-180deg)"
                                        : "none",
                                    transition: (theme) =>
                                        theme.transitions.create(["transform"]),
                                }}
                            />
                        }
                    >
                        {column}
                    </Chip>
                </Menu>
            }
        >
            {match(remote, {
                _: () => <></>,
                Success: (data) => (
                    <Stack gap={2}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography fontWeight="bold">
                                    Total rows:{" "}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {formatNumberWithCommas(
                                        data.fetchDatasetStatistics.totalRows
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography fontWeight="bold">
                                    Filled Out:{" "}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {formatNumberWithCommas(
                                        data.fetchDatasetStatistics.total
                                    )}{" "}
                                    (estimate)
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item xs>
                                <ProgressBar
                                    value={
                                        (data.fetchDatasetStatistics.total /
                                            data.fetchDatasetStatistics
                                                .totalRows) *
                                        100
                                    }
                                    color={"success"}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {(data.fetchDatasetStatistics.total /
                                        data.fetchDatasetStatistics.totalRows) *
                                        100}
                                    %
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                ),
            })}
        </ChartLayoutCard>
        // <HorizontalFilledBarChart
        //     title="Area data quality"
        //     loading={loading || remote.kind === Kinds.Initialized}
        //     selectChipProps={{
        //         label: column ? column : "Column",
        //         variant: "outlined",
        //         closeOnSelect: true,
        //         options: pipe(
        //             columns,
        //             A.map((c) => (
        //                 <MenuItem
        //                     key={c.name}
        //                     density="dense"
        //                     label={c.name}
        //                     onClick={() => setColumn(c.name)}
        //                 />
        //             ))
        //         ),
        //     }}
        //     data={match(remote, {
        //         _: () => [
        //             {
        //                 name: "Estimate",
        //                 amount: 0,
        //             },
        //         ],
        //         Initialized: () => [
        //             {
        //                 name: "Estimate",
        //                 amount: 0,
        //             },
        //         ],
        //         Success: (data) => [
        //             {
        //                 name: "Estimate",
        //                 amount: data.fetchDatasetStatistics.total,
        //             },
        //             {
        //                 name: "Total",
        //                 amount: data.fetchDatasetStatistics.totalRows,
        //             },
        //         ],
        //     })}
        //     barChartProps={{
        //         responsiveContainerProps: {
        //             height: themeSpacingToNumber(theme.spacing(20)),
        //         },
        //     }}
        //     color="chartNegative"
        //     endColor="background"
        //     disablePadding
        //     slotProps={{
        //         mainContent: {
        //             sx: {
        //                 gap: 1,
        //             },
        //         },
        //         topDivider: {
        //             sx: {
        //                 display: "none",
        //             },
        //         },
        //         root: {
        //             sx: {
        //                 border: "none",
        //             },
        //         },
        //     }}
        // />
    );
};

export default React.memo(MarketplaceDatasetStatisticsContainer);
