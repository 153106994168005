import {
    AnyResponse,
    DatasetGeometryPoint,
    FilterCriteriaDatasetItem,
    InputPolygon,
    InputViewBox,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    BadgeDot,
    FlexScrollArea,
    FlexScrollAreaContainer,
    HorizontalScroller,
    LogicOperator,
    Severity,
    Tab,
    TabGroup,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";

import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import replace from "lodash/replace";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import { toasterActions } from "../../../toaster/containers/redux/model";
import { MapTableTabs } from "../../map-wrappers/MapViewWrapper";
import {
    MapContextDataset,
    MapContextDatasetFilter,
    useMap,
} from "../../mapbox/context";
import { SetDatasetContextType } from "../../mapbox/context/context-utils";
import { onFlyToMapFeature } from "../../mapbox/utils/data-hooks-utils";
import { setSelectedRowPulsingDot } from "../../mapbox/utils/map-utils";
import { DEFAULT_LOGIC_OPERATOR, getOrderedDatasetTable } from "../utils/utils";
import DatasetTableContainer from "./DatasetTableContainer";

interface IMainDatasetTableContainer
    extends Pick<SetDatasetContextType, "updateDataset"> {
    readonly tab: MapTableTabs;
    readonly datasets: MapContextDataset[];
    readonly channelId: string;
    readonly geospatialSelection:
        | {
              viewport: InputViewBox;
          }
        | {
              multipolygon: InputPolygon[];
          };
    readonly isRunningOnSF: boolean;
}

export const MainDatasetTableContainer = ({
    tab,
    datasets: d,
    channelId,
    geospatialSelection,
    isRunningOnSF,
    updateDataset,
}: IMainDatasetTableContainer) => {
    const { map, isLoaded } = useMap();
    const dispatch = useDispatch();
    const datasets = useMemo(() => getOrderedDatasetTable(d), [d]);
    const [dataset, setDataset] = useState<MapContextDataset>(datasets[0]);
    const tableId = dataset.dataSource.tableId || undefined;

    const dggsIndexName = pipe(
        dataset.dataSource.geographyColumn,
        O.fromNullable,
        O.fold(
            () => "DGGS_INDEX",
            (col) => `DGGS_INDEX_${replace(col, /[^\w\s]/gi, "_")}`
        )
    );

    const onGoToClick = (rows: AnyResponse["data"]) => {
        onFlyToMapFeature({
            rows,
            map,
            isLoaded,
            tableId,
            geographyColumn: dataset.dataSource.geographyColumn || undefined,
            datasetId: dataset.dataSource.id,
            onError: () => {
                dispatch(
                    toasterActions.openToast({
                        open: true,
                        title: `Cannot fly to point. Longitude & Latitude coordinates or Table Id are missing for dataset #${dataset.dataSource.label || dataset.dataSource.tableName}`,
                        autoHideDuration: 5000,
                        severity: Severity.error,
                    })
                );
            },
        });
    };

    const onDatasetUpdate = (input: {
        currentDataset: MapContextDataset;
        i: Partial<{
            filters: FilterCriteriaDatasetItem[];
            logicOperator: LogicOperator;
            selectedRows: Omit<DatasetGeometryPoint, "dataSourceId">[];
            pinnedRows: Omit<DatasetGeometryPoint, "dataSourceId">[];
        }>;
    }) => {
        const { currentDataset, i } = input;

        const filters: Partial<MapContextDatasetFilter | undefined> = omitBy(
            {
                logicOperator: i.logicOperator,
                filters: i.filters
                    ? pipe(
                          i.filters,
                          A.map((f) => ({
                              ...f,
                              id: uuid(),
                          }))
                      )
                    : undefined,
            },
            isNil
        );
        const params = omitBy(
            {
                selectedRows: i.selectedRows
                    ? pipe(
                          i.selectedRows,
                          A.map((r) => ({
                              ...r,
                              dataSourceId: currentDataset.dataSource.id,
                              mapTemplateDatasetId:
                                  currentDataset.mapTemplateDataset?.id,
                          }))
                      )
                    : undefined,
                pinnedRows: i.pinnedRows
                    ? pipe(
                          i.pinnedRows,
                          A.map((r) => ({
                              ...r,
                              dataSourceId: currentDataset.dataSource.id,
                              mapTemplateDatasetId:
                                  currentDataset.mapTemplateDataset?.id,
                          }))
                      )
                    : undefined,
            },
            isNil
        );

        updateDataset({
            dataSourceId: currentDataset.dataSource.id,
            dataset: {
                filters,
                ...params,
            },
        });

        if (params.selectedRows && map.current) {
            setTimeout(() => {
                setSelectedRowPulsingDot({
                    action: "add",
                    datasets,
                    map,
                    isLoaded,
                });
            }, 200);
        }

        if (isEqual(currentDataset.dataSource.id, dataset.dataSource.id)) {
            setDataset((p) => ({
                ...p,
                filters: {
                    logicOperator:
                        filters.logicOperator ||
                        dataset.filters?.logicOperator ||
                        DEFAULT_LOGIC_OPERATOR,
                    filters: filters.filters || dataset.filters?.filters || [],
                },
                ...params,
            }));
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const currentDataset = datasets.find((d) =>
            isEqual(d.dataSource.id, dataset.dataSource.id)
        );

        if (currentDataset) {
            setDataset(currentDataset);
        }
    }, [datasets]);

    return (
        <FlexScrollAreaContainer
            sx={{
                paddingX: 4,
            }}
        >
            <HorizontalScroller>
                <TabGroup value={dataset.dataSource.id}>
                    {pipe(
                        datasets,
                        A.map((d) => {
                            const filtersCount = pipe(
                                d.filters?.filters,
                                O.fromNullable,
                                O.fold(
                                    () => 0,
                                    (filters) => filters.length
                                )
                            );

                            return (
                                <Tab
                                    key={d.dataSource.id}
                                    value={d.dataSource.id}
                                    onClick={() => {
                                        setDataset(d);
                                    }}
                                    endNode={
                                        isEqual(filtersCount, 0) ? undefined : (
                                            <BadgeDot color="primary">
                                                {filtersCount}
                                            </BadgeDot>
                                        )
                                    }
                                >
                                    {d.dataSource.label ??
                                        d.dataSource.tableName}
                                </Tab>
                            );
                        })
                    )}
                </TabGroup>
            </HorizontalScroller>
            <FlexScrollArea
                flexDirection="column"
                gap={4}
                height={"100%"}
                width={"100%"}
                sx={{
                    paddingY: 3,
                }}
            >
                <DatasetTableContainer
                    mainTab={tab}
                    data={dataset}
                    channelId={channelId}
                    tableId={tableId}
                    geospatialSelection={geospatialSelection}
                    type="data"
                    isRunningOnSF={isRunningOnSF}
                    onGoToClick={onGoToClick}
                    dggsIndexName={dggsIndexName}
                    updateDataset={(filters) =>
                        onDatasetUpdate({
                            currentDataset: dataset,
                            i: filters,
                        })
                    }
                />
            </FlexScrollArea>
        </FlexScrollAreaContainer>
    );
};
