import {
    DatabaseType,
    DatasetConfiguration,
    DatasetStyles,
    DbColumnType,
    FilterCriteriaDatasetItem,
    FilterData,
    FilterObject,
    FilterType,
    LogicOperator,
    SavedViewDataset,
    SavedViewFilters,
    SchemaRow,
    ShapeStyle,
    WhereOperator,
} from "@biggeo/bg-server-lib/datascape-ai";
import { GridColDef } from "@biggeo/bg-ui";
import {
    WithPartialValues,
    WithRequiredProperty,
    areValuesEmpty,
    isObjectNotEmpty,
} from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

import { Either } from "fp-ts/lib/Either";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import orderBy from "lodash/orderBy";
import some from "lodash/some";
import uniqWith from "lodash/uniqWith";
import uuid from "react-uuid";
import { match } from "ts-pattern";
import Zod from "zod";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import { heatmapSwatchOptions } from "../../../common/components/HeatMapColorMapper";
import { levelSetPercentiles } from "../../../utils/variables";
import {
    MapAction,
    MapContextDataset,
    MapContextFilter,
} from "../../mapbox/context";
import {
    getDefaultDatasetConfiguration,
    getMultiFilters,
} from "../../mapbox/context/context-utils";
import {
    DEFAULT_SHAPE_COLOR,
    DEFAULT_SHAPE_OPACITY,
} from "../../mapbox/hooks/style-hooks";
import { setDatasetVisibility } from "../../mapbox/utils/data-layers-utils";
import { handleFilterLayers } from "../../mapbox/utils/filtered-data-layers-utils";
import { getHeatmapFormattedValue } from "../../utils/style-utils";
import { getDefaultDatasetConfigurationOptions } from "../../utils/utils";

// ███████╗███╗   ██╗██╗   ██╗███╗   ███╗
// ██╔════╝████╗  ██║██║   ██║████╗ ████║
// █████╗  ██╔██╗ ██║██║   ██║██╔████╔██║
// ██╔══╝  ██║╚██╗██║██║   ██║██║╚██╔╝██║
// ███████╗██║ ╚████║╚██████╔╝██║ ╚═╝ ██║
// ╚══════╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝     ╚═╝

export enum MapFilterCriteriaSection {
    details = "details",
    filterCriteria = "filterCriteria",
    styles = "styles",
}

export enum FilterCriteriaDataAggregationDensity {
    heatmap = "heatmap",
    cluster = "cluster",
}

//  ██████╗ ██████╗ ███╗   ██╗███████╗████████╗ █████╗ ███╗   ██╗████████╗
// ██╔════╝██╔═══██╗████╗  ██║██╔════╝╚══██╔══╝██╔══██╗████╗  ██║╚══██╔══╝
// ██║     ██║   ██║██╔██╗ ██║███████╗   ██║   ███████║██╔██╗ ██║   ██║
// ██║     ██║   ██║██║╚██╗██║╚════██║   ██║   ██╔══██║██║╚██╗██║   ██║
// ╚██████╗╚██████╔╝██║ ╚████║███████║   ██║   ██║  ██║██║ ╚████║   ██║
//  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═══╝   ╚═╝

export const FILTERED_DATA_TABLE_LIMIT = 20;
export const VIEW_DATASET_TABLE_LIMIT = 20;

export const DEFAULT_LOGIC_OPERATOR = LogicOperator.and;

export const DEFAULT_SHAPE_COLOR_OBJECT = {
    color: DEFAULT_SHAPE_COLOR,
    opacity: DEFAULT_SHAPE_OPACITY,
};

export const mapFilterCriteriaOperators: MapFilterCriteriaOperators = {
    [FilterType.BooleanType]: [
        WhereOperator.equals,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
    [FilterType.StringType]: [
        WhereOperator.equals,
        WhereOperator.contains,
        WhereOperator.startsWith,
        WhereOperator.endsWith,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
    [FilterType.NumberType]: [
        WhereOperator.eq,
        WhereOperator.neq,
        WhereOperator.gt,
        WhereOperator.gte,
        WhereOperator.lt,
        WhereOperator.lte,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
    [FilterType.DateType]: [
        WhereOperator.eq,
        WhereOperator.neq,
        WhereOperator.gt,
        WhereOperator.gte,
        WhereOperator.lt,
        WhereOperator.lte,
        WhereOperator.isEmpty,
        WhereOperator.isNotEmpty,
    ],
};

// ████████╗██╗   ██╗██████╗ ███████╗
// ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝
//    ██║    ╚████╔╝ ██████╔╝█████╗
//    ██║     ╚██╔╝  ██╔═══╝ ██╔══╝
//    ██║      ██║   ██║     ███████╗
//    ╚═╝      ╚═╝   ╚═╝     ╚══════╝

export type MapFilterCriteriaForm = {
    details: MapFilterCriteriaDetails;
    filterCriteria: MapFilterCriteriaDataset[];
    styles: Partial<MapFilterCriteriaStyle>;
};

export type MapFilterCriteriaDetails = {
    readonly name: string;
    readonly description?: string;
};

export type MapFilterCriteriaDataset = {
    readonly mapTemplateDatasetId: number;
    readonly dataSourceId: string;
    readonly label: string;
    readonly collection: string;
    readonly filters: Partial<FilterCriteriaDatasetItem>[];
    readonly logicOperator?: LogicOperator;
    readonly configuration?: DatasetConfiguration;
};

export type MapFilterCriteriaStyle = Omit<DatasetStyles, "dataAggregation"> & {
    dataAggregation: MapFilterCriteriaStyleDataAggregation;
};

export type MapFilterCriteriaStyleDataAggregation = Partial<{
    heatmap: ColorSwatchOption;
    cluster: ColorSwatchOption; // TODO
}>;

export type MapFilterCriteriaOperators = {
    [T in FilterType]: Array<WhereOperator>;
};

// ███████╗ ██████╗██╗  ██╗███████╗███╗   ███╗ █████╗
// ██╔════╝██╔════╝██║  ██║██╔════╝████╗ ████║██╔══██╗
// ███████╗██║     ███████║█████╗  ██╔████╔██║███████║
// ╚════██║██║     ██╔══██║██╔══╝  ██║╚██╔╝██║██╔══██║
// ███████║╚██████╗██║  ██║███████╗██║ ╚═╝ ██║██║  ██║
// ╚══════╝ ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝╚═╝  ╚═╝

export const mapFilterCriteriaSchema = Zod.object({
    details: Zod.object({
        name: Zod.string(),
        description: Zod.string().optional(),
    }),
    filterCriteria: Zod.array(
        Zod.object({
            mapTemplateDatasetId: Zod.number(),
            dataSourceId: Zod.string(),
            label: Zod.string(),
            collection: Zod.string(),
            logicOperator: Zod.nativeEnum(LogicOperator).optional(),
            filters: Zod.array(
                Zod.object({
                    column: Zod.string(),
                    type: Zod.nativeEnum(FilterType),
                    operator: Zod.nativeEnum(WhereOperator),
                    value: Zod.string(),
                })
            ),
        })
    ).nonempty(),
    styles: Zod.object({
        shape: Zod.nativeEnum(ShapeStyle).optional(),
        fill: Zod.object({
            color: Zod.string().optional(),
            opacity: Zod.number().optional(),
        }).optional(),
        stroke: Zod.object({
            color: Zod.string().optional(),
            opacity: Zod.number().optional(),
        }).optional(),
        customMarker: Zod.string().optional(),
        dataAggregation: Zod.object({
            heatmap: Zod.object({
                id: Zod.union([Zod.number(), Zod.string()]),
                swatch: Zod.array(
                    Zod.object({
                        range: Zod.number(),
                        color: Zod.string(),
                    })
                ),
            }).optional(),
            cluster: Zod.object({
                id: Zod.union([Zod.number(), Zod.string()]),
                swatch: Zod.array(
                    Zod.object({
                        range: Zod.number(),
                        color: Zod.string(),
                    })
                ),
            }).optional(),
        }).optional(),
    }).optional(),
});

// ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗
// ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗
// ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝
// ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗
// ██║  ██║███████╗███████╗██║     ███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝

export const mapFilterCriteriaSign = ({
    operator,
    verbose = false,
}: { operator: WhereOperator; verbose?: boolean }): string =>
    match(operator)
        .with(WhereOperator.eq, () => (verbose ? "Equals" : "="))
        .with(WhereOperator.neq, () => (verbose ? "Not Equals" : "!="))
        .with(WhereOperator.gt, () => (verbose ? "Greater Than" : ">"))
        .with(WhereOperator.gte, () =>
            verbose ? "Greater Than Or Equals" : ">="
        )
        .with(WhereOperator.lt, () => (verbose ? "Less Than" : "<"))
        .with(WhereOperator.lte, () => (verbose ? "Less Than Or Equals" : "<="))
        .with(WhereOperator.isEmpty, () => "Is Empty")
        .with(WhereOperator.isNotEmpty, () => "Is Not Empty")
        .with(WhereOperator.startsWith, () => "Starts With")
        .with(WhereOperator.endsWith, () => "Ends With")
        .with(WhereOperator.equals, () => "Equals")
        .otherwise(() => "Contains");

export const getFilterCriteriaOperators = (type: FilterType): WhereOperator[] =>
    mapFilterCriteriaOperators[type];

export const mapColumnType = (type: DbColumnType): FilterType =>
    match(type)
        .with(DbColumnType.Boolean, () => FilterType.BooleanType)
        .with(DbColumnType.Date, () => FilterType.DateType)
        .with(DbColumnType.Number, () => FilterType.NumberType)
        .otherwise(() => FilterType.StringType);

export const mapFilterData = (type: FilterType, value?: string): FilterData => {
    return {
        ...(isEqual(type, FilterType.BooleanType) && {
            booleanData: value ? value === "true" : null,
        }),
        ...(isEqual(type, FilterType.DateType) && {
            dateData: value ? new Date(value) : null,
        }),
        ...(isEqual(type, FilterType.NumberType) && {
            numberData: value ? Number.parseInt(value) : null,
        }),
        ...(isEqual(type, FilterType.StringType) && {
            stringData: value ? value : null,
        }),
    };
};

export const mapFilterValue = (
    type: FilterType,
    data: FilterData
): string | undefined => {
    return match(type)
        .with(FilterType.BooleanType, () =>
            data.booleanData ? data.booleanData.toString() : undefined
        )
        .with(FilterType.DateType, () =>
            data.dateData ? data.dateData.toString() : undefined
        )
        .with(FilterType.NumberType, () =>
            data.numberData ? data.numberData.toString() : undefined
        )
        .with(FilterType.StringType, () =>
            data.stringData ? data.stringData.toString() : undefined
        )
        .exhaustive();
};

export const getFilterObjectFromFilter = (
    filterCriteria: MapFilterCriteriaDataset,
    isRunningOnSF: boolean
): FilterObject => {
    const filters = pipe(
        filterCriteria.filters,
        compact,
        A.map((item) => {
            const e = item as FilterCriteriaDatasetItem;

            return {
                column: e.column,
                operator: e.operator,
                type: e.type,
                data: mapFilterData(e.type, e.value || undefined),
            };
        }),
        A.map((item) => (areValuesEmpty(item) ? undefined : item)),
        compact
    );

    return {
        collection: filterCriteria.collection,
        databaseId: filterCriteria.dataSourceId,
        databaseType: DatabaseType.point,
        filters,
        logicOperator: filterCriteria.logicOperator ?? DEFAULT_LOGIC_OPERATOR,
        isPreview: false,
        options:
            filterCriteria.configuration?.options ||
            getDefaultDatasetConfigurationOptions(isRunningOnSF),
    };
};

export const mapToMultifilterType = (
    filter: MapFilterCriteriaForm,
    isRunningOnSF: boolean
): FilterObject[] => {
    return pipe(
        filter.filterCriteria,
        A.map((d) => getFilterObjectFromFilter(d, isRunningOnSF))
    );
};

export const mapFilterCriteriaInitialValues = (
    filter?: MapContextFilter
): WithPartialValues<MapFilterCriteriaForm> => {
    // Defaults to primary
    const color = DEFAULT_SHAPE_COLOR;
    const heatmap = heatmapSwatchOptions[1];

    return {
        details: {
            name: filter ? filter.details.name : undefined,
            description: filter ? filter.details.description : undefined,
        },
        filterCriteria: filter?.filterCriteria || [],
        styles: {
            shape: filter ? filter.styles.shape : ShapeStyle.oval,
            fill: filter
                ? filter.styles.fill
                : color
                  ? {
                        color,
                        opacity: 0.9,
                    }
                  : undefined,
            stroke: filter
                ? filter.styles.stroke
                : color
                  ? {
                        color,
                        opacity: 0.9,
                    }
                  : undefined,
            customMarker: filter ? filter.styles.customMarker : undefined,
            dataAggregation: {
                heatmap: filter?.styles.dataAggregation?.heatmap ?? heatmap,
                cluster: filter
                    ? filter.styles.dataAggregation?.cluster
                    : undefined,
            },
        },
    };
};

export const getUpdatedMultifiters = (input: {
    filter: MapContextFilter;
    selectedDatasets: MapContextDataset[];
    multiFilters: FilterObject[];
}) => {
    const { filter, selectedDatasets, multiFilters } = input;

    const datasets = pipe(
        filter.filterCriteria,
        A.map((f) => f.dataSourceId)
    );

    return pipe(
        selectedDatasets,
        A.map((f) => {
            const found = multiFilters.find(
                (m) => m.databaseId === f.dataSource.id
            );
            const defaultFilters = found?.filters || [];

            return {
                collection: f.dataSource.collectionName,
                databaseId: f.dataSource.id,
                databaseType: DatabaseType.point,
                filters: datasets.includes(f.dataSource.id)
                    ? []
                    : defaultFilters,
                logicOperator: LogicOperator.and,
            };
        })
    );
};

export const getSnowflakeTableColumns = (
    columns: SchemaRow[]
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
): GridColDef<any>[] => {
    return columns.reduce((acc, c) => {
        if (acc.some((col) => col.field === c.columnName)) return acc;
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const v: GridColDef<any> = {
            type: match(c.dataType)
                .with(DbColumnType.String, () => "string")
                .with(DbColumnType.Boolean, () => "boolean")
                .with(DbColumnType.Number, () => "number")
                .with(DbColumnType.Date, () => "date")
                .otherwise(() => "string"),
            field: c.columnName,
            headerName: c.columnName,
            filterable: c.columnName !== "ID",
            valueGetter: (params) => {
                if (c.dataType === DbColumnType.Date) {
                    return new Date(params.value);
                }

                if (c.dataType === DbColumnType.Geospatial) {
                    return JSON.stringify(params.value);
                }
                return params.value;
            },
            sortable: false,
            headerAlign: "left",
            align: "left",
            minWidth:
                c.dataType === DbColumnType.Geospatial
                    ? 300
                    : match(c.columnName.length * 13)
                          .when(
                              (v) => v > 200,
                              () => 200
                          )
                          .when(
                              (v) => v < 50,
                              () => 50
                          )
                          .otherwise((v) => v),
        };
        return acc.concat(v);
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    }, [] as GridColDef<any>[]);
};

export const updateDatasetsVisibility = (input: {
    visibility: "visible" | "none";
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    datasets: MapContextDataset[];
}) => {
    const { datasets, map, isLoaded, visibility } = input;

    pipe(
        datasets,
        A.map((dataset) => {
            if (map.current) {
                setDatasetVisibility({
                    map: map.current,
                    prefix: dataset.dataSource.id,
                    levelSets: [],
                    visibility,
                    isLoaded,
                    styles: dataset.mapTemplateDataset?.styles || undefined,
                });
            }
        })
    );
};

export const setFilterCriteria = ({
    dispatch,
    filters,
    selectedDatasets,
    map,
    isLoaded,
    setPreviewDataset,
}: {
    dispatch?: React.Dispatch<MapAction>;
    filters: MapContextFilter[];
    selectedDatasets: MapContextDataset[];
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    setPreviewDataset: React.Dispatch<
        React.SetStateAction<
            | Either<
                  {
                      marketplaceDatasetId: string;
                  },
                  {
                      dataSourceId: string;
                  }
              >
            | undefined
        >
    >;
}) => {
    const previewedFilter = filters.find((f) => isEqual(f.preview, true));

    const editedFilter = filters.find(
        (f) => isEqual(f.preview, true) && isEqual(f.selected, true)
    );

    const canAddFilterCriteria = !isEmpty(
        pipe(
            selectedDatasets,
            A.filter((d) => isEqual(d.dataSource.isPreview, false))
        )
    );
    const viewedFilter = pipe(
        filters,
        A.findFirst((f) => isEqual(f.viewed, true)),
        O.fold(
            () => undefined,
            (f) => f
        )
    );
    const canOpenFilterCriteria = Boolean(previewedFilter);

    const handleFilterCriteriaSidebar = () => {
        if (!previewedFilter && !editedFilter) {
            addFilter({
                filter: {
                    details: { name: "", description: undefined },
                    filterCriteria: [],
                    styles: {
                        shape: ShapeStyle.oval,
                        fill: DEFAULT_SHAPE_COLOR_OBJECT,
                        stroke: DEFAULT_SHAPE_COLOR_OBJECT,
                        dataAggregation: {
                            heatmap: heatmapSwatchOptions[1],
                        },
                    },
                },
                isPreview: true,
            });
            setPreviewDataset(undefined);
        }

        if (!editedFilter && previewedFilter) {
            removeFilter(previewedFilter.id);
        }
    };

    const setFilters = (f: MapContextFilter[]) => {
        dispatch?.({
            type: "SET_FILTERS",
            values: f,
        });
    };

    const addFilter = ({
        filter,
        isPreview,
    }: {
        filter: MapFilterCriteriaForm;
        isPreview: boolean;
    }) => {
        const id = uuid();

        dispatch?.({
            type: "ADD_FILTER",
            values: {
                ...filter,
                id,
                visible: true,
                selected: false,
                disabled: false,
                preview: isPreview,
            },
        });
    };

    const updateFilter = (
        filter: WithRequiredProperty<Partial<MapContextFilter>, "id">
    ) => {
        dispatch?.({
            type: "UPDATE_FILTER",
            values: {
                id: filter.id,
                ...omitBy(filter, isNil),
                viewed: filter.viewed,
            },
        });
    };

    const updatePreviewFilter = (filter: Partial<MapContextFilter>) => {
        if (previewedFilter) {
            updateFilter({
                id: previewedFilter.id,
                ...filter,
            });
        }
    };

    const toggleFilterVisibility = (id: string, visible: boolean) => {
        updateFilter({ id, visible });
    };

    const removeFilter = (id: string) => {
        const updatedFilters = filters.filter((f) => f.id !== id);

        handleFilterLayers({
            action: "remove",
            map,
            isLoaded,
            suffix: id,
        });

        dispatch?.({
            type: "REMOVE_FILTER",
            values: id,
        });

        if (isEmpty(updatedFilters)) {
            updateDatasetsVisibility({
                visibility: "visible",
                map,
                isLoaded,
                datasets: selectedDatasets,
            });
        }
    };

    const clearFilters = (
        map: React.MutableRefObject<mapboxgl.Map | null>,
        isLoaded: boolean
    ) => {
        dispatch?.({
            type: "CLEAR_FILTERS",
        });

        filters.map((filter) =>
            handleFilterLayers({
                action: "remove",
                map,
                isLoaded,
                suffix: filter.id,
            })
        );
    };

    const editFilter = (id: string) => {
        dispatch?.({
            type: "EDIT_FILTER",
            values: id,
        });
    };

    const saveFilter = (id: string) => {
        dispatch?.({
            type: "SAVE_FILTER",
            values: id,
        });
        handleFilterCriteriaSidebar();
    };

    const onAddFilter = () => {
        dispatch?.({
            type: "RESET_FILTERS",
            values: {
                disable: true,
            },
        });
        handleFilterCriteriaSidebar();
    };

    const handleFilterCriteria = (reset?: boolean) => {
        handleFilterCriteriaSidebar();
        if (isEqual(reset, true)) {
            dispatch?.({
                type: "RESET_FILTERS",
                values: {
                    disable: false,
                },
            });
        }
    };

    return {
        canOpenFilterCriteria,
        canAddFilterCriteria,
        viewedFilter,
        setFilters,
        addFilter,
        updateFilter,
        toggleFilterVisibility,
        removeFilter,
        clearFilters,
        editFilter,
        saveFilter,
        onAddFilter,
        handleFilterCriteria,
        updatePreviewFilter,
        previewedFilter,
    };
};

export const handleFilteredDatasets = (input: {
    dataset: MapContextDataset;
    filters: MapContextFilter[];
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    dispatch?: React.Dispatch<MapAction>;
    openModal: ({
        onClickClearFilters,
        onClickKeepFilters,
        filters,
    }: {
        onClickClearFilters: () => void;
        onClickKeepFilters: () => void;
        filters: string[];
    }) => void;
    closeModal: () => void;
    unselectDataset: () => void;
}) => {
    const {
        dataset,
        filters,
        map,
        isLoaded,
        dispatch,
        openModal,
        closeModal,
        unselectDataset,
    } = input;

    const linkedFilters = pipe(
        filters,
        A.filter((filter) =>
            some(
                filter.filterCriteria,
                (c) => c.dataSourceId === dataset.dataSource.id
            )
        )
    );

    if (isEmpty(linkedFilters)) {
        unselectDataset();
    } else {
        openModal({
            filters: pipe(
                linkedFilters,
                A.map((f) => f.details.name)
            ),
            onClickKeepFilters: () => closeModal(),
            onClickClearFilters: () => {
                linkedFilters.map((filter) => {
                    handleFilterLayers({
                        action: "remove",
                        map,
                        isLoaded,
                        suffix: filter.id,
                    });

                    dispatch?.({
                        type: "REMOVE_FILTER",
                        values: filter.id,
                    });
                });

                closeModal();
            },
        });
    }
};

export const getOrderedDatasetTable = (
    datasets: MapContextDataset[]
): MapContextDataset[] => {
    return orderBy(datasets, (d) => d.tableOrder || 0, ["asc"]);
};

// ███████╗ █████╗ ██╗   ██╗███████╗██████╗     ██╗   ██╗██╗███████╗██╗    ██╗
// ██╔════╝██╔══██╗██║   ██║██╔════╝██╔══██╗    ██║   ██║██║██╔════╝██║    ██║
// ███████╗███████║██║   ██║█████╗  ██║  ██║    ██║   ██║██║█████╗  ██║ █╗ ██║
// ╚════██║██╔══██║╚██╗ ██╔╝██╔══╝  ██║  ██║    ╚██╗ ██╔╝██║██╔══╝  ██║███╗██║
// ███████║██║  ██║ ╚████╔╝ ███████╗██████╔╝     ╚████╔╝ ██║███████╗╚███╔███╔╝
// ╚══════╝╚═╝  ╚═╝  ╚═══╝  ╚══════╝╚═════╝       ╚═══╝  ╚═╝╚══════╝ ╚══╝╚══╝

export const getSavedViewFilters = (
    filters: MapContextFilter[],
    isRunningOnSF: boolean
): SavedViewFilters[] => {
    const multiFilters = getMultiFilters();

    const extractedMultiFilters = pipe(
        filters,
        A.flatMap((filter) => filter.filterCriteria),
        A.map((d) => getFilterObjectFromFilter(d, isRunningOnSF)),
        (f) => uniqWith(f, (x, y) => isEqual(x.databaseId, y.databaseId))
    );

    if (isEmpty(filters)) {
        return pipe(
            multiFilters,
            A.map((m) => ({
                ...omit(m, ["options"]),
                filterCriteria: undefined,
                configuration: {
                    showLevelSets: true,
                    showPoints: true,
                    options: m.options
                        ? {
                              pointMaxGeomsPerCell:
                                  m.options.pointMaxGeomsPerCell,
                              pointResolutionOffset:
                                  m.options.pointResolutionOffset,
                              polygonMaxGeomsPerCell:
                                  m.options.polygonMaxGeomsPerCell,
                              polygonResolutionOffset:
                                  m.options.polygonResolutionOffset,
                              levelSets:
                                  m.options.levelSets ?? levelSetPercentiles,
                          }
                        : getDefaultDatasetConfigurationOptions(isRunningOnSF),
                },
            }))
        );
    }

    return pipe(
        extractedMultiFilters,
        A.map((multifilter) => {
            const linkedFilters = pipe(
                filters,
                A.filter((f) =>
                    some(f.filterCriteria, (c) =>
                        isEqual(c.dataSourceId, multifilter.databaseId)
                    )
                )
            );

            const filterCriteria = pipe(
                linkedFilters,
                O.fromPredicate((f) => !isEmpty(f)),
                O.fold(
                    () => undefined,
                    (f) =>
                        pipe(
                            f,
                            A.map((filter) => ({
                                id: filter.id,
                                name: filter.details.name,
                                description: filter.details.description,
                                visible: filter.visible,
                                selected: filter.selected,
                                disabled: filter.disabled,
                                filters: pipe(
                                    filter.filterCriteria,
                                    A.map((i) => ({
                                        ...i,
                                        filters: pipe(
                                            i.filters,
                                            A.map((item) =>
                                                isObjectNotEmpty(item)
                                                    ? item
                                                    : undefined
                                            ),
                                            compact
                                        ),
                                        configuration:
                                            i.configuration || undefined,
                                    }))
                                ),
                                shape: filter.styles.shape,
                                fill: filter.styles.fill,
                                stroke: filter.styles.stroke,
                                customMarker: filter.styles.customMarker,
                                dataAggregation: {
                                    heatmap: filter.styles.dataAggregation
                                        ?.heatmap
                                        ? {
                                              ...filter.styles.dataAggregation
                                                  .heatmap,
                                              id: filter.styles.dataAggregation.heatmap.id.toString(),
                                          }
                                        : undefined,
                                },
                            }))
                        )
                )
            );

            return {
                ...omit(multifilter, ["options"]),
                configuration: multifilter.options
                    ? {
                          showLevelSets: true,
                          showPoints: true,
                          options:
                              multifilter.options ??
                              getDefaultDatasetConfigurationOptions(
                                  isRunningOnSF
                              ),
                      }
                    : getDefaultDatasetConfiguration(isRunningOnSF),
                filterCriteria,
            };
        })
    );
};

export const getContextFilters = (
    savedViewFilters: SavedViewFilters[],
    isRunningOnSF: boolean
): MapContextFilter[] | undefined => {
    const filters = pipe(
        savedViewFilters,
        A.flatMap((filter) => filter.filterCriteria || []),
        A.map((filterCriteria) => {
            const heatmap = getHeatmapFormattedValue(
                filterCriteria.dataAggregation?.heatmap || undefined
            );

            return {
                id: filterCriteria.id,
                visible: filterCriteria.visible,
                selected: filterCriteria.selected,
                disabled: filterCriteria.disabled,
                viewed: undefined,
                loading: false,
                preview: false,
                details: {
                    name: filterCriteria.name,
                    description: filterCriteria.description || undefined,
                },
                filterCriteria: pipe(
                    filterCriteria.filters,
                    A.map((i) => ({
                        ...i,
                        filters: pipe(
                            i.filters,
                            A.map((item) => ({
                                ...item,
                                value: item.value || undefined,
                            }))
                        ),
                        logicOperator: i.logicOperator || undefined,
                        configuration: i.configuration
                            ? {
                                  showLevelSets: i.configuration.showLevelSets,
                                  showPoints: i.configuration.showPoints,
                                  options:
                                      i.configuration.options ??
                                      getDefaultDatasetConfigurationOptions(
                                          isRunningOnSF
                                      ),
                              }
                            : undefined,
                    }))
                ),
                styles: {
                    shape: filterCriteria.shape || ShapeStyle.oval,
                    fill: filterCriteria.fill
                        ? {
                              color: filterCriteria.fill.color || undefined,
                              opacity: filterCriteria.fill.opacity || undefined,
                          }
                        : undefined,
                    stroke: filterCriteria.stroke
                        ? {
                              color: filterCriteria.stroke.color || undefined,
                              opacity:
                                  filterCriteria.stroke.opacity || undefined,
                          }
                        : undefined,
                    customMarker: filterCriteria.customMarker || undefined,
                    dataAggregation: filterCriteria.dataAggregation
                        ? {
                              heatmap,
                          }
                        : undefined,
                } as MapFilterCriteriaStyle,
            };
        }),
        (f) => uniqWith(f, (x, y) => isEqual(x.id, y.id))
    );

    return isEmpty(filters) ? undefined : filters;
};

export const getContextDatasets = (i: {
    savedViewDatasets: SavedViewDataset[];
    savedViewFilters?: SavedViewFilters[] | null;
    mapTemplateId: number;
    isRunningOnSF: boolean;
}) => {
    const {
        savedViewDatasets,
        savedViewFilters,
        mapTemplateId,
        isRunningOnSF,
    } = i;

    return pipe(
        savedViewDatasets,
        A.map((d) => {
            const datasetFilters = savedViewFilters?.find(
                (f) => f.databaseId === d.id
            );
            const filters = datasetFilters?.filters
                ? pipe(
                      datasetFilters.filters,
                      A.map((f) => ({
                          id: f.id ? f.id : uuid(),
                          column: f.column,
                          operator: f.operator,
                          type: f.type,
                          value: mapFilterValue(f.type, f.data),
                      }))
                  )
                : [];

            return d.dataSource
                ? {
                      dataSource: d.dataSource,
                      mapTemplateDataset: d.mapTemplateDatasetId
                          ? {
                                id: d.mapTemplateDatasetId,
                                fkMapTemplateId: mapTemplateId,
                                fkDataSourceId: d.dataSource.id,
                                mapUse: true,
                                enable: true,
                                styles: d.styles || undefined,
                            }
                          : undefined,
                      isSelected: d.selected,
                      isVisible: d.selected,
                      isGettingStyled: false,
                      isLegendOpen: false,
                      isTableViewed: d.selected,
                      configuration: {
                          isOpen: false,
                          showLevelSets: d.configuration?.showLevelSets ?? true,
                          showPoints: d.configuration?.showPoints ?? true,
                          options: d.configuration?.options
                              ? {
                                    pointMaxGeomsPerCell:
                                        d.configuration?.options
                                            .pointMaxGeomsPerCell,
                                    pointResolutionOffset:
                                        d.configuration?.options
                                            .pointResolutionOffset,
                                    polygonMaxGeomsPerCell:
                                        d.configuration?.options
                                            .polygonMaxGeomsPerCell,
                                    polygonResolutionOffset:
                                        d.configuration?.options
                                            .polygonResolutionOffset,
                                    levelSets:
                                        d.configuration?.options.levelSets ??
                                        levelSetPercentiles,
                                }
                              : getDefaultDatasetConfigurationOptions(
                                    isRunningOnSF
                                ),
                      },
                      filters: {
                          logicOperator: DEFAULT_LOGIC_OPERATOR,
                          filters: filters,
                      },
                      selectedRows: d.selectedRows || [],
                      pinnedRows: d.pinnedRows || [],
                  }
                : undefined;
        }),
        compact
    );
};
