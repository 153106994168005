import { ReqOptions } from "@biggeo/bg-server-lib/datascape-ai";
import { WithPartialValues } from "@biggeo/bg-utils";
import { useMemo } from "react";
import { match } from "ts-pattern";
import { MapContextDataset, useMap } from "../mapbox/context";
import { setDatasetVisibility } from "../mapbox/utils/data-layers-utils";
import DatasetConfigurationView from "../views/DatasetConfigurationView";

export type DatasetConfigurationType = {
    maximumGeometries: { value: number; max: number };
    resolutionOffset: { value: number; max: number };
};
export interface IDatasetConfigurationContainer {
    readonly dataset: MapContextDataset;
    readonly isRunningOnSF: boolean;
    readonly updateDataset: (values: {
        dataSourceId: string;
        dataset: Partial<WithPartialValues<MapContextDataset>>;
    }) => void;
}

const DatasetConfigurationContainer = ({
    dataset,
    isRunningOnSF,
    updateDataset,
}: IDatasetConfigurationContainer) => {
    const { map, isLoaded } = useMap();

    const params: Record<"points" | "polygons", DatasetConfigurationType> & {
        maxAdditionalRefines: { readonly value: number };
    } = useMemo(() => {
        const options = dataset.configuration.options;

        return {
            points: {
                maximumGeometries: {
                    value: isRunningOnSF
                        ? options.pointMaxGeomsPerCell || 200
                        : options.pointMaxGeomsPerCell || 101,
                    max: isRunningOnSF ? 300 : 200,
                },
                resolutionOffset: {
                    value: options.pointResolutionOffset || 3,
                    max: isRunningOnSF ? 6 : 4,
                },
            },
            polygons: {
                maximumGeometries: {
                    value: isRunningOnSF
                        ? options.polygonMaxGeomsPerCell || 125
                        : options.polygonMaxGeomsPerCell || 75,
                    max: isRunningOnSF ? 300 : 200,
                },
                resolutionOffset: {
                    value: options.polygonResolutionOffset || 3,
                    max: isRunningOnSF ? 7 : 4,
                },
            },
            maxAdditionalRefines: { value: options.maxAdditionalRefines || 0 },
        };
    }, [dataset, isRunningOnSF]);

    const handleConfiguration = (input: Partial<ReqOptions>) => {
        updateDataset({
            dataSourceId: dataset.dataSource.id,
            dataset: {
                configuration: {
                    options: {
                        ...input,
                    },
                },
            },
        });
    };

    const handleVisibility = (
        category: "levelSets" | "points",
        show: boolean
    ) => {
        updateDataset({
            dataSourceId: dataset.dataSource.id,
            dataset: match(category)
                .with("points", () => ({
                    configuration: {
                        showPoints: show,
                    },
                }))
                .with("levelSets", () => ({
                    configuration: {
                        showLevelSets: show,
                    },
                }))
                .exhaustive(),
        });

        if (map.current && isLoaded) {
            setDatasetVisibility({
                category,
                map: map.current,
                isLoaded,
                prefix: dataset.dataSource.id,
                levelSets: [],
                visibility: show ? "visible" : "none",
                styles: dataset.mapTemplateDataset?.styles || undefined,
            });
        }
    };

    return (
        <DatasetConfigurationView
            onBack={() =>
                updateDataset({
                    dataSourceId: dataset.dataSource.id,
                    dataset: {
                        configuration: {
                            isOpen: false,
                        },
                    },
                })
            }
            handleVisibility={handleVisibility}
            params={params}
            handleConfiguration={handleConfiguration}
            dataset={dataset}
        />
    );
};

export default DatasetConfigurationContainer;
