import {
    VerifiedAccessKeyResponse,
    VerifiedAccessKeyStatus,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    Button,
    Divider,
    Grid,
    Radio,
    Stack,
    TextField,
    ThumbnailAvatar,
    Typography,
} from "@biggeo/bg-ui/lab";
import { CancelFilled, CheckCircle } from "@biggeo/bg-ui/lab/icons";
import { BillingEventName, productPrice } from "@biggeo/bg-utils";
import { isAppRunningOnSF } from "../../common/redux/hooks.ts";
import { BillingType } from "../containers/MarketplaceUpgradeContainer.tsx";
import { UpgradeInfo } from "./MarketplaceUpgradeForm.tsx";

export interface IMapTemplateFormView {
    readonly values: UpgradeInfo;
    readonly onChange: (i: Partial<UpgradeInfo>) => void;
    verifyAccessKey: (
        accessKey: string,
        onSuccess: (data: VerifiedAccessKeyResponse) => void
    ) => void;
    readonly billingType: BillingType;
    readonly setBillingType: (type: BillingType) => void;
}

const MarketplaceUpgradeFormView = ({
    values,
    onChange,
    verifyAccessKey,
    billingType,
    setBillingType,
}: IMapTemplateFormView) => {
    const price = productPrice[BillingEventName.BG_VELOCITY_CHARGE];
    const formattedValue = price.toLocaleString();

    const formatter = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
    const formattedSubTotal = formatter.format(price);

    const gst = price * 0.05;
    const formattedGST = formatter.format(gst);
    const total = price + gst;
    const formattedTotal = formatter.format(total);

    const handleSwitchBillingType = (type: BillingType) => {
        if (type === "Snowflake")
            onChange({
                accessKey: "",
                message: "",
                status: VerifiedAccessKeyStatus.Failed,
            });

        setBillingType(type);
    };

    return (
        <Stack gap={4}>
            <Grid
                container
                alignItems="center"
                gap={2}
                sx={{
                    padding: 3,
                    cursor: "pointer",
                    borderRadius: (theme) => theme.radius.xs3,
                    border: 1,
                    borderColor: (theme) => theme.palette.stroke[100],
                }}
            >
                <ThumbnailAvatar
                    src="https://biggeo.blob.core.windows.net/media/fdsa.png"
                    square
                    size="md"
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                />
                <Grid item xs minWidth={0}>
                    <Stack alignSelf="stretch">
                        <Typography
                            variant="body2"
                            fontWeight="bold"
                            color="background"
                            colorSet="main"
                            invertColors
                            truncate
                        >
                            Velocity
                        </Typography>
                        <Typography
                            variant="body3"
                            color="disabled"
                            colorSet="container"
                            invertColors
                            truncate
                        >
                            By BigGeo
                        </Typography>
                    </Stack>
                </Grid>
                <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="background"
                    colorSet="main"
                    invertColors
                >
                    ${formattedValue}/Mo
                </Typography>
            </Grid>
            <Stack>
                <Typography
                    variant="title1"
                    fontWeight="bold"
                    color="background"
                    colorSet="main"
                    invertColors
                >
                    Payment info
                </Typography>
                <Typography
                    variant="body2"
                    color="disabled"
                    colorSet="container"
                    invertColors
                >
                    Select your payment method
                </Typography>
            </Stack>
            <Stack gap={4}>
                <Grid
                    container
                    alignItems="center"
                    gap={2}
                    sx={{
                        padding: 3,
                        cursor: "pointer",
                        borderRadius: (theme) => theme.radius.xs3,
                        border: 1,
                        borderColor: (theme) => theme.palette.stroke[100],
                    }}
                    onClick={() => {
                        handleSwitchBillingType("AccessKey");
                    }}
                >
                    <Radio
                        size="xs"
                        checked={billingType === "AccessKey"}
                        onClick={() => {
                            handleSwitchBillingType("AccessKey");
                        }}
                    />
                    <ThumbnailAvatar
                        src="https://biggeo.blob.core.windows.net/media/fdsa.png"
                        size="md"
                        sx={{ justifyContent: "center", alignItems: "center" }}
                    />
                    <Grid item xs minWidth={0} alignSelf="center">
                        <Typography
                            variant="body3"
                            fontWeight="semibold"
                            color="background"
                            colorSet="main"
                            invertColors
                            truncate
                        >
                            Use License Key
                        </Typography>
                    </Grid>
                </Grid>
                {billingType === "AccessKey" && (
                    <Stack>
                        <TextField
                            value={values.accessKey || undefined}
                            placeholder="Enter Access Key"
                            slotProps={{
                                inputRoot: {
                                    sx: {
                                        padding: 4,
                                    },
                                },
                            }}
                            fullWidth
                            onChange={(_, v) => {
                                onChange({
                                    status: VerifiedAccessKeyStatus.Failed,
                                    message: "",
                                    accessKey: v === "" ? undefined : v,
                                });
                            }}
                            endNode={
                                <Grid sx={{ display: "flex", gap: 2 }}>
                                    <Divider
                                        orientation="vertical"
                                        color={300}
                                    />
                                    <Button
                                        variant="ghost"
                                        color="primary"
                                        disabled={!values.accessKey}
                                        onClick={() => {
                                            verifyAccessKey(
                                                values.accessKey,
                                                (res) =>
                                                    onChange({
                                                        status: res.status,
                                                        message: res.message,
                                                    })
                                            );
                                        }}
                                    >
                                        Check Key
                                    </Button>
                                </Grid>
                            }
                        />
                        {values.message && (
                            <Grid
                                sx={{ display: "flex" }}
                                alignItems="center"
                                gap={1}
                            >
                                {values.status ===
                                VerifiedAccessKeyStatus.Failed ? (
                                    <CancelFilled size="xxs" color={"error"} />
                                ) : (
                                    <CheckCircle size="xxs" color={"success"} />
                                )}

                                <Typography variant="body4">
                                    {values.message}
                                </Typography>
                            </Grid>
                        )}
                    </Stack>
                )}
                {isAppRunningOnSF() && (
                    <Grid
                        container
                        alignItems="center"
                        gap={2}
                        sx={{
                            padding: 3,
                            cursor: "pointer",
                            borderRadius: (theme) => theme.radius.xs3,
                            border: 1,
                            borderColor: (theme) => theme.palette.stroke[100],
                        }}
                        onClick={() => {
                            handleSwitchBillingType("Snowflake");
                        }}
                    >
                        <Radio
                            size="xs"
                            checked={billingType === "Snowflake"}
                            onClick={() => {
                                handleSwitchBillingType("Snowflake");
                            }}
                        />
                        <ThumbnailAvatar
                            src="https://biggeo.blob.core.windows.net/media/sf.png"
                            square
                            size="md"
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        />
                        <Grid item xs minWidth={0} alignSelf="center">
                            <Typography
                                variant="body3"
                                fontWeight="semibold"
                                color="background"
                                colorSet="main"
                                invertColors
                                truncate
                            >
                                Use Snowflake Credits
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Stack>
            {billingType === "Snowflake" && (
                <Stack>
                    <Grid
                        container
                        gap={4}
                        alignItems="center"
                        sx={{ paddingTop: 1, paddingBottom: 1 }}
                    >
                        <Grid item xs minWidth={0}>
                            <Typography
                                variant="body2"
                                fontWeight="regular"
                                truncate
                            >
                                Subtotal
                            </Typography>
                        </Grid>

                        <Typography variant="body3" fontWeight="bold">
                            ${formattedSubTotal}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        gap={4}
                        alignItems="center"
                        sx={{ paddingTop: 1, paddingBottom: 1 }}
                    >
                        <Grid item xs minWidth={0}>
                            <Typography
                                variant="body2"
                                fontWeight="regular"
                                truncate
                            >
                                GST
                            </Typography>
                        </Grid>

                        <Typography variant="body3" fontWeight="bold">
                            ${formattedGST}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        gap={4}
                        alignItems="center"
                        sx={{ paddingTop: 2, paddingBottom: 2 }}
                    >
                        <Grid item xs minWidth={0}>
                            <Typography
                                variant="body2"
                                fontWeight="bold"
                                truncate
                            >
                                Total
                            </Typography>
                        </Grid>

                        <Typography variant="body1" fontWeight="bold">
                            ${formattedTotal}
                        </Typography>
                    </Grid>
                </Stack>
            )}
        </Stack>
    );
};

export default MarketplaceUpgradeFormView;
