import { LogicOperator } from "@biggeo/bg-server-lib/datascape-ai";
import { Theme, useMediaQuery } from "@biggeo/bg-ui";
import {
    Button,
    Chip,
    DataGridPaginationProps,
    Divider,
    Dropdown,
    Grid,
    HorizontalScroller,
    MenuItem,
    OverflowingTypography,
    Stack,
    Switch,
    Tag,
    Typography,
    buttonClasses,
} from "@biggeo/bg-ui/lab";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { TableFilterChip } from "../../../common/components/TableFilterChip";
import { MapContextDatasetFilter } from "../../mapbox/context";
import { DatasetTableTab } from "../containers/DatasetTableContainer";
import { mapFilterCriteriaSign } from "../utils/utils";
import { DatasetTableProps } from "./DatasetTable";

type DatasetTableHeaderProps = {
    readonly onSaveCriteria?: (filters: MapContextDatasetFilter) => void;
    readonly isSaveCriteriaDisabled?: boolean;
    readonly onExport?: () => void;
    readonly isExportDisabled: boolean;
    readonly filters: MapContextDatasetFilter;
    readonly onFilterRemove: (id: string) => void;
    readonly onClearFilters: () => void;
    readonly isRemoveDisabled: boolean;
    readonly isClearFilterDisabled?: boolean;
    readonly onOperatorChange: (operator: LogicOperator) => void;
} & Pick<DatasetTableProps, "view" | "setView"> &
    Pick<DataGridPaginationProps, "showing">;

export const DataGridDivider = () => (
    <Stack alignItems="center">
        <Divider
            orientation="vertical"
            color={200}
            sx={{
                height: (theme) => theme.spacing(4),
            }}
        />
    </Stack>
);

export const DatasetTableHeader = ({
    onSaveCriteria,
    onExport,
    isExportDisabled,
    isSaveCriteriaDisabled = false,
    filters,
    onClearFilters,
    onFilterRemove,
    isRemoveDisabled,
    isClearFilterDisabled = false,
    onOperatorChange,
    view,
    setView,
    showing,
}: DatasetTableHeaderProps) => {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md")
    );
    const hasFilters = !isEmpty(filters.filters);

    return (
        <Stack
            gap={2}
            width="100%"
            sx={{
                padding: 2,
            }}
        >
            <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Stack flexDirection="row" gap={1}>
                    <Typography variant="body3">Showing</Typography>
                    <Typography variant="body3" fontWeight="bold">
                        {showing.current}
                    </Typography>
                    <Typography variant="body3">/</Typography>
                    <Typography variant="body3" fontWeight="bold">
                        {showing.all}
                    </Typography>
                    <Typography variant="body3">From</Typography>
                    <Typography variant="body3" fontWeight="bold">
                        {isEqual(view, DatasetTableTab.viewport)
                            ? "Map View"
                            : "All"}
                    </Typography>
                </Stack>
                <Stack
                    alignItems="center"
                    gap={2}
                    flexDirection={isMobile ? "column-reverse" : "row"}
                >
                    {onSaveCriteria && (
                        <Chip
                            rounded
                            density="dense"
                            variant="outlined"
                            color={
                                isSaveCriteriaDisabled ? "disabled" : "primary"
                            }
                            onClick={() => onSaveCriteria(filters)}
                            disabled={isSaveCriteriaDisabled}
                        >
                            Save Criteria
                        </Chip>
                    )}
                    {onExport && (
                        <Chip
                            rounded
                            density="dense"
                            variant="filled"
                            onClick={onExport}
                            color={isExportDisabled ? "disabled" : undefined}
                            disabled={isExportDisabled}
                        >
                            Export
                        </Chip>
                    )}
                </Stack>
            </Stack>
            <Grid container justifyContent="space-between" width="100%">
                <Grid item xs minWidth={0}>
                    <HorizontalScroller>
                        <Stack flexDirection="row" alignItems="center" gap={2}>
                            <Tag
                                rounded
                                variant="outlined"
                                density="dense"
                                endNode={
                                    <Switch
                                        switched={isEqual(
                                            view,
                                            DatasetTableTab.viewport
                                        )}
                                        onSwitchChange={() => {
                                            setView(
                                                isEqual(
                                                    view,
                                                    DatasetTableTab.viewport
                                                )
                                                    ? DatasetTableTab.all
                                                    : DatasetTableTab.viewport
                                            );
                                        }}
                                    />
                                }
                            >
                                Map View
                            </Tag>
                            {hasFilters && (
                                <Stack
                                    flexDirection="row"
                                    alignItems="center"
                                    gap={2}
                                >
                                    <DataGridDivider />
                                    <Dropdown
                                        placement="bottom-end"
                                        slotProps={{
                                            button: {
                                                fullWidth: true,
                                                disabled:
                                                    filters.filters.length ===
                                                    1,
                                                density: "none",
                                                variant: "outlined",
                                                sx: {
                                                    borderRadius: (theme) =>
                                                        theme.radius.full,
                                                    [`&.${buttonClasses.outlined}`]:
                                                        {
                                                            padding: 1,
                                                            paddingX: 2,
                                                        },
                                                },
                                            },
                                        }}
                                        label={
                                            <OverflowingTypography
                                                variant="button"
                                                fontWeight="semibold"
                                                sx={{
                                                    width: (theme) =>
                                                        theme.spacing(7),
                                                }}
                                            >
                                                {startCase(
                                                    filters.logicOperator
                                                )}
                                            </OverflowingTypography>
                                        }
                                        content={pipe(
                                            Object.values(LogicOperator),
                                            A.map((operator) => (
                                                <MenuItem
                                                    key={operator}
                                                    label={startCase(operator)}
                                                    density="dense"
                                                    value={
                                                        filters.logicOperator
                                                    }
                                                    onClick={() =>
                                                        onOperatorChange(
                                                            operator
                                                        )
                                                    }
                                                />
                                            ))
                                        )}
                                    />
                                    <DataGridDivider />
                                </Stack>
                            )}
                            <Stack flexDirection="row" gap={2}>
                                {pipe(
                                    filters.filters,
                                    A.map((filter) => (
                                        <TableFilterChip
                                            key={filter.id}
                                            column={filter.column}
                                            condition={mapFilterCriteriaSign({
                                                operator: filter.operator,
                                                verbose: true,
                                            })}
                                            value={filter.value || undefined}
                                            onRemoveClick={() => {
                                                onFilterRemove(filter.id);
                                            }}
                                            isRemoveDisabled={
                                                isRemoveDisabled &&
                                                filters.filters.length === 1
                                            }
                                        />
                                    ))
                                )}
                            </Stack>
                        </Stack>
                    </HorizontalScroller>
                </Grid>
                {hasFilters && isEqual(isClearFilterDisabled, false) && (
                    <Grid item>
                        <Stack
                            alignItems="center"
                            gap={1}
                            width="100%"
                            flexWrap="nowrap"
                            flexDirection="row"
                        >
                            <Divider
                                orientation="vertical"
                                color={200}
                                sx={{
                                    height: (theme) => theme.spacing(4),
                                    marginBlockStart: isMobile
                                        ? undefined
                                        : "revert",
                                    ...(isMobile && {
                                        display: "flex",
                                        alignSelf: "center",
                                    }),
                                }}
                            />
                            <Button
                                variant="ghost"
                                density="dense"
                                onClick={onClearFilters}
                            >
                                Clear Filters
                            </Button>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Stack>
    );
};
