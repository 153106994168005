import { Industry } from "@biggeo/bg-server-lib/studio";
import {
    AutoStoriesOutline,
    BacklightHighOutline,
    BugReportOutline,
    ChatBubbleOutline,
    MoveToInboxOutline,
    RouteOutline,
    SupportOutline,
    UnknownDocumentOutline,
} from "@biggeo/bg-ui/lab/icons";
import { Routes } from "../navigation/redux/model.ts";

export const user = {
    firstName: "Olivia",
    lastName: "Bergson",
    email: "olivia@northstarsystems.com",
    img: "https://randomuser.me/api/portraits/women/2.jpg",
    phoneCountryCode: "1",
    phone: "4015691234",
    currentWorkspace: {
        name: "Northstar Systems Canada",
        img: "https://biggeo.blob.core.windows.net/media/bg-background.png",
        industry: [Industry.PublicSectorAndGovernment],
        country: "Canada",
        phone: "4031231234",
        website: "www.northstarsystems.com",
        description: undefined,
    },
    workspaces: [
        {
            name: "Northstar Systems Canada",
            img: "https://biggeo.blob.core.windows.net/media/bg-background.png",
        },
        {
            name: "Northstar Systems USA",
            img: "https://biggeo.blob.core.windows.net/media/bg-background.png",
        },
    ],
    role: "owner",
};

export const bannerUsers = [
    {
        id: "1",
        email: "test1@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
        id: "2",
        email: "test2@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
        id: "3",
        email: "test3@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
        id: "4",
        email: "test4@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
        id: "5",
        email: "test5@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
    {
        id: "6",
        email: "test6@test.com",
        imgUrl: "https://randomuser.me/api/portraits/men/20.jpg",
    },
];

export const changeLogs = [
    {
        id: "1",
        date: "Jan 1, 2024",
        title: "Your Change Log Title Goes Here",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis consequatur aliquid iusto ratione aliquam? Ea minima omnis odio vero doloribus blanditiis at quasi excepturi officia corporis quas, veritatis architecto magni!",
        onClick: () => {},
    },
    {
        id: "2",
        date: "Jan 1, 2024",
        title: "Your Change Log Title Goes Here",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis consequatur aliquid iusto ratione aliquam? Ea minima omnis odio vero doloribus blanditiis at quasi excepturi officia corporis quas, veritatis architecto magni!",
        onClick: () => {},
    },
    {
        id: "3",
        date: "Jan 1, 2024",
        title: "Your Change Log Title Goes Here",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis consequatur aliquid iusto ratione aliquam? Ea minima omnis odio vero doloribus blanditiis at quasi excepturi officia corporis quas, veritatis architecto magni!",
        onClick: () => {},
    },
    {
        id: "4",
        date: "Jan 1, 2024",
        title: "Your Change Log Title Goes Here",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis consequatur aliquid iusto ratione aliquam? Ea minima omnis odio vero doloribus blanditiis at quasi excepturi officia corporis quas, veritatis architecto magni!",
        onClick: () => {},
    },
    {
        id: "5",
        date: "Jan 1, 2024",
        title: "Your Change Log Title Goes Here",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis consequatur aliquid iusto ratione aliquam? Ea minima omnis odio vero doloribus blanditiis at quasi excepturi officia corporis quas, veritatis architecto magni!",
        onClick: () => {},
    },
];

export const useCases = [
    {
        id: "1",
        title: "Real Estate",
        imgUrl: "",
        onClick: () => {},
    },
    {
        id: "2",
        title: "Logistics",
        imgUrl: "",
        onClick: () => {},
    },
    {
        id: "3",
        title: "Micro Mobility",
        imgUrl: "",
        onClick: () => {},
    },
    {
        id: "4",
        title: "Aviation",
        imgUrl: "",
        onClick: () => {},
    },
    {
        id: "5",
        title: "Mining",
        imgUrl: "",
        onClick: () => {},
    },
    {
        id: "6",
        title: "Finance",
        imgUrl: "",
        onClick: () => {},
    },
];

export const blogs = [
    {
        id: "1",
        title: "Blog #1",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
    {
        id: "2",
        title: "Blog #2",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
    {
        id: "3",
        title: "Blog #3",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
    {
        id: "4",
        title: "Blog #4",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
    {
        id: "5",
        title: "Blog #5",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
    {
        id: "6",
        title: "Blog #6",
        imgUrl: "",
        content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do, Lorem ipsum dolor sit amet, consectetur adipiscing elit, do...",
        onClick: () => {},
    },
];

export const resources = [
    {
        icon: <UnknownDocumentOutline />,
        title: "Developer Documentation",
        description: "Visualize your datasets and unlock for all of your team",
    },
    {
        icon: <AutoStoriesOutline />,
        title: "Knowledge Base",
        description: "Visualize your datasets and unlock for all of your team",
    },
    {
        icon: <ChatBubbleOutline />,
        title: "Ai Chat Support",
        description: "Visualize your datasets and unlock for all of your team",
    },
    {
        icon: <BacklightHighOutline />,
        title: "Feature Request",
        description: "Visualize your datasets and unlock for all of your team",
    },
];

export const temporaryResources = [
    {
        icon: <BacklightHighOutline />,
        title: "Feature request",
        description:
            "Let us know what we should be building next to make Datascape better for you",
        cta: "Request a feature",
        href: Routes.featureRequestAndIssues,
    },
    {
        icon: <MoveToInboxOutline />,
        title: "Give feedback",
        description:
            "Give us feedback on how you’re finding the use of Datascape",
        cta: "Give feedback",
        href: Routes.feedback,
    },
    {
        icon: <BugReportOutline />,
        title: "Report a bug",
        description:
            "Something is not working quite right? let us know immediately",
        cta: "Create Issue",
        href: Routes.featureRequestAndIssues,
    },
    {
        icon: <RouteOutline />,
        title: "Product roadmap",
        description:
            "See what's coming up and upvote the features you'd like the most.",
        cta: "View roadmap",
        href: Routes.roadmap,
    },
    {
        icon: <ChatBubbleOutline />,
        title: "Change Log",
        description:
            "See what’s changed since your last time here with Datascape",
        cta: "View Change Log",
        href: Routes.changeLog,
    },
    {
        icon: <SupportOutline />,
        title: "Help Center",
        description: "Get product help with using BigGeo Datascape",
        cta: "Get Support",
        href: Routes.support,
    },
];
