import { useFetchMarketplaceDatasetByIdQuery } from "@biggeo/bg-server-lib/datascape-ai";
import { Box, LoadingBar } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorPage } from "../../common/components/ErrorPage";
import { MarketplacePreviewDataListingContainer } from "./MarketplacePreviewDataListingContainer";

export const MarketplaceDatasetListingByIdContainer = () => {
    const { id } = useParams();

    const { remote: listingRd } = useFetchMarketplaceDatasetByIdQuery(
        id
            ? {
                  variables: {
                      id: id,
                  },
                  onError(error) {
                      <ErrorPage subtitle={error.message} />;
                  },
              }
            : {
                  skip: true,
              }
    );

    return (
        <Box
            sx={{
                height: "100dvh",
                width: "100%",
                containerType: "inline-size",
                minWidth: 0,
                background: (theme) => theme.palette.background.container,
                color: (theme) => theme.palette.background.onContainer,
            }}
        >
            {id ? (
                match(listingRd, {
                    _: () => <LoadingBar />,
                    Success: ({ fetchMarketplaceDatasetById }) => {
                        const { marketplaceDataset, provider } =
                            fetchMarketplaceDatasetById;

                        return (
                            <MarketplacePreviewDataListingContainer
                                marketplaceDataset={marketplaceDataset}
                                provider={provider}
                            />
                        );
                    },
                    Failure: (err) => {
                        return <ErrorPage subtitle={err.message} />;
                    },
                })
            ) : (
                <ErrorPage subtitle="Page is not found" />
            )}
        </Box>
    );
};
