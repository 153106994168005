import {
    InputDatasetStatisticsFilters,
    useFetchDatasetStatisticsLazyQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { withDefault } from "@vividtheory/remotedata";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import uuid from "react-uuid";
import { parse } from "wkt";
import { usePreviewDatasets } from "../../../database-meta-data/redux/hooks";
import { MapContextPreviewDataset, useMap } from "../context";
import { getDatasetSources } from "../utils/data-layers-utils";
import {
    DEFAULT_LEVEL_SETS,
    getPreviewDatasetLayerPrefixes,
    handlePreviewDatasetLayers,
    removePreviewDatasetLayer,
} from "../utils/preview-data-layers-utils";
import { DEFAULT_SHAPE_COLOR } from "./style-hooks";

export type PreviewGeometry = { contour_sets: string }[];

export const usePreviewData = ({
    filters,
}: { readonly filters: InputDatasetStatisticsFilters }) => {
    const { map, isLoaded, previews, dispatch } = useMap();
    const previewDatasets = withDefault([], usePreviewDatasets());

    const { executeQuery: getDatasetStatistics } =
        useFetchDatasetStatisticsLazyQuery();

    const setPreviewDataset = (values: MapContextPreviewDataset) => {
        dispatch?.({
            type: "SET_PREVIEW_DATASETS",
            values,
        });
    };

    const setDatasetPreviewFromResponse = (input: {
        dataset: MapContextPreviewDataset;
        map: mapboxgl.Map;
        isLoaded: boolean;
    }) => {
        const { dataset, map, isLoaded } = input;
        const { tableName, column, geometry, type } = dataset;
        const prefix = `${tableName}-${dataset.dataSourceId}`;

        if (isEqual(dataset.isVisible, true)) {
            if (!isEmpty(geometry)) {
                const geojsonGeometry: GeoJSON.Feature<
                    GeoJSON.Geometry,
                    GeoJSON.GeoJsonProperties
                >[][] = pipe(
                    geometry,
                    A.map((wktGeometry) => parse(wktGeometry)),
                    A.map((geojsonGeometry: GeoJSON.Geometry) =>
                        geojsonGeometry.type === "MultiPolygon"
                            ? geojsonGeometry.coordinates.flatMap(
                                  (c: GeoJSON.Position[][]) => ({
                                      type: "Feature",
                                      properties: {
                                          id: prefix,
                                          tableName,
                                          column,
                                          label: "Preview Level Set",
                                          idCadence: 12 % 20,
                                      },
                                      geometry: {
                                          type: "Polygon",
                                          coordinates: c,
                                      },
                                  })
                              )
                            : [
                                  {
                                      type: "Feature",
                                      properties: {
                                          id: prefix,
                                          tableName,
                                          column,
                                          label: "Preview Level Set",
                                          idCadence: 12 % 20,
                                      },
                                      geometry: geojsonGeometry,
                                  },
                              ]
                    )
                );
                const { levelSets } = getDatasetSources({
                    prefix,
                    map,
                    isLoaded,
                    levelSets: DEFAULT_LEVEL_SETS,
                });

                handlePreviewDatasetLayers({
                    action: "add",
                    map,
                    isLoaded,
                    prefix,
                    sources: { levelSets },
                    data: { type },
                    sourcesData: { levelSets: geojsonGeometry },
                    styles: {
                        fill: dataset.styles?.fill,
                        dataAggregation: dataset.styles?.dataAggregation,
                    },
                });
            }
        }

        if (isEqual(dataset.isVisible, false)) {
            removePreviewDatasetLayer({ map, isLoaded, preview: dataset });
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!isEmpty(previewDatasets)) {
            pipe(
                previewDatasets,
                A.map(({ dataSource, marketplaceDataset }) => {
                    if (marketplaceDataset) {
                        getDatasetStatistics({
                            variables: {
                                input: {
                                    tableName: marketplaceDataset.tableName,
                                    filters: {
                                        ...filters,
                                        multipolygon:
                                            !filters.multipolygon ||
                                            isEmpty(filters.multipolygon)
                                                ? undefined
                                                : filters.multipolygon,
                                    },
                                },
                            },
                            onCompleted: (data) => {
                                const found = previews.find(
                                    (p) => p.dataSourceId === dataSource.id
                                );

                                setPreviewDataset({
                                    id: uuid(),
                                    dataSourceId: dataSource.id,
                                    marketplaceDatasetId: marketplaceDataset.id,
                                    type: marketplaceDataset.type,
                                    tableName:
                                        data.fetchDatasetStatistics.tableName,
                                    column:
                                        data.fetchDatasetStatistics.column ||
                                        undefined,
                                    geometry:
                                        data.fetchDatasetStatistics.geometry,
                                    styles: {
                                        fill: {
                                            color:
                                                dataSource.color ||
                                                DEFAULT_SHAPE_COLOR,
                                        },
                                        dataAggregation: dataSource.heatmapColor
                                            ? {
                                                  heatmap: JSON.parse(
                                                      dataSource.heatmapColor
                                                  ),
                                              }
                                            : undefined,
                                    },
                                    isVisible: found ? found.isVisible : false,
                                    isGettingStyled: false,
                                });
                            },
                        });
                    }
                })
            );
        }
    }, [previewDatasets, filters.multipolygon?.length, filters.polygon]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (map.current && isLoaded) {
            if (!isEmpty(previews)) {
                for (const preview of previews) {
                    setDatasetPreviewFromResponse({
                        map: map.current,
                        isLoaded,
                        dataset: preview,
                    });
                }
            }

            if (isEmpty(previews)) {
                const prefixes = getPreviewDatasetLayerPrefixes({
                    map: map.current,
                    isLoaded,
                });

                prefixes.map((prefix) => {
                    if (map.current) {
                        const { levelSets } = getDatasetSources({
                            prefix,
                            map: map.current,
                            isLoaded,
                            levelSets: DEFAULT_LEVEL_SETS,
                        });

                        handlePreviewDatasetLayers({
                            action: "remove",
                            map: map.current,
                            isLoaded,
                            prefix,
                            sources: { levelSets },
                        });
                    }
                });
            }
        }
    }, [previewDatasets, previews, map.current, isLoaded]);
};
