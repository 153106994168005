import {
    CollectionProgressStatus,
    useIsDatasetStatisticsPreviewDownloadedQuery,
    useListenToProcessCollectionSubscription,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    BorderedTabsWrapper,
    Button,
    FeatureComingSoon,
    Stack,
    Tab,
    TabGroup,
    Typography,
} from "@biggeo/bg-ui/lab";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../navigation/redux/model";
import MarketplaceDatasetStatisticsContainer, {
    IMarketplaceDatasetStatisticsContainer,
} from "../containers/MarketplaceDatasetStatisticsContainer";

export type AreaQualityTabsProps = IMarketplaceDatasetStatisticsContainer & {
    onClickDownload: () => void;
    isDownloadDisabled?: boolean;
};

export const AreaQualityTabs = ({
    onClickDownload,
    isDownloadDisabled = true,
    ...props
}: AreaQualityTabsProps) => {
    const [tab, setTab] = useState("area-quality");
    const [isDownloaded, setIsDownloaded] = useState<boolean>(true);
    const {
        queryReturn: { data },
    } = useIsDatasetStatisticsPreviewDownloadedQuery({
        variables: {
            tableName: props.tableName,
        },
    });

    const { data: statusData } = useListenToProcessCollectionSubscription(
        props.dataSourceId
            ? {
                  variables: {
                      channel: `${props.dataSourceId}-statistics`,
                  },
                  context: { clientName: "node" },
                  fetchPolicy: "no-cache",
                  shouldResubscribe: true,
              }
            : { skip: true }
    );

    useEffect(() => {
        if (data) {
            if (
                statusData &&
                statusData.listenToProcessCollection.status ===
                    CollectionProgressStatus.complete
            ) {
                setIsDownloaded(true);
            } else {
                setIsDownloaded(data.isDatasetStatisticsPreviewDownloaded);
            }
        }
    }, [data, statusData]);

    return (
        <Stack gap={4}>
            <BorderedTabsWrapper>
                <TabGroup variant="tonal" value={tab} square>
                    <Tab
                        value="area-quality"
                        onClick={() => setTab("area-quality")}
                    >
                        Area Quality
                    </Tab>
                    <Tab
                        value="get-sample"
                        onClick={() => setTab("get-sample")}
                    >
                        Get Sample
                    </Tab>
                </TabGroup>
            </BorderedTabsWrapper>
            {tab === "area-quality" ? (
                isDownloaded ? (
                    <MarketplaceDatasetStatisticsContainer {...props} />
                ) : (
                    <Stack
                        gap={1}
                        alignItems="center"
                        sx={{
                            padding: 8,
                            border: 1,
                            borderColor: (theme) => theme.palette.stroke[100],
                            borderRadius: (theme) => theme.radius.xs3,
                        }}
                    >
                        <Typography
                            variant="title1"
                            fontWeight="bold"
                            align="center"
                        >
                            Download Preview
                        </Typography>
                        <Typography
                            variant="body3"
                            textColor="disabled.onContainer"
                            align="center"
                        >
                            Download preview to view area quality stats
                        </Typography>
                        <Button
                            variant="outlined"
                            disabled={isDownloadDisabled}
                            onClick={onClickDownload}
                        >
                            Download
                        </Button>
                    </Stack>
                )
            ) : (
                <FeatureComingSoon
                    endNode={
                        <Link
                            to={Routes.featureRequestAndIssues}
                            target="_blank"
                        >
                            <Button variant="outlined">Upvote feature</Button>
                        </Link>
                    }
                />
            )}
        </Stack>
    );
};
