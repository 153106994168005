import {
    AnyResponse,
    DatabaseType,
    DatasetGeometryPoint,
    FetchSnowflakeDataQuery,
    FetchSnowflakeTableSchemaQuery,
    FilterCriteriaDatasetItem,
    FilterObject,
    InputPolygon,
    InputViewBox,
    LogicOperator,
    useFetchSnowflakeDataQuery,
    useFetchSnowflakeTableSchemaQuery,
} from "@biggeo/bg-server-lib/datascape-ai";
import { useDataGridOptions } from "@biggeo/bg-ui/lab";
import { map2, withDefault } from "@vividtheory/remotedata";

import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import uniqBy from "lodash/uniqBy";
import { useMemo, useState } from "react";
import uuid from "react-uuid";
import { match } from "ts-pattern";
import { ColorSwatchOption } from "../../../common/components/ColorSwatchSelector";
import { MapTableTabs } from "../../map-wrappers/MapViewWrapper";
import { MapContextDataset } from "../../mapbox/context";
import { getFilterObjectFromDataset } from "../../mapbox/context/context-utils";
import { getDatasetShapeColor } from "../../utils/style-utils";
import { DatasetTable } from "../components/DatasetTable";
import {
    FILTERED_DATA_TABLE_LIMIT,
    MapFilterCriteriaDataset,
    getFilterObjectFromFilter,
} from "../utils/utils";

export enum DatasetTableTab {
    viewport = "viewport",
    all = "all",
}
interface DatasetTableContainerDefautType {
    readonly channelId: string;
    readonly geospatialSelection:
        | { viewport: InputViewBox }
        | { multipolygon: InputPolygon[] };
    readonly isRunningOnSF: boolean;
    readonly filterId?: string;
    readonly dggsIndexName: string;
    readonly updateFilter?: (filters: FilterCriteriaDatasetItem[]) => void;
    readonly updateDataset?: (
        i: Partial<{
            filters: FilterCriteriaDatasetItem[];
            logicOperator: LogicOperator;
            selectedRows: Omit<DatasetGeometryPoint, "dataSourceId">[];
            pinnedRows: Omit<DatasetGeometryPoint, "dataSourceId">[];
        }>
    ) => void;
    readonly onGoToClick: (rows: AnyResponse["data"]) => void;
    readonly heatmap?: ColorSwatchOption;
    readonly mainTab: MapTableTabs;
    readonly tableId?: string;
}
interface IDefaultDatasetTableContainer
    extends DatasetTableContainerDefautType {
    readonly type: "data";
    readonly data: MapContextDataset;
}

interface IFilteredDatasetTableContainer
    extends DatasetTableContainerDefautType {
    readonly type: "filteredData";
    readonly data: MapFilterCriteriaDataset;
}

type DatasetTableContainerProps =
    | IDefaultDatasetTableContainer
    | IFilteredDatasetTableContainer;

const DatasetTableContainer = ({
    type,
    channelId,
    isRunningOnSF,
    data,
    filterId,
    updateFilter,
    updateDataset,
    onGoToClick,
    dggsIndexName,
    heatmap,
    mainTab,
    tableId,
    ...props
}: DatasetTableContainerProps) => {
    const [view, setView] = useState<DatasetTableTab>(DatasetTableTab.viewport);
    const geospatialSelection = useMemo(
        () =>
            match<
                DatasetTableTab,
                | { viewport: InputViewBox }
                | { multipolygon: InputPolygon[] }
                | undefined
            >(view)
                .with(DatasetTableTab.viewport, () => props.geospatialSelection)
                .with(DatasetTableTab.all, () => undefined)
                .exhaustive(),
        [view, props.geospatialSelection]
    );

    const filter: FilterObject = useMemo(
        () =>
            type === "filteredData"
                ? getFilterObjectFromFilter(data, isRunningOnSF)
                : getFilterObjectFromDataset(data),
        [data, isRunningOnSF, type]
    );

    const [date] = useState(Date.now().toString());
    const {
        dataGridFetchInputProps: filteredDataGridProps,
        filterSearchPaginateProps: filteredDataPaginationProps,
    } = useDataGridOptions(FILTERED_DATA_TABLE_LIMIT);

    const styles = type === "data" ? getDatasetShapeColor(data) : undefined;

    const initialFilters = useMemo(
        () => ({
            logicOperator: filter.logicOperator,
            filters: pipe(
                filter.filters,
                A.map((f) => {
                    const value =
                        f.data.stringData ??
                        f.data.booleanData ??
                        f.data.dateData ??
                        f.data.numberData ??
                        undefined;

                    return {
                        id: uuid(),
                        filterId,
                        column: f.column,
                        operator: f.operator,
                        type: f.type,
                        value: value ? value.toString() : undefined,
                    };
                })
            ),
        }),
        [filter, filterId]
    );

    const {
        remote: columnsRD,
        queryReturn: { loading: cLoading },
    } = useFetchSnowflakeTableSchemaQuery({
        variables: {
            databaseId: filter.databaseId,
            withAttributes: filter.databaseType === DatabaseType.point,
        },
    });

    const {
        remote: dataRD,
        queryReturn: { loading: dLoading },
    } = useFetchSnowflakeDataQuery({
        variables: {
            filters: {
                ...geospatialSelection,
                filters: {
                    filters: filter.filters,
                    databaseId: filter.databaseId,
                    logicOperator: filter.logicOperator,
                    collection: filter.collection,
                    databaseType: filter.databaseType,
                    options: filter.options,
                },
                limitOffset: {
                    limit: filteredDataGridProps.pageLimit,
                    offset:
                        filteredDataGridProps.pageLimit *
                        (filteredDataGridProps.pageIndex - 1),
                },
                dateTime: date,
                channelId,
            },
        },
    });

    const remoteData = pipe(
        withDefault(
            { columns: [], data: { count: 0, data: [] } },
            map2(
                (c: FetchSnowflakeTableSchemaQuery) =>
                    (d: FetchSnowflakeDataQuery) => ({
                        columns: c.fetchSnowflakeTableSchema.rows,
                        data: d.fetchSnowflakeData,
                    }),
                columnsRD,
                dataRD
            )
        ),
        (data) =>
            tableId
                ? {
                      ...data,
                      data: {
                          ...data.data,
                          data: uniqBy(data.data.data, tableId),
                      },
                  }
                : data
    );

    return (
        <DatasetTable
            view={view}
            setView={setView}
            {...remoteData}
            type={type}
            heatmap={styles ? styles.heatmap : heatmap}
            loading={cLoading || dLoading || isEmpty(remoteData.columns)}
            dataPaginationProps={filteredDataPaginationProps}
            initialFilters={initialFilters}
            updateFilter={updateFilter}
            updateDataset={updateDataset}
            onGoToClick={onGoToClick}
            dggsIndexName={dggsIndexName}
            isGoToFieldHidden={isEqual(mainTab, MapTableTabs.table)}
            tableId={tableId}
            isFocused={
                type === "data" && !isEmpty(data.selectedRows)
                    ? !data.configuration.showPoints
                    : false
            }
            selectedRows={
                type === "data"
                    ? pipe(
                          data.selectedRows,
                          A.map((r) => r.row)
                      )
                    : []
            }
            pinnedRows={
                type === "data"
                    ? pipe(
                          data.pinnedRows,
                          A.map((r) => r.row)
                      )
                    : []
            }
        />
    );
};

export default DatasetTableContainer;
