import { FilterCriteriaDatasetItem } from "@biggeo/bg-server-lib/datascape-ai";
import {
    AutoComplete,
    Button,
    DatePicker,
    Divider,
    Dropdown,
    FilterType,
    FlexScrollArea,
    FlexScrollAreaContainer,
    IconAvatar,
    InfoCell,
    MenuItem,
    OverflowingTypography,
    Radio,
    Stack,
    TextField,
    Typography,
    WhereOperator,
} from "@biggeo/bg-ui/lab";
import { SearchOutline, TableOutline } from "@biggeo/bg-ui/lab/icons";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import capitalize from "lodash/capitalize";
import isDate from "lodash/isDate";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";
import { useState } from "react";
import { match } from "ts-pattern";
import { formatNumberWithCommas } from "../../../utils/utils";
import { mapFilterCriteriaSign } from "../utils/utils";

type MenuStringType = {
    attributes: string[];
    limit: number;
    total: number;
};

type MenuNumberType = {
    min: number;
    max: number;
};

interface DatasetTableHeaderMenuProps
    extends Partial<MenuStringType>,
        Partial<MenuNumberType> {
    type: FilterType;
    header: string;
    operators: WhereOperator[];
    onApplyClick: (filter: FilterCriteriaDatasetItem) => void;
    onCancelClick: () => void;
}

export type ConditionalDatasetTableHeaderMenuProps<
    T extends {
        readonly type: FilterType;
    },
> = T extends {
    readonly type: FilterType.StringType;
}
    ? T & MenuStringType
    : T extends {
            readonly type: FilterType.NumberType;
        }
      ? T & MenuNumberType
      : T;

export const DatasetTableHeaderMenu = <T extends DatasetTableHeaderMenuProps>({
    type,
    header,
    operators,
    onApplyClick,
    onCancelClick,

    attributes,
    limit,
    total,

    min,
    max,
}: ConditionalDatasetTableHeaderMenuProps<T>) => {
    const [operator, setOperator] = useState<WhereOperator>(
        operators[0] ?? WhereOperator.equals
    );
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [selected, setSelected] = useState<
        string | number | Date | boolean | undefined
    >(undefined);

    const isEmptyCheck =
        isEqual(operator, WhereOperator.isEmpty) ||
        isEqual(operator, WhereOperator.isNotEmpty);

    const onApply = (
        operator: WhereOperator,
        value?: string | number | Date | boolean
    ) => {
        onApplyClick({
            column: header,
            type,
            operator,
            value: value?.toString(),
        });
        onCancelClick();
    };

    return (
        <FlexScrollAreaContainer
            width="100%"
            height="100%"
            sx={{
                maxHeight: (theme) => theme.spacing(80),
                minHeight: (theme) =>
                    theme.spacing(
                        type === FilterType.NumberType
                            ? 55
                            : type === FilterType.StringType
                              ? 70
                              : 45
                    ),
                width: (theme) => theme.spacing(61.25),
            }}
        >
            <Stack
                gap={2}
                sx={{
                    padding: 2,
                }}
            >
                <Stack flexDirection="row" justifyContent="space-between">
                    <Stack flexDirection="row" gap={1} alignItems="center">
                        <IconAvatar square color="surface" size="xs">
                            <TableOutline size="md" />
                        </IconAvatar>
                        <OverflowingTypography
                            variant="body3"
                            fontWeight="semibold"
                            sx={{
                                maxWidth: (theme) => theme.spacing(25),
                            }}
                        >
                            {header}
                        </OverflowingTypography>
                    </Stack>
                    <Dropdown
                        placement="bottom-end"
                        slotProps={{
                            button: {
                                density: "dense",
                                variant: "outlined",
                            },
                        }}
                        label={
                            <OverflowingTypography
                                variant="button"
                                fontWeight="semibold"
                                sx={{
                                    minWidth: (theme) => theme.spacing(9),
                                }}
                            >
                                {mapFilterCriteriaSign({
                                    operator,
                                })}
                            </OverflowingTypography>
                        }
                        content={
                            <Stack
                                gap={1}
                                sx={{
                                    maxHeight: (theme) => theme.spacing(95),
                                }}
                            >
                                {pipe(
                                    operators,
                                    A.map((o) => (
                                        <MenuItem
                                            key={o}
                                            label={mapFilterCriteriaSign({
                                                operator: o,
                                            })}
                                            density="dense"
                                            value={operator}
                                            onClick={() => {
                                                setOperator(o);

                                                if (
                                                    isEqual(
                                                        o,
                                                        WhereOperator.isEmpty
                                                    ) ||
                                                    isEqual(
                                                        o,
                                                        WhereOperator.isNotEmpty
                                                    )
                                                ) {
                                                    setSelected(undefined);
                                                }
                                            }}
                                        />
                                    ))
                                )}
                            </Stack>
                        }
                    />
                </Stack>
                {type === FilterType.StringType && attributes && (
                    <TextField
                        fullWidth
                        placeholder="Enter value"
                        value={selected && isString(selected) ? selected : ""}
                        onChange={(_, v) => setSelected(v)}
                    />
                )}
            </Stack>
            <Divider />
            <FlexScrollArea
                width="100%"
                height="100%"
                sx={{
                    padding: 2,
                }}
            >
                {match(type)
                    .with(FilterType.StringType, () => (
                        <Stack gap={2} width="100%" height="100%">
                            {attributes && (
                                <AutoComplete
                                    fullWidth
                                    startNode={<SearchOutline />}
                                    placeholder="Search attributes..."
                                    value={search}
                                    options={pipe(
                                        attributes,
                                        A.map((attribute) => ({
                                            label: attribute,
                                            onClick: () => setSearch(attribute),
                                        }))
                                    )}
                                    onChange={(_, value) => {
                                        if (!value || isEqual(value, "")) {
                                            setSearch(undefined);
                                        }
                                    }}
                                />
                            )}
                            <Stack>
                                <OverflowingTypography
                                    variant="body3"
                                    sx={{
                                        maxWidth: (theme) => theme.spacing(70),
                                    }}
                                >
                                    {`Showing ${limit}/${total} attributes`}
                                </OverflowingTypography>
                            </Stack>
                            {attributes && (
                                <Stack
                                    gap={1}
                                    sx={{
                                        paddingBottom: 2,
                                    }}
                                >
                                    {pipe(
                                        attributes,
                                        A.filter((a) =>
                                            search ? isEqual(a, search) : true
                                        ),
                                        A.map((attribute) => (
                                            <InfoCell
                                                key={attribute}
                                                title={
                                                    <OverflowingTypography variant="body2">
                                                        {attribute}
                                                    </OverflowingTypography>
                                                }
                                                density="none"
                                                startNode={
                                                    <Radio
                                                        checked={
                                                            !isUndefined(
                                                                selected
                                                            ) &&
                                                            isEqual(
                                                                selected,
                                                                attribute
                                                            )
                                                        }
                                                        onClick={() => {
                                                            setSelected(
                                                                isEqual(
                                                                    selected,
                                                                    attribute
                                                                )
                                                                    ? undefined
                                                                    : attribute
                                                            );

                                                            if (isEmptyCheck) {
                                                                setOperator(
                                                                    operators[0] ??
                                                                        WhereOperator.equals
                                                                );
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        ))
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    ))
                    .with(FilterType.NumberType, () => (
                        <Stack gap={2}>
                            <TextField
                                fullWidth
                                type="number"
                                placeholder="Enter number"
                                value={
                                    selected && isNumber(selected)
                                        ? selected.toString()
                                        : ""
                                }
                                onChange={(_, v) => {
                                    setSelected(v ? Number(v) : undefined);
                                    if (isEmptyCheck) {
                                        setOperator(
                                            operators[0] ?? WhereOperator.equals
                                        );
                                    }
                                }}
                            />
                            <Stack gap={1} flexDirection="row">
                                <Typography
                                    variant="body3"
                                    textColor="disabled.onContainer"
                                >
                                    Min:
                                </Typography>
                                <Typography variant="body3">
                                    {formatNumberWithCommas(min || 0)}
                                </Typography>
                            </Stack>
                            <Stack gap={1} flexDirection="row">
                                <Typography
                                    variant="body3"
                                    textColor="disabled.onContainer"
                                >
                                    Max:
                                </Typography>
                                <Typography variant="body3">
                                    {formatNumberWithCommas(max || 0)}
                                </Typography>
                            </Stack>
                        </Stack>
                    ))
                    .with(FilterType.BooleanType, () => (
                        <Stack gap={2}>
                            {pipe(
                                [true, false],
                                A.map((b) => (
                                    <InfoCell
                                        key={b.toString()}
                                        title={capitalize(b.toString())}
                                        density="none"
                                        startNode={
                                            <Radio
                                                checked={
                                                    !isUndefined(selected) &&
                                                    isEqual(selected, b)
                                                }
                                                onClick={() => {
                                                    setSelected(
                                                        isEqual(selected, b)
                                                            ? undefined
                                                            : b
                                                    );

                                                    if (isEmptyCheck) {
                                                        setOperator(
                                                            operators[0] ??
                                                                WhereOperator.equals
                                                        );
                                                    }
                                                }}
                                            />
                                        }
                                    />
                                ))
                            )}
                        </Stack>
                    ))
                    .with(FilterType.DateType, () => (
                        <Stack gap={2}>
                            <DatePicker
                                value={isDate(selected) ? selected : null}
                                onChange={(e) => {
                                    if (e) {
                                        setSelected(e);
                                    }
                                }}
                                label="Enter date"
                                slotProps={{
                                    popper: {
                                        sx: {
                                            zIndex: 9999,
                                        },
                                    },
                                }}
                            />
                        </Stack>
                    ))
                    .exhaustive()}
            </FlexScrollArea>
            <Divider />
            <Stack
                flexDirection="row"
                gap={1}
                justifyContent="flex-end"
                sx={{
                    padding: 2,
                }}
            >
                <Stack flexDirection="row" gap={1} justifyContent="flex-end">
                    <Button variant="ghost" onClick={onCancelClick}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={isEmptyCheck ? false : isUndefined(selected)}
                        onClick={() => onApply(operator, selected)}
                    >
                        Apply
                    </Button>
                </Stack>
            </Stack>
        </FlexScrollAreaContainer>
    );
};
