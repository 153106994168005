import {
    AnyResponse,
    SubscriptionDataConnection,
} from "@biggeo/bg-server-lib/datascape-ai";
import {
    bgLongToBigInt,
    bgLongToString,
    bigIntToBgLong,
} from "@biggeo/bg-utils";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { centroid, lineString, midpoint, point, polygon } from "@turf/turf";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import * as S from "fp-ts/string";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { Dispatch } from "redux";
import { commonActions } from "../../../common/redux/model";
import { closePolygon } from "../../../utils/utils";
import {
    getFeatureBounds,
    handleAntiMeridian,
    isLineStringFeature,
    isMultiPolygonFeature,
    isPointFeature,
    isPolygonFeature,
} from "../../utils/utils";

export const getGeometryFromData = (i: {
    data: SubscriptionDataConnection;
    dataSourceId: string;
}): {
    aggregates: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
    points: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
    polygons: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
    multiPolygons: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >[];
    lines: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[];
    levelSets: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[][];
} => {
    const { data, dataSourceId } = i;

    const aggregates: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >[] = pipe(
        data.aggregation || [],
        A.map((d) => {
            const pointCoord = [d.meanPoint.longitude, d.meanPoint.latitude];
            return d.count
                ? data.pointCount.low === 0
                    ? undefined
                    : point(pointCoord, {
                          label: `Aggregate ${d.cellID}`,
                          count: d.count,
                          databaseId: dataSourceId,
                      })
                : undefined;
        }),
        compact
    );

    const points: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >[] = pipe(
        data.points || [],
        A.map((p) => {
            const pointCoord = p.point
                ? [p.point.longitude, p.point.latitude]
                : [0, 0];

            const formattedId =
                p.id.uint64?.low || p.id.uint64?.high
                    ? bgLongToString(p.id.uint64)
                    : p.id.uint64
                      ? p.id.uint64
                      : p.id.string;

            return point(pointCoord, {
                label: `Point ${p.id.uint64 ? bgLongToString(p.id.uint64) : p.id.string}`,
                count: 1,
                id: JSON.stringify(p.id),
                formattedId: formattedId?.toString(),
                databaseId: dataSourceId,
            });
        })
    );

    const polygons: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >[] = pipe(
        data.nonPoints || [],
        A.map((p) => {
            const polypoints = p?.polygon?.outer?.points || [];
            const points = polypoints.map((p) => {
                return [p.longitude, p.latitude];
            });
            if (points.length < 3) {
                return undefined;
            }

            return polygon([closePolygon(points)], {
                count: 1,
                label: `Polygon ${p.id.uint64 ? bgLongToString(p.id.uint64) : p.id.string}`,
                id: JSON.stringify(p.id),
                databaseId: dataSourceId,
                idCadence: p.id.uint64
                    ? bigIntToBgLong(bgLongToBigInt(p.id.uint64) % 20n).low
                    : p.id.string,
            });
        }),
        compact
    );

    const multiPolygons: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >[] = pipe(
        data.nonPoints || [],
        A.flatMap((geometry) =>
            pipe(
                geometry.multipolygon?.polygons || [],
                A.map((p) => {
                    const polypoints = p?.outer?.points || [];
                    const points = polypoints.map((p) => {
                        return [p.longitude, p.latitude];
                    });

                    if (points.length < 3) {
                        return undefined;
                    }
                    return polygon([closePolygon(points)], {
                        count: 1,
                        label: `Multi-Polygon ${geometry.id}`,
                        id: JSON.stringify(geometry.id),
                        databaseId: dataSourceId,
                        idCadence: geometry.id.uint64
                            ? bigIntToBgLong(
                                  bgLongToBigInt(geometry.id.uint64) % 20n
                              ).low
                            : geometry.id.string,
                    });
                })
            )
        ),
        compact
    );

    const lines: GeoJSON.Feature<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >[] = pipe(
        data.nonPoints || [],
        A.map((l) => {
            const linePoints = l?.lineStrip?.points || [];

            const points = handleAntiMeridian(linePoints);

            return points.length < 3
                ? undefined
                : lineString(points, {
                      count: 1,
                      label: `Line ${l.id}`,
                      id: l.id,
                      databaseId: dataSourceId,
                      line: "true",
                  });
        }),
        compact
    );

    const levelSets = pipe(
        data.levelSets || [],
        A.map((ls) => {
            return ls.rings.map((r) => {
                const ringPoints = r?.points || [];

                const points = handleAntiMeridian(ringPoints);

                return polygon([closePolygon(points)], {
                    count: 1,
                    label: "Levelset triangle",
                    id: 12,
                    databaseId: dataSourceId,
                    idCadence: 12 % 20,
                });
            });
        })
    );

    return { aggregates, points, polygons, multiPolygons, lines, levelSets };
};

export const handleLayerRenderingTime = ({
    glContext,
    datasetId,
    map,
    dispatch,
}: {
    glContext: WebGLRenderingContext | WebGL2RenderingContext | null;
    datasetId: string;
    map: mapboxgl.Map;
    dispatch?: Dispatch;
}) => {
    if (glContext && dispatch) {
        computeWebGLRenderingTime({
            context: glContext,
            datasetId,
            map,
            dispatch,
        });
    } else {
        console.warn("WebGL context not available.");
    }
};

export const computeWebGLRenderingTime = ({
    context,
    datasetId,
    map,
    dispatch,
}: {
    context: WebGLRenderingContext | WebGL2RenderingContext;
    datasetId: string;
    map: mapboxgl.Map;
    dispatch: Dispatch;
}) => {
    const start = performance.now();
    // Enable timer queries if available (WebGL2)
    context.getExtension("EXT_disjoint_timer_query_webgl2");

    map.once("render", () => {
        // Capture the WebGL render time for the current frame
        const end = performance.now();
        const layerRenderingTime = end - start;
        if (!isNil(datasetId)) {
            dispatch(
                commonActions.setRenderingTime({
                    datasetId,
                    layerRenderingTime,
                })
            );
        }
    });
};

export const getPointFeatures = (input: {
    datasetId: string;
    rows: AnyResponse["data"];
    tableId?: string;
    geographyColumn: string[];
}) => {
    const { rows, geographyColumn, tableId, datasetId } = input;

    const isPoint = geographyColumn.length === 2;
    const isPolygon = geographyColumn.length === 1;

    return pipe(
        rows,
        A.map((row) => {
            const id = tableId ? row[tableId].toString() : undefined;

            if (isPoint) {
                const longitudeProp = geographyColumn[0];
                const latitudeProp = geographyColumn[1];

                const latitude = row[latitudeProp];
                const longitude = row[longitudeProp];

                const pointCoord =
                    latitude && longitude ? [longitude, latitude] : [0, 0];

                return point(pointCoord, {
                    label: `Point - ${id}`,
                    count: 1,
                    formattedId: id,
                    id: JSON.stringify({
                        uint64: Number(id),
                    }),
                    databaseId: datasetId,
                });
            }

            if (isPolygon) {
                const latLongProp = geographyColumn[0];
                const latLong: GeoJSON.Geometry = row[latLongProp];

                return {
                    type: MapboxDraw.constants.geojsonTypes.FEATURE,
                    geometry: latLong,
                    properties: {
                        label: `Polygon/MultiPolygon/LineString - ${id}`,
                        count: 1,
                        formattedId: id,
                        id: JSON.stringify({
                            uint64: Number(id),
                        }),
                        databaseId: datasetId,
                    },
                };
            }
        }),
        compact
    );
};

export const onFlyToMapFeature = (i: {
    rows: AnyResponse["data"];
    geographyColumn?: string;
    tableId?: string;
    datasetId: string;
    map: React.MutableRefObject<mapboxgl.Map | null>;
    isLoaded: boolean;
    onError: () => void;
}) => {
    const {
        rows,
        geographyColumn: gc,
        tableId,
        map,
        isLoaded,
        datasetId,
        onError,
    } = i;

    const geographyColumn = pipe(
        gc,
        O.fromNullable,
        O.fold(
            () => [],
            (column) => pipe(column, S.split(","), compact)
        )
    );

    if (isEmpty(geographyColumn) || !tableId) {
        onError();
    }

    if (map.current && isLoaded && tableId && !isEmpty(geographyColumn)) {
        const points = getPointFeatures({
            datasetId,
            rows,
            tableId,
            geographyColumn,
        });
        const geojson = points[0];

        if (isPointFeature(geojson)) {
            map.current.flyTo({
                center: [
                    geojson.geometry.coordinates[0],
                    geojson.geometry.coordinates[1],
                ],
                zoom: 13,
                essential: true,
                offset: [0, -200],
            });
        } else {
            const bounds = getFeatureBounds(geojson);

            map.current.fitBounds(bounds, {
                maxZoom: 14,
                duration: 1000,
                essential: true,
                offset: [0, -120],
            });
        }
    }
};

export const getPointFromGeometry = (
    feature: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
): GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties> | undefined => {
    if (isPointFeature(feature)) {
        return feature;
    }

    if (isLineStringFeature(feature)) {
        return midpoint(
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[
                feature.geometry.coordinates.length - 1
            ]
        );
    }

    if (isPolygonFeature(feature) || isMultiPolygonFeature(feature)) {
        return centroid(feature, {
            properties: feature.properties,
        });
    }
};
