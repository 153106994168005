import { useFetchExtendedMarketplaceDatasetByIdQuery } from "@biggeo/bg-server-lib/datascape-ai";
import { LoadingBar } from "@biggeo/bg-ui/lab";
import { match } from "@vividtheory/remotedata";
import MarketplaceDatasetPreview from "../views/MarketplaceDatasetPreview";
import { IMarketplaceDatasetPreviewContainer } from "./MarketplaceDatasetPreviewContainer";

interface IMarketplaceDatasetPreviewById
    extends Omit<IMarketplaceDatasetPreviewContainer, "previewDataset"> {
    readonly marketplaceDatasetId: string;
}

const MarketplaceDatasetPreviewById = ({
    marketplaceDatasetId,
    isPreviewBtnDisabled,
    onClickPreviewInfo,
    ...props
}: IMarketplaceDatasetPreviewById) => {
    const { remote } = useFetchExtendedMarketplaceDatasetByIdQuery({
        variables: { id: marketplaceDatasetId },
    });

    return match(remote, {
        _: () => <LoadingBar />,
        Success: (data) => {
            const result = data.fetchExtendedMarketplaceDatasetById;

            return (
                <MarketplaceDatasetPreview
                    {...props}
                    marketplaceDataset={result.marketplaceDataset}
                    isPreviewBtnDisabled={
                        result.dataSource?.isPreview || isPreviewBtnDisabled
                    }
                    dataSourceId={result.dataSource?.id}
                    onClose={() => onClickPreviewInfo(undefined)}
                />
            );
        },
    });
};

export default MarketplaceDatasetPreviewById;
