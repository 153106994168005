import { FilterObject } from "@biggeo/bg-server-lib/datascape-ai";
import { WithPartialValues } from "@biggeo/bg-utils";
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import {
    DEFAULT_LOGIC_OPERATOR,
    getFilterObjectFromFilter,
    mapFilterData,
} from "../../filter-criteria/utils/utils";
import { getDefaultDatasetConfigurationOptions } from "../../utils/utils";
import {
    MapContextDataset,
    MapContextDatasetConfiguration,
    MapContextFilter,
    useMap,
} from "./map";

export type SetDatasetContextType = {
    addDatasets: (values: MapContextDataset | MapContextDataset[]) => void;
    updateDataset: (values: {
        dataSourceId: string;
        dataset: Partial<WithPartialValues<MapContextDataset>>;
    }) => void;
    removeDataset: (values: {
        dataSourceId: string;
        mapTemplateDatasetId?: number;
    }) => void;
    overrideDatasets: (values: MapContextDataset[]) => void;
    reorderDatasets: (dataSourceIds: string[]) => void;
};

export const setDatasetContext = (): SetDatasetContextType => {
    const { dispatch } = useMap();

    const addDatasets = (values: MapContextDataset | MapContextDataset[]) => {
        dispatch?.({ type: "ADD_DATASETS", values });
    };

    const updateDataset = (values: {
        dataSourceId: string;
        dataset: Partial<WithPartialValues<MapContextDataset>>;
    }) => {
        dispatch?.({ type: "UPDATE_DATASET", values });
    };

    const removeDataset = (values: {
        dataSourceId: string;
        mapTemplateDatasetId?: number;
    }) => {
        dispatch?.({ type: "REMOVE_DATASET", values });
    };

    const overrideDatasets = (values: MapContextDataset[]) => {
        dispatch?.({ type: "OVERRIDE_DATASETS", values });
    };

    const reorderDatasets = (dataSourceIds: string[]) => {
        dispatch?.({ type: "REORDER_DATASETS", values: { dataSourceIds } });
    };

    return {
        addDatasets,
        updateDataset,
        removeDataset,
        overrideDatasets,
        reorderDatasets,
    };
};

export const getFilterObjectFromDataset = (
    dataset: MapContextDataset
): FilterObject => ({
    collection: dataset.dataSource.collectionName,
    databaseId: dataset.dataSource.id,
    databaseType: dataset.dataSource.type,
    filters:
        dataset.filters && !isEmpty(dataset.filters.filters)
            ? pipe(
                  dataset.filters.filters,
                  A.map((filter) => ({
                      column: filter.column,
                      operator: filter.operator,
                      type: filter.type,
                      data: mapFilterData(
                          filter.type,
                          filter.value || undefined
                      ),
                  }))
              )
            : [],
    isPreview: dataset.dataSource.isPreview ?? false,
    logicOperator: dataset.filters?.logicOperator ?? DEFAULT_LOGIC_OPERATOR,
    options: dataset.configuration.options,
});

export const getMultiFiltersType = (input: {
    datasets: MapContextDataset[];
    filters: MapContextFilter[];
    isRunningOnSF: boolean;
}): FilterObject[] => {
    const { datasets, filters, isRunningOnSF } = input;

    const selectedDatasets = pipe(
        datasets,
        A.filter(
            (dataset) =>
                isEqual(dataset.isSelected, true) &&
                isEqual(dataset.dataSource.isPreview, false)
        )
    );
    const visibleFilters = pipe(
        filters,
        A.filter((f) => isEqual(f.visible, true))
    );

    if (isEmpty(visibleFilters) && !isEmpty(selectedDatasets)) {
        return pipe(selectedDatasets, A.map(getFilterObjectFromDataset));
    }

    if (!isEmpty(visibleFilters)) {
        return pipe(
            visibleFilters,
            A.flatMap((filter) =>
                pipe(
                    filter.filterCriteria,
                    A.map((d) => getFilterObjectFromFilter(d, isRunningOnSF))
                )
            ),
            (multiFilters) =>
                pipe(
                    multiFilters,
                    A.concat(
                        pipe(
                            selectedDatasets,
                            A.map((selectedDataset) => {
                                if (
                                    !multiFilters
                                        .map((m) => m.databaseId)
                                        .includes(selectedDataset.dataSource.id)
                                ) {
                                    return getFilterObjectFromDataset(
                                        selectedDataset
                                    );
                                }
                            }),
                            compact
                        )
                    )
                )
        );
    }

    return [];
};

export const getMultiFilters = (): FilterObject[] => {
    const { datasets, filters, isRunningOnSF } = useMap();

    return useMemo(
        () => getMultiFiltersType({ datasets, filters, isRunningOnSF }),
        [datasets, filters, isRunningOnSF]
    );
};

export const getDefaultDatasetConfiguration = (
    isRunningOnSF: boolean
): MapContextDatasetConfiguration => ({
    isOpen: false,
    options: getDefaultDatasetConfigurationOptions(isRunningOnSF),
    showLevelSets: true,
    showPoints: true,
});
