import {
    DatasetDataAggregationHeatmap,
    ShapeColor,
} from "@biggeo/bg-server-lib/datascape-ai";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/function";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import replace from "lodash/replace";
import some from "lodash/some";
import { GeoJSONSource } from "mapbox-gl";
import { ColorSwatchOption } from "../../common/components/ColorSwatchSelector";
import { MapContextDataset } from "../mapbox/context";
import {
    CustomShapeSource,
    DEFAULT_SHAPE_COLOR,
    DEFAULT_SHAPE_OPACITY,
} from "../mapbox/hooks/style-hooks";
import {
    getDatasetLayerIds,
    isLayerPresent,
} from "../mapbox/utils/data-layers-utils";
import { MapFeature } from "../views/MapShapeLayers";
import { generateColorScale, generateSteps } from "./utils";

export type MapboxImageObject = {
    width: number;
    height: number;
    data: Uint8Array | Uint8ClampedArray;
    onAdd: () => void;
    render: () => void;
};

export const vertexLayers = [
    "gl-draw-polygon-and-line-vertex-inactive.hot",
    "gl-draw-polygon-and-line-vertex-inactive.cold",
    "gl-draw-polygon-and-line-vertex-active.hot",
    "gl-draw-polygon-and-line-vertex-active.cold",
    "gl-draw-polygon-midpoint.hot",
    "gl-draw-polygon-midpoint.cold",
    "gl-draw-point-active.hot",
    "gl-draw-point-active.cold",
];

export const onCustomShapeLayerLoad = (input: {
    map: mapboxgl.Map;
    features?: GeoJSON.FeatureCollection<
        GeoJSON.Geometry,
        GeoJSON.GeoJsonProperties
    >;
    visibleDatasetIds: string[];
    isLoaded: boolean;
}): GeoJSONSource => {
    const { map, features, visibleDatasetIds, isLoaded } = input;

    const customizationSource = map.getSource(
        CustomShapeSource.customization
    ) as GeoJSONSource;

    if (customizationSource && features) {
        customizationSource.setData(features);
    }

    // So the datasets layers show on top of the custom fill & line colors set in `Shape Layers`.
    const topDatasetLayerId = pipe(
        visibleDatasetIds[0],
        O.fromNullable,
        O.fold(
            () => [],
            (datasetId) =>
                getDatasetLayerIds({
                    prefix: datasetId,
                    map,
                    isLoaded,
                })
        ),
        (layers) => first(layers)
    );

    if (
        !isLayerPresent({ layerId: "shape-customization-fill", map, isLoaded })
    ) {
        map.addLayer(
            {
                id: "shape-customization-fill",
                type: "fill",
                source: CustomShapeSource.customization,
                paint: {
                    "fill-color": [
                        "coalesce",
                        ["get", "fill-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                    "fill-opacity": [
                        "coalesce",
                        ["get", "fill-opacity"],
                        DEFAULT_SHAPE_OPACITY,
                    ],
                },
                filter: ["==", ["geometry-type"], "Polygon"],
            },
            topDatasetLayerId
        );
    }

    if (
        !isLayerPresent({ layerId: "shape-customization-line", map, isLoaded })
    ) {
        map.addLayer(
            {
                id: "shape-customization-line",
                type: "line",
                source: CustomShapeSource.customization,
                paint: {
                    "line-color": [
                        "coalesce",
                        ["get", "stroke-color"],
                        DEFAULT_SHAPE_COLOR,
                    ],
                    "line-width": ["coalesce", ["get", "stroke-width"], 2],
                    "line-opacity": ["coalesce", ["get", "stroke-opacity"], 1],
                },
                filter: ["==", ["geometry-type"], "Polygon"],
            },
            topDatasetLayerId
        );
    }

    return customizationSource;
};

export const setCustomShapeStyling = (input: {
    map: mapboxgl.Map;
    datasets: MapContextDataset[];
    geometries:
        | GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>[]
        | MapFeature[];
    isLoaded: boolean;
    source?: GeoJSONSource;
}) => {
    const { map, datasets, isLoaded, geometries } = input;

    if (map && isLoaded) {
        const source =
            input.source ??
            (map.getSource(CustomShapeSource.customization) as GeoJSONSource);

        onCustomShapeLayerLoad({
            map,
            isLoaded,
            visibleDatasetIds: pipe(
                datasets,
                A.filter(
                    (d) =>
                        isEqual(d.isSelected, true) &&
                        isEqual(d.isVisible, true)
                ),
                A.map((d) => d.dataSource.id)
            ),
        });

        source?.setData({
            type: "FeatureCollection",
            features: geometries,
        });
    }
};

export const setLayersFilter = ({
    map,
    isSelectMode,
}: { map: mapboxgl.Map; isSelectMode: boolean }) => {
    const fillCustomLayer = map.getLayer("shape-customization-fill");
    const lineCustomLayer = map.getLayer("shape-customization-line");

    const fillHotLayer = map.getLayer("gl-map-shapes-fill.hot");
    const fillColdLayer = map.getLayer("gl-map-shapes-fill.cold");

    const lineHotLayer = map.getLayer("gl-map-shapes-line.hot");
    const lineColdLayer = map.getLayer("gl-map-shapes-line.cold");

    const strokeHotLayer = map.getLayer("gl-draw-polygon-stroke-active.hot");
    const strokeColdLayer = map.getLayer("gl-draw-polygon-stroke-active.cold");

    if (isEqual(isSelectMode, true)) {
        // Hide the draw layers when select mode is true
        if (fillHotLayer) {
            map.setFilter("gl-map-shapes-fill.hot", [
                "!=",
                "user_selected",
                true,
            ]);
        }

        if (fillColdLayer) {
            map.setFilter("gl-map-shapes-fill.cold", [
                "!=",
                "user_selected",
                true,
            ]);
        }

        if (lineHotLayer) {
            map.setFilter("gl-map-shapes-line.hot", [
                "!=",
                "user_selected",
                true,
            ]);
        }

        if (lineColdLayer) {
            map.setFilter("gl-map-shapes-line.cold", [
                "!=",
                "user_selected",
                true,
            ]);
        }

        if (strokeHotLayer) {
            map.setFilter("gl-draw-polygon-stroke-active.hot", [
                "!=",
                "user_selected",
                true,
            ]);
        }

        if (strokeColdLayer) {
            map.setFilter("gl-draw-polygon-stroke-active.cold", [
                "!=",
                "user_selected",
                true,
            ]);
        }

        // Hide the shape customization layers when select mode is true
        if (fillCustomLayer) {
            map.setFilter("shape-customization-fill", ["!=", "selected", true]);
        }

        if (lineCustomLayer) {
            map.setFilter("shape-customization-line", ["!=", "selected", true]);
        }
    }

    if (isEqual(isSelectMode, false)) {
        // Show the draw layers when select mode is false
        if (fillHotLayer) {
            map.setFilter("gl-map-shapes-fill.hot", [
                "all",
                ["==", "$type", "Polygon"],
            ]);
        }

        if (fillColdLayer) {
            map.setFilter("gl-map-shapes-fill.cold", [
                "all",
                ["==", "$type", "Polygon"],
            ]);
        }

        if (lineHotLayer) {
            map.setFilter("gl-map-shapes-line.hot", [
                "all",
                ["==", "$type", "Polygon"],
            ]);
        }

        if (lineColdLayer) {
            map.setFilter("gl-map-shapes-line.cold", [
                "all",
                ["==", "$type", "Polygon"],
            ]);
        }

        if (strokeHotLayer) {
            map.setFilter("gl-draw-polygon-stroke-active.hot", [
                "all",
                ["==", "active", "true"],
                ["==", "$type", "Polygon"],
            ]);
        }

        if (strokeColdLayer) {
            map.setFilter("gl-draw-polygon-stroke-active.cold", [
                "all",
                ["==", "active", "true"],
                ["==", "$type", "Polygon"],
            ]);
        }

        // Show the shape customization layers when select mode is false
        if (fillCustomLayer) {
            map.setFilter("shape-customization-fill", undefined);
        }
        if (lineCustomLayer) {
            map.setFilter("shape-customization-line", undefined);
        }
    }
};

export const removeCustomLayers = (map: mapboxgl.Map) => {
    const fillCustomLayer = map.getLayer("shape-customization-fill");
    const lineCustomLayer = map.getLayer("shape-customization-line");

    if (fillCustomLayer) {
        map.removeLayer("shape-customization-fill");
    }
    if (lineCustomLayer) {
        map.removeLayer("shape-customization-line");
    }

    const fillSelectLayer = map.getLayer(
        `map-shapes-fill-${CustomShapeSource.select}`
    );
    const lineSelectLayer = map.getLayer(
        `map-shapes-line-${CustomShapeSource.select}`
    );

    if (fillSelectLayer) {
        map.removeLayer(`map-shapes-fill-${CustomShapeSource.select}`);
    }

    if (lineSelectLayer) {
        map.removeLayer(`map-shapes-line-${CustomShapeSource.select}`);
    }
};

export const isTouchEvent = (
    value: (mapboxgl.MapMouseEvent & mapboxgl.EventData) | TouchEvent
): value is TouchEvent => {
    return value.type.includes("touch");
};

export const isMapTouchEvent = (
    value:
        | (mapboxgl.MapMouseEvent & mapboxgl.EventData)
        | (mapboxgl.MapTouchEvent & mapboxgl.EventData)
): value is mapboxgl.MapTouchEvent & mapboxgl.EventData => {
    return value.type.includes("touch");
};

export const resize = ({
    map,
    draw,
    isLoaded,
    onResize,
    onRelease,
}: {
    map: mapboxgl.Map | null;
    draw: MapboxDraw | null;
    isLoaded: boolean;
    onResize: (i: {
        isResizing: boolean;
        isMidpoint: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>;
        cursor?: { x: number; y: number };
        canvas: HTMLCanvasElement | undefined;
    }) => void;
    onRelease?: (i: {
        isResizing: boolean;
        isMidpoint: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
    }) => void;
}): { cleanup: () => void } => {
    let isResizing = false;
    let featureId: string | undefined = undefined;
    let isMidpoint = false;

    const canvas = map?.getCanvas();
    const mapBounds = canvas?.getBoundingClientRect();
    const top = mapBounds ? mapBounds.top : 0;
    const left = mapBounds ? mapBounds.left : 0;

    const onMouseDown = (
        e:
            | (mapboxgl.MapMouseEvent & mapboxgl.EventData)
            | (mapboxgl.MapTouchEvent & mapboxgl.EventData)
    ) => {
        const selectedFeature = draw?.getSelected().features[0];

        const id =
            selectedFeature && isString(selectedFeature.id)
                ? selectedFeature.id
                : undefined;

        if (map && selectedFeature) {
            const features = map.queryRenderedFeatures(e.point, {
                layers: vertexLayers,
            });

            const isVertex =
                !isEmpty(features) &&
                some(
                    features,
                    (f) =>
                        f.properties?.meta === "vertex" ||
                        f.properties?.meta === "midpoint"
                );

            if (isVertex) {
                isResizing = true;
                featureId = id;
                isMidpoint = some(
                    features,
                    (f) => f.properties?.real_meta === "midpoint"
                );
            } else {
                isResizing = false;
                featureId = undefined;
            }
        }
    };

    const onMouseUp = () => {
        if (isEqual(isResizing, true)) {
            onRelease?.({
                isResizing,
                feature: draw && featureId ? draw.get(featureId) : undefined,
                isMidpoint,
            });

            isResizing = false;
            isMidpoint = false;
            featureId = undefined;
            draw?.changeMode("simple_select");
            if (canvas) canvas.style.cursor = "";
        }
    };

    const onDrawUpdate = () => {
        if (isEqual(isResizing, true)) {
            onRelease?.({
                isResizing,
                feature: draw && featureId ? draw.get(featureId) : undefined,
                isMidpoint,
            });
        }

        isResizing = false;
        isMidpoint = false;
        featureId = undefined;
        draw?.changeMode("simple_select");
        if (canvas) canvas.style.cursor = "";
    };

    const onMouseMove = (
        e:
            | (mapboxgl.MapMouseEvent & mapboxgl.EventData)
            | (mapboxgl.MapTouchEvent & mapboxgl.EventData)
    ) => {
        if (isEqual(isResizing, true)) {
            const selectedFeatureWithUpdatedCoordinates =
                featureId && draw ? draw.get(featureId) : undefined;

            const x = isMapTouchEvent(e)
                ? e.originalEvent.touches[0].clientX - left
                : e.originalEvent.clientX - left;
            const y = isMapTouchEvent(e)
                ? e.originalEvent.touches[0].clientY - top
                : e.originalEvent.clientY - top;

            featureId = selectedFeatureWithUpdatedCoordinates?.id?.toString();

            const cursor = { x, y };

            onResize({
                isResizing,
                feature:
                    selectedFeatureWithUpdatedCoordinates as GeoJSON.Feature<
                        GeoJSON.Polygon,
                        GeoJSON.GeoJsonProperties
                    >,
                cursor,
                canvas,
                isMidpoint,
            });
        }
    };

    const onTouchStart = (e: mapboxgl.MapTouchEvent & mapboxgl.EventData) => {
        onMouseDown(e);
    };

    const onTouchMove = (e: mapboxgl.MapTouchEvent & mapboxgl.EventData) => {
        onMouseMove(e);
    };

    const onTouchEnd = () => {
        onMouseUp();
    };

    if (map && isLoaded) {
        map.on("mousedown", onMouseDown);
        map.on("mouseup", onMouseUp);
        map.on("mousemove", onMouseMove);
        map.on("draw.update", onDrawUpdate);

        map.on("touchstart", onTouchStart);
        map.on("touchmove", onTouchMove);
        map.on("touchend", onTouchEnd);
    }

    return {
        cleanup: () => {
            if (map) {
                map.off("mousedown", onMouseDown);
                map.off("mouseup", onMouseUp);
                map.off("mousemove", onMouseMove);
                map.off("draw.update", onDrawUpdate);

                map.off("touchstart", onTouchStart);
                map.off("touchmove", onTouchMove);
                map.off("touchend", onTouchEnd);
            }
        },
    };
};

export const drag = ({
    map,
    draw,
    isLoaded,
    onDrag,
    mode,
    onRelease,
}: {
    map: mapboxgl.Map | null;
    draw: MapboxDraw | null;
    isLoaded: boolean;
    mode: boolean;
    onDrag: (i: {
        isDragging: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>;
        cursor?: { x: number; y: number };
        canvas: HTMLCanvasElement | undefined;
    }) => void;
    onRelease: (i: {
        isDragging: boolean;
        feature?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>;
    }) => void;
}): { cleanup: () => void } => {
    let isDragging = false;
    let selected:
        | GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
        | undefined = undefined;

    const canvas = map?.getCanvas();
    const mapBounds = canvas?.getBoundingClientRect();
    const top = mapBounds ? mapBounds.top : 0;
    const left = mapBounds ? mapBounds.left : 0;

    const onClick = (
        e:
            | (mapboxgl.MapMouseEvent & mapboxgl.EventData)
            | (mapboxgl.MapTouchEvent & mapboxgl.EventData)
    ) => {
        const shape = isMapTouchEvent(e)
            ? draw?.getSelected().features[0]
            : e.features[0];

        if (map && shape) {
            selected = {
                type: "Feature",
                id: shape.id,
                geometry: shape.geometry,
                properties: shape.properties,
            };
        }
    };

    const onMouseEnter = (
        e: mapboxgl.MapMouseEvent & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData
    ) => {
        e.preventDefault();
        if (canvas) {
            canvas.style.cursor = "pointer";
        }
    };

    const onMouseUp = () => {
        onRelease({
            isDragging,
            feature: selected,
        });

        if (isEqual(isDragging, true)) {
            isDragging = false;
            draw?.changeMode("simple_select");
            if (canvas) canvas.style.cursor = "";
        }
    };

    const onDrawUpdate = () => {
        if (isEqual(isDragging, true)) {
            onRelease({
                isDragging,
                feature: selected,
            });
        }

        isDragging = false;
        draw?.changeMode("simple_select");
        if (canvas) canvas.style.cursor = "";
    };

    const onMouseMove = (
        e: (mapboxgl.MapMouseEvent & mapboxgl.EventData) | TouchEvent
    ) => {
        const selectedFeatureWithUpdatedCoordinates =
            selected && isString(selected.id) && draw
                ? draw.get(selected.id)
                : undefined;

        const hasMoved = !isEqual(
            selected?.geometry,
            selectedFeatureWithUpdatedCoordinates?.geometry
        );

        const x = isTouchEvent(e)
            ? e.touches[0].clientX - left
            : e.originalEvent.clientX - left;
        const y = isTouchEvent(e)
            ? e.touches[0].clientY - top
            : e.originalEvent.clientY - top;

        const cursor = { x, y };

        if (hasMoved && isEqual(mode, true)) {
            isDragging = true;

            selected = selectedFeatureWithUpdatedCoordinates;

            onDrag({
                isDragging,
                feature:
                    selectedFeatureWithUpdatedCoordinates as GeoJSON.Feature<
                        GeoJSON.Polygon,
                        GeoJSON.GeoJsonProperties
                    >,
                cursor,
                canvas,
            });
        } else {
            isDragging = false;
        }
    };

    const onTouchStart = (e: mapboxgl.MapTouchEvent & mapboxgl.EventData) => {
        onClick(e);
    };

    const onTouchMove = (e: TouchEvent) => {
        onMouseMove(e);
    };

    const onTouchEnd = () => {
        onMouseUp();
    };

    if (map && isLoaded && isEqual(mode, true)) {
        map.on("click", `map-shapes-fill-${CustomShapeSource.select}`, onClick);
        map.on(
            "mouseenter",
            `map-shapes-fill-${CustomShapeSource.select}`,
            onMouseEnter
        );
        map.on("mousemove", onMouseMove);
        map.on("mouseup", onMouseUp);
        map.on("draw.update", onDrawUpdate);

        map.on("touchstart", onTouchStart);
        map.on("touchend", onTouchEnd);
        canvas?.addEventListener("touchmove", onTouchMove);
    }

    return {
        cleanup: () => {
            if (map) {
                map.off(
                    "click",
                    `map-shapes-fill-${CustomShapeSource.select}`,
                    onClick
                );
                map.off(
                    "mouseenter",
                    `map-shapes-fill-${CustomShapeSource.select}`,
                    onMouseEnter
                );
                map.off("mousemove", onMouseMove);
                map.off("mouseup", onMouseUp);
                map.off("draw.update", onDrawUpdate);

                map.off("touchstart", onTouchStart);
                map.off("touchend", onTouchEnd);
                canvas?.removeEventListener("touchmove", onTouchMove);
            }
        },
    };
};

export const hexToRgba = ({
    hex,
    opacity = 0.9,
}: { hex?: string; opacity?: number }) =>
    pipe(
        hex,
        O.fromNullable,
        O.fold(
            () => [0, 0, 0, opacity],
            (hex) =>
                pipe(
                    replace(hex, "#", ""),
                    (color) => color.match(/.{1,2}/g),
                    O.fromNullable,
                    O.foldW(
                        () => [0, 0, 0, opacity],
                        (color) => [
                            ...A.map((c: string) => Number.parseInt(c, 16))(
                                color
                            ),
                            opacity,
                        ]
                    )
                )
        )
    );

export const createSquareIcon = ({
    size = 60,
    color,
    stroke,
    borderWidth = 10,
}: {
    size?: number;
    color?: Partial<{ color: string; opacity: number }>;
    stroke?: Partial<{ color: string; opacity: number }>;
    borderWidth?: number;
}) => {
    const bytesPerPixel = 4; // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
    const imageData = new Uint8ClampedArray(size * size * bytesPerPixel);
    const [red, green, blue, opacity] = hexToRgba({
        hex: color?.color,
        opacity: color?.opacity,
    });
    const [bRed, bGreen, bBlue, bOpacity] = hexToRgba({
        hex: stroke?.color,
        opacity: stroke?.opacity,
    });

    for (let x = 0; x < size; x++) {
        for (let y = 0; y < size; y++) {
            const index = (y * size + x) * bytesPerPixel;
            if (
                x < borderWidth ||
                x >= size - borderWidth ||
                y < borderWidth ||
                y >= size - borderWidth
            ) {
                imageData[index] = bRed;
                imageData[index + 1] = bGreen;
                imageData[index + 2] = bBlue;
                imageData[index + 3] = Math.round(bOpacity * 255);
            } else {
                // Inside the square
                imageData[index] = red;
                imageData[index + 1] = green;
                imageData[index + 2] = blue;
                imageData[index + 3] = Math.round(opacity * 255);
            }
        }
    }
    return { width: size, height: size, data: imageData };
};

export const getLayerHeatmapStyle = (
    value?: ColorSwatchOption,
    color?: string
): {
    steps: number[];
    heatMapColorArray: (string | number)[];
    colorSet: string[];
    colorMap: (string | number)[];
} => {
    if (!value) {
        if (color) {
            const heatmapColorSet = generateColorScale(color, 1.5, 3);

            const colorSet = generateColorScale(color, 3, 20);

            const colorMap = colorSet.flatMap((color, idx) => [
                idx,
                `${color}`,
            ]);

            return {
                steps: [],
                heatMapColorArray: [
                    0,
                    "rgba(255,255,255,0)",
                    0.1,
                    heatmapColorSet[0],
                    0.5,
                    heatmapColorSet[1],
                    1,
                    heatmapColorSet[2],
                ],
                colorSet,
                colorMap,
            };
        }

        return { steps: [], heatMapColorArray: [], colorSet: [], colorMap: [] };
    }

    const steps = generateSteps(value.swatch.length);

    // biome-ignore lint/correctness/noFlatMapIdentity: <explanation>
    const heatMapColorArray = steps
        .map((step, i) => [step, value.swatch[i].color])
        .flatMap((c) => c);

    const colorSet = generateColorScale(
        value.swatch[steps.length / 2]?.color || "#ffffff",
        3,
        20
    );

    const colorMap = colorSet.flatMap((color, idx) => [idx, `${color}`]);

    return { steps, heatMapColorArray, colorSet, colorMap };
};

export const generateHeatmapFromColor = (color: string): ColorSwatchOption => {
    const heatmapColorSet = generateColorScale(color, 1.5, 3);

    return {
        id: "custom",
        swatch: [
            {
                range: 0,
                color: "rgba(255,255,255,0)",
            },
            {
                range: 100,
                color: heatmapColorSet[0],
            },
            {
                range: 400,
                color: heatmapColorSet[1],
            },
            {
                range: 900,
                color: heatmapColorSet[2],
            },
        ],
    };
};

export const getHeatmapFormattedValue = (
    heatmap?: DatasetDataAggregationHeatmap
): ColorSwatchOption | undefined => {
    const heatMapId = heatmap?.id || undefined;

    return heatmap && heatMapId
        ? {
              id: isNumber(Number(heatMapId))
                  ? Number(heatMapId)
                  : (heatMapId as "custom"),
              swatch: pipe(
                  heatmap.swatch,
                  A.map((s) => ({
                      range: s.range,
                      color: s.color,
                  }))
              ),
          }
        : undefined;
};

export const getDatasetShapeColor = (
    item: MapContextDataset
): {
    fill: ShapeColor;
    stroke?: ShapeColor;
    heatmap: ColorSwatchOption;
} => {
    const styles = item.mapTemplateDataset?.styles || undefined;
    const defaultColor = item.dataSource.color || DEFAULT_SHAPE_COLOR;

    const fill = pipe(
        styles?.fill,
        O.fromNullable,
        O.fold(
            () => ({
                color: defaultColor,
                opacity: item.dataSource.opacity || 0.9,
            }),
            (fill) => ({
                color: fill.color || defaultColor,
                opacity: fill.opacity || item.dataSource.opacity || 0.9,
            })
        )
    );

    const stroke = pipe(
        styles?.stroke,
        O.fromNullable,
        O.fold(
            () => fill,
            (stroke) => ({
                color: stroke.color || fill.color,
                opacity: stroke.opacity || fill.opacity,
            })
        )
    );

    const heatmap = pipe(
        getHeatmapFormattedValue(styles?.dataAggregation?.heatmap || undefined),
        O.fromNullable,
        O.fold(
            () => generateHeatmapFromColor(fill.color),
            (h) => h
        )
    );

    return { fill, stroke, heatmap };
};

export const createPulsingDot = ({
    size = 200,
    color = DEFAULT_SHAPE_COLOR,
    map,
}: {
    size?: number;
    color?: string;
    map: React.MutableRefObject<mapboxgl.Map | null>;
}): MapboxImageObject => {
    let context: CanvasRenderingContext2D | null = null;

    const width = size;
    const height = size;
    const data = new Uint8Array(width * height * 4);

    const onAdd = () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        context = canvas.getContext("2d");
    };

    const render = (): boolean => {
        const duration = 1000;
        const t = (performance.now() % duration) / duration;

        const zoomLevel = map.current?.getZoom() || 0;

        // Smooth scaling factor based on zoom level
        const minZoom = 0;
        const maxZoom = 20;
        const maxScale = 1;
        const minScale = 0.3;

        // This will ensure the dot will get bigger or smaller as you zoom in or out
        const scaleFactor = Math.max(
            minScale,
            maxScale -
                ((zoomLevel - minZoom) / (maxZoom - minZoom)) *
                    (maxScale - minScale)
        );

        const radius = (size / 2) * 0.3 * scaleFactor;
        const outerRadius = ((size / 2) * 0.7 * t + radius) * scaleFactor;

        if (context) {
            const [red, green, blue, opacity] = hexToRgba({
                hex: color,
                opacity: 1 - t,
            });

            // Outer circle
            context.clearRect(0, 0, width, height);
            context.beginPath();
            context.arc(width / 2, height / 2, outerRadius, 0, Math.PI * 2);
            context.fillStyle = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
            context.fill();

            // Inner circle
            context.beginPath();
            context.arc(width / 2, height / 2, radius, 0, Math.PI * 2);
            context.fillStyle = color;
            context.strokeStyle = "white";
            context.lineWidth = 2 + 4 * (1 - t);
            context.fill();
            context.stroke();

            const imageData = context.getImageData(0, 0, width, height);
            data.set(imageData.data);
        }

        // Trigger a map repaint for smooth animation
        if (map.current) {
            map.current.triggerRepaint();
        }

        // Return true to indicate the image was updated
        return true;
    };

    return { width, height, data, onAdd, render };
};
